.botCommercialDiv {
    width: 100%;
    min-height: 550px;
    max-height: 770px;
    position: relative;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    position: relative;
}

.peper {
    left: 0;
    height: 100%;
    width: 8%;
    position: absolute;
    left: 0;
}

.blue_phoneDiv {
    /* width: 55%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
    right: 0;
}

.botCommercialDiv .blue_phone {
    /* position: absolute; */
    width: 350px;
}

.botCommercialDiv h5 img {
    width: 100px;
    position: absolute;
    right: -85px;
    top: -75px;
}

.botCommercialDiv h5 {
    position: relative;
    font-family: 'DaysOne';
    font-size: 40px;
    color: #FFFFFF;
    margin: 0;
}

.botCommercialText h5:nth-child(3) {
    color: #F7D81A;
}

.botCommercialText {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    max-width: 830px;
    min-width: 640px;
    margin-left: 100px;
}

.botCommercialLines {
    margin: 20px auto;
    margin-left: 10%;
    width: 200px;
}

.botCommercialText a {
    position: relative;
    background: #F7D81A;
    border: 1px solid #141414;
    box-shadow: 0px 20px 60px rgba(247, 216, 26, 0.2), inset -2px -4px 4px rgba(255, 255, 255, 0.25), inset 2px 4px 4px rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    padding: 15px 25px;
    font-family: 'DaysOne';
    font-size: 20px;
    color: #141414;
    margin-top: 80px;
    transition: 0.3s;
}

.botCommercialText a:hover {
    color: #F7D81A;
    background: #F7D81A00;
    box-shadow: 0px 20px 60px rgba(247, 216, 26, 0.0), inset -2px -4px 4px rgba(255, 255, 255, 0.0), inset 2px 4px 4px rgba(255, 255, 255, 0.0);
    border: 1px solid #F7D81A;

}

.botCommercialText a img {
    width: 120px;
    transition: 0.5s;
    position: absolute;
    transform: translateX(50%) translatey(-104%) rotate(0deg);
}

.botCommercialText a:hover img {
    transform: translateX(35%) translatey(-45%) rotate(12deg);
}

.botCommercialText p {
    font-size: 25px;
    color: #fff;
    margin: 0;
    max-width: 80%;
    text-align: left;
}

.botCommercialText p b {
    color: #F7D81A;
}

@media screen and (max-width:1310px) {
    .botCommercialDiv h5 {
        font-size: 35px;
    }
    .botCommercialDiv .blue_phone{
        width: 300px;
    }
    .botCommercialText{
        margin-left: 50px;
        min-width: 520px;
    }
    .botCommercialText p {
        font-size: 18px;
    }
    .botCommercialText a{
        font-size: 18px;
        padding: 15px 23px;
    }
    .botCommercialLines{
        width: 250px;
    }

    .peper{
        height: auto;
        width: 11%;
    }
}
@media screen and (max-width:1000px) {
    .blue_phoneDiv {
        width: 30%;
        position: absolute;
    }
    .botCommercialDiv {
        justify-content: flex-start;
    }
    .botCommercialDiv h5 {
        font-size: 30px;
    }
    .botCommercialDiv .blue_phone{
        width: 250px;
    }
    .botCommercialText{
        margin-left: 30px;
    }
    .botCommercialText p {
        font-size: 18px;
    }
    .botCommercialText a{
        font-size: 18px;
        padding: 10px 20px;
        border-radius: 5px;
    }
    .botCommercialLines{
        width: 250px;
    }

    .peper{
        height: auto;
        width: 13%;
        position: relative;
    }
    .botCommercialText{
        min-width: 470px;
    }
    .botCommercialText a img{
        width: 130px;
    }
    .botCommercialDiv h5 img{
        width: 120px;
    }
}
@media screen and (max-width:770px) {
    .botCommercialDiv h5 {
        font-size: 30px;
    }
    .botCommercialLines{
        width: 150px;
        margin: 15px auto;
        margin-left: 5%;
    }
    .blue_phoneDiv{
        top: 30px;
        z-index: -1;
        width: auto;
    }
    .botCommercialDiv .blue_phone{
        width: 30vw;
    }
    .botCommercialDiv{
        min-height: auto;
    }
    .botCommercialText a,
    .botCommercialText p{
    font-size: 16px;       
    }
}
@media screen and (max-width:550px) {
    
    .blue_phoneDiv{
        display: none;
    }
    .peper {
        width: auto;
        height: 350px;
    }    
    .botCommercialDiv{
        min-height: auto;
    }
    .botCommercialText{
        min-width: calc(70% - 20px);
        margin-left: 20px;
    }
    .botCommercialText p{
        max-width: 90%;          
    }
    .botCommercialText p br{
        display: none;
    }
    .botCommercialDiv h5 img{
        width: 80px;
        right: -57px;
        top: -51px;
    }
    .botCommercialText a {
        margin-top: 50px;
    }
    .botCommercialText a img{
        width: 90px;
    }
    
}
@media screen and (max-width:450px) {
    
    .botCommercialDiv h5{
        font-size: 20px;
    }   
    .botCommercialText a,
    .botCommercialText p{
        font-size: 13px;
    }
    .botCommercialText a{
        padding: 7px 12px;
    }
    .peper{
        height: 260px;
    }
}