:root {
    --appWidth: 2300px
}

.adminSection * {
    font-family: Gilroy;
    transition: 0.3s;
}

.adminSection {
    padding-top: 60px;

}

.adminMenu {
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, #686767 -238.95%, #1e1e1e 50%);
    background: linear-gradient(0deg, #686767 -238.95%, #1e1e1e 50%);
    display: flex;
    align-items: center;
    z-index: 100;
}

.adminMenu a {
    font-size: 16px;
    color: #fff;
    margin: 10px;
    border-bottom: 1px solid rgb(131, 131, 131);
    padding-bottom: 3px;
}

.adminLogo {
    font-family: DaysOne;
    color: #f7d81a;
    margin: 0 20px;
    font-size: 23px;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: start;
    width: 30px;
    height: 30px;
}

.adminLogo::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    top: -1px;
    left: -1px;
    border: 1px solid #f7d81a;
    border-radius: 2px;
}

.crmDiv {
    width: 100%;
    height: 100vh;
    position: relative;
    box-sizing: border-box;
    padding: 30px;
}

.crmSection {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.04);
    border-radius: 20px;
}

.crmApp::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 0px;
    background: #141414;
}

.crmApp {
    position: relative;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    padding: 0 20px;
    height: 97%;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-top: 10px;
}

.crmContainer {
    height: 100%;
    position: relative;
    width: var(--appWidth);
    display: flex;
    justify-content: space-around;
    gap: 10px;
}

.row::-webkit-scrollbar {
    width: 1px;
}

.row {
    width: 300px;
    height: 92%;
    margin: 5px;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #141414;
    transition: background-color 0.3s ease-in-out;
    overflow-y: scroll;
}

.row:last-child .item {
    background-color: #192f1e;
}

.row.highlighted {
    background-color: #484848;
}


.rowButton img {
    height: 20px;
    filter: invert(1);
    cursor: pointer;
    user-select: none;
    margin-right: 10px;
}

.rowButton .vip_image {
    filter: invert(0);
    cursor: default;
    position: absolute;
    right: 0px;
}

.rowButton .partner_image {
    height: 18px;
    right: 40px;
}

.rowButton {
    position: relative;
    height: 30px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: #444;
    border-radius: 0 0 6px 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.item p span {
    color: gray;
    font-size: 12px;
    margin-right: 10px;
}

.item p {
    color: white;
    margin: 0;
    font-size: 14px;
}

.item:active {
    opacity: 0.3;
}

.item {
    width: 95%;
    padding-top: 20px;
    background-color: #313131;
    cursor: grab;
    margin: 8px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 6px;
    box-shadow: 3px 3px 5px 3px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 3px 3px 5px 3px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 3px 3px 5px 3px rgba(0, 0, 0, 0.5);
}

.item:active {
    cursor: grabbing;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);

}

.itemInfo {
    width: 100%;
    padding: 0 20px;
    padding-bottom: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.itemInfo p {
    display: flex;
    text-transform: capitalize;
    align-items: center;
}

.itemInfo .itemCommentDiv p {
    text-transform: none
}

.crmLineDiv {
    width: 100%;
    height: 1px;
    background-color: rgb(160, 160, 160);
    margin: 5px 0;
}

.itemInfo input:focus,
.itemInfo input:disabled,
.itemInfo input {
    outline: none;
    user-select: none;
    background-color: transparent;
    color: white;
    margin: 0;
    padding: 0;
    border-color: transparent;
}

.itemInfo_telegram {
    border: 2px solid #45a3fa;
}

.itemInfo_whatsApp {
    border: 2px solid #417505;

}

.crmHedaers {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.crmHedaers::-webkit-scrollbar {
    /* display: none; */
}

.crmHedaers {
    width: var(--appWidth);
    overflow: hidden;
    display: flex;
    justify-content: space-around;
}

.crmHedaers p {
    color: white;
    font-size: 16px;
    height: 30px;
    width: 300px;
    border-radius: 7px;
    white-space: nowrap;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.crmEmptyItem P {
    color: rgb(69, 69, 69);
    font-size: 14px;
}

.crmHedaers p:nth-child(1) {
    background-color: #45a3fa;
}

.crmHedaers p:nth-child(2) {
    background-color: #3a9f88;
}

.crmHedaers p:nth-child(3) {
    background-color: #417505;
}

.crmHedaers p:nth-child(4) {
    background-color: #1d77ff;
}

.crmHedaers p:nth-child(5) {
    background-color: #55a400;
}

.crmHedaers p:nth-child(6) {
    background-color: #6200a4;
}

.crmHedaers p:nth-child(7) {
    background-color: #244727;
}


.crmClientAdd {
    height: 50px;
}

.crmClientAdd p b {
    font-size: 35px;
    font-weight: normal;
    margin-left: 10px;
}

.crmClientAdd p {
    width: 200px;
    margin: 0;
    display: flex;
    align-items: center;
    color: white;
    font-size: 18px;
    line-height: 35px;
    cursor: pointer;
}

.vip_client {
    background-color: #656340;
}

.itemCommentDiv p:first-child {
    color: gray;
    margin-left: 0px;
}

.itemCommentDiv p {
    font-size: 12px;
    color: #fff;
    margin-right: 10px;
    text-align: left;
    margin-left: 5px;
}

.itemCommentDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.crmDeleteItem p {
    color: white;
    margin: 0;
    font-size: 15px;
}


.crmDeleteItem p:nth-child(3),
.crmDeleteItem p:nth-child(2) {
    padding: 5px;
    width: 70%;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.crmDeleteItem p:nth-child(2) {
    background-color: rgb(151, 18, 18);
}

.crmDeleteItem p:nth-child(3) {
    background-color: rgb(18, 151, 29);
}

.crmDeleteItemFon {
    width: 100%;
    height: 400vw;
    background-color: #14141454;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    z-index: 5;
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
}

.crmDeleteItem {
    position: fixed;
    z-index: 6;
    height: 120px;
    width: 200px;
    background-color: rgba(46, 46, 46, 0.95);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    left: calc(50% - 100px);
    top: calc(50% - 100px);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

@media only screen and (max-width: 800px) {
    .item:active {
        opacity: 1;
    }

    .item:active {
        cursor: default;
        box-shadow: transparent;

    }

    .mobileCrmClientdiv .crmClientCreatorRelation {
        margin-bottom: 0;
        margin: 10px 0;
    }

    .mobileCrmClientdiv .crmClientCreatorRelation select {
        width: 100%;
        font-size: 16px;
    }

    .mobileCrmClientdiv {
        display: flex;
        justify-content: center;
        /* align-items: center; */
        flex-direction: column;
        gap: 15px;
    }

    .mobileCrmClientItem {
        position: relative;
        margin: auto;
    }

    .crmDiv {
        padding: 5px;
    }


    .mobileCrmClientItemnew {
        border-left: 25px solid #45a3fa;
    }


    .mobileCrmClientItemmatching {
        border-left: 25px solid #3a9f88;
    }


    .mobileCrmClientItemcollection {
        border-left: 25px solid #417505;
    }


    .mobileCrmClientItemapproval {
        border-left: 25px solid #1d77ff;
    }


    .mobileCrmClientItempayment {
        border-left: 25px solid #55a400;
    }

    .mobileCrmClientItemlaunch {
        border-left: 25px solid #6200a4;
    }
    .mobileCrmClientItemcompleted {
        border-left: 25px solid #244727;
        background-color: #192f1e;
    }
    

    .mobileCrmClientItemnew .crmClientCreatorRelation {
        background-color:#45a3fa;
    }

    .mobileCrmClientItemmatching .crmClientCreatorRelation {
        background-color: #3a9f88;
    }

    .mobileCrmClientItemcollection .crmClientCreatorRelation {
        background-color: #417505;
    }

    .mobileCrmClientItemapproval .crmClientCreatorRelation {
        background-color: #1d77ff;
    }

    .mobileCrmClientItempayment .crmClientCreatorRelation {
        background-color: #55a400;
    }

    .mobileCrmClientItemlaunch .crmClientCreatorRelation {
        background-color: #6200a4;
    }

    .mobileCrmClientItemcompleted .crmClientCreatorRelation {
        background-color: #244727;
    }

    .itemInfo {
        padding: 0 10px;
    }

    .rowButton {
        border-radius: 0 0 6px 0;
    }
}