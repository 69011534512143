.instagramEclipse {
    background-color: #EE6DD9;
}

.telegramEclipse {
    background-color: #9FE9F9;
}

.whatsappEclipse {
    background-color: #0DD337;
}

.viberEclipse {
    background-color: #B15BBE;
}

.vkEclipse {
    background-color: #9FE9F9;
}

.socailScreens {
    width: 80%;
    margin: auto;
    background: rgba(217, 217, 217, 0.1);
    min-height: 600px;
    border-radius: 40px;
    border: 2px solid #e9b84a;
    box-shadow: inset -2px -4px 4px rgba(255, 255, 255, 0.25), inset 2px 4px 4px rgba(255, 255, 255, 0.25);
    filter: drop-shadow(0px 20px 60px rgba(196, 22, 23, 0.2));
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
}

.socailScreensSection {
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.screenshot img {
    width: 250px;
}

.socialLine {
    margin-bottom: 0;
    margin-top: 10px;
}


.socailScreens h2 {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F9B21A;
    margin-bottom: 40px;
    margin-top: 60px;
    text-align: center;
}

.socailScreens p {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    margin-bottom: 50px;
    text-align: center;

}

.socailScreens span {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #dfdfdf;
    width: 70%;
    margin-top: 60px;
    margin-bottom: 30px;
}

.screenshot span {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    width: 250px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 30px;
}

.screenshot p {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    margin: 0;
    width: 250px;
    min-height: 55px;
    text-align: left;
    text-transform: uppercase;
}

.socailScreens .screenshot {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin: 30px 5px;
}

.workTextOtchyot {
    padding-top: 50px;
}

.socialWorkSection img {
    width: 65%;
}

.tarifDiv h2 {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F9B21A;
    margin-top: 100px;
}

.tarifDiv p {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #d1d1d1;
}

.tarifDiv {
    width: 80%;
    width: 80%;
    margin: auto;
    margin-top: 80px;
}

.tarifFon {
    background: rgba(255, 241, 114, 0.1);
    border-radius: 40px;
    width: 100%;
    min-height: 500px;
    margin-bottom: 130px;
    padding-top: 30px;
    padding-bottom: 160px;
    scroll-behavior: smooth;
}


.tarifFon button:hover {
    background: #F9B21A00;
    border: 1px solid #F9B21A;
    color: #F9B21A;
}

.tarifFon button {
    position: absolute;
    cursor: pointer;
    transition: .25s;
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #141414;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 260px;
    height: 56px;
    left: calc(50% - 130px);
    background: #F9B21A;
    border: 1px solid #141414;
    border-radius: 10px;
    margin-top: 40px;
    margin-bottom: 50px;
}

.tarifs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.finger {
    display: none;
}

.tarifInfo p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.1em;
    color: #F9B21A;
    flex: none;
    order: 0;
    flex-grow: 0;
    height: 50px;

}

.tarifInfo span {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    flex: none;
    order: 0;
    flex-grow: 0;
    display: block;
    margin-top: 60px;
}

.tarifInfo {
    width: 24%;
}

.tarifLine {
    /* margin-top: 50px; */
    height: 580px;
    width: 1px;
    background-color: rgba(249, 178, 26, 0.6);
}

.tarifLineViber {
    height: 350px;
}

.socailForm {
    height: 750px;
}

.socialHomeFormText b {
    background: #F7D81A;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-align: left;
    margin-bottom: 10px;
}

.socialHomeFormText p {
    background: #F4F4F4;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-align: left;
    margin-bottom: 10px;
}

.screenshotViber {
    width: 32%;
}

.screenshotViber p {
    width: 100%;
}

.screenshotViber span {
    width: 100%;

}

.socailScreensSliderParagraph {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    margin-bottom: 50px;
    text-align: center;
}

.socailScreensSliderspan {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #dfdfdf;
    width: 70%;
    margin-top: 60px;
    margin-bottom: 30px;
}

.socailScreensSlider h2 {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F7D81A;
    margin-bottom: 40px;
    margin-top: 180px;
    text-align: center;

}

.socailScreensSlider {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.socialSwiper .socialLine {
    width: 100%;
}

.socialSwiper {
    width: 100%;
    cursor: grab;
}

.socialSwiper .screenshot {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;

}

.swiper-slide {
    padding-bottom: 45px;
    display: flex;
    justify-content: center;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 7px;
}

.swiper-pagination-bullet {
    scale: 1.3;
    background-color: white;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    background-color: #F7D81A;
}
.swiper-button-next, .swiper-button-prev{
    color: #F7D81A;
}
.swiper-backface-hidden .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}
.socailScreens{
    display: none;
}
.socailScreensViber{
    display: flex;
}

@media only screen and (max-width: 1800px) {
    .socailScreens .screenshot {
        margin: 30px 50px;
    }
}

@media only screen and (max-width: 1500px) {
    .tarifDiv {
        width: 90%;
    }

    .socailScreens .screenshot {
        margin: 30px 50px;
    }

    .socailScreensSection {
        width: 100%;
    }
}

@media only screen and (max-width: 1250px) {
    .socailScreens .screenshot {
        margin: 30px 50px;
    }

    .tarifDiv {
        width: 100%;
    }
}

@media only screen and (max-width: 1000px) {
    .socailScreensSlider{
        display: none;
    }
    .socailScreens{
        display: flex;
    }
    .finger {
        width: 80px;
        position: absolute;
        margin-top: -55px;
        left: calc(50% - 40px);
        animation-name: finger;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        display: block;
    }

    @keyframes finger {
        0% {
            margin-left: -30px;
        }

        50% {
            margin-left: 30px;
        }

        100% {
            margin-left: -30px;
        }


    }

    .tarifInfo {
        width: 210px;
    }

    .tarifFon {
        width: 100%;
        overflow-x: scroll;
        border-radius: 20px;
    }

    .tarifs {
        min-width: 1000px;

    }
}

@media only screen and (max-width: 900px) {

    /* .tarifInfo span{
        font-size: 10px;
    }
    .tarifInfo p{
        font-size: 10px;
    }
    .tarifFon{
        border-radius: 25px;
    } */
    .socailScreens .screenshot {
        margin: 30px 50px;
    }

    .screenshotViber {
        width: 90%;
        margin: 30px 10px;
    }
}

@media only screen and (max-width: 450px) {
    .socailScreens{
        width: 95%;
    }
    .socailScreens h2 {
        font-size: 28px;
    }

    .socialWorkSection {
        margin-top: 30px;
    }

    .socialHomeFormText p {
        text-align: center;
    }

    .socailScreens .screenshot {
        margin: 20px 10px;
        width: 90%;
    }
}