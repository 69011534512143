.cabinetMessageMailingText {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.1em;
    color: #989898;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 2vh;
    position: absolute;
    top: 30px;
}

.SelfMailingCabinetDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
}

.reverse {
    flex-direction: column;
}

.SelfMailingCabinetElement {
    width: 93%;
    padding: 5px 2%;
    height: 150px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    background: rgba(217, 217, 217, 0.05);
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1), inset 0px -2px 8px rgba(0, 0, 0, 0.15), inset 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
}

.iconTimeInfoMobile {
    display: none;
}

.iconTimeInfo {
    height: 150px;
    width: 15%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.iconTimeInfo img {
    width: 60px;
}

.iconTimeInfo p {
    font-family: 'DaysOne';
    font-style: normal;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.1em;
    background: linear-gradient(252.81deg, #F9B21A 0.14%, rgba(245, 249, 26, 0.78) 64.57%, rgba(245, 249, 26, 0.7) 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0;
}

.iconTimeInfo span {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #FFFFFF;
}

.iconTimeInfo h6 {
    margin: 0;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    color: #FFFFFF;
}

.statusDiv {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.statusDiv p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;
    background: linear-gradient(252.81deg, #F9B21A 0.14%, rgba(245, 249, 26, 0.78) 64.57%, rgba(245, 249, 26, 0.5) 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

}

.statusDiv .start {
    background: linear-gradient(210.19deg, #0DD337 0%, rgba(147, 247, 172, 0.5) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

}

.statusDiv .finish {
    background: linear-gradient(231.86deg, #C41617 35.92%, rgba(222, 58, 57, 0.5) 100.02%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.SelfMailingCabinetStatisticMobile {
    display: none;
}

.SelfMailingCabinetStatistic {
    width: 75%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.SelfMailingCabinetStatistic span {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;

}

.SelfMailingCabinetStatistic b {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
}

.SelfMailingCabinetStatistic .zaglushka {
    width: 124px;
    height: 84px;
    padding: 4px;
    background: linear-gradient(245.35deg, rgba(100, 100, 100, 0.1) 0%, rgba(100, 100, 100, 0.0557072) 69.43%, rgba(100, 100, 100, 0) 100%);
    box-shadow: inset -2px -4px 4px rgba(255, 255, 255, 0.25), inset 2px 4px 4px rgba(255, 255, 255, 0.25);
    filter: drop-shadow(0px 5px 30px rgba(100, 100, 100, 0.1)), ;
    border-radius: 16px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.SelfMailingCabinetStatistic .zaglushka p {
    color: white;
    font-size: 12px;

}

.SelfMailingCabinetStatistic p {
    font-size: 15px;
    border-radius: 16px;
    width: 124px;
    height: 84px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 3px;
}

.SelfMailingCabinetStatistic .sendMailing {
    background: linear-gradient(210.19deg, rgba(159, 233, 249, 0.1) 0%, rgba(34, 179, 218, 0.1) 66.67%, rgba(14, 171, 213, 0) 100%);
    box-shadow: inset -2px -4px 4px rgba(0, 0, 0, 0.25), inset 2px 4px 4px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 5px 30px rgba(153, 213, 229, 0.1));
    border: 1px solid #0EABD5;
    color: #0EABD5;
}

.SelfMailingCabinetStatistic .deliveryMailing {
    background: linear-gradient(245.35deg, rgba(255, 241, 114, 0.1) 0%, rgba(255, 241, 114, 0.0557072) 69.43%, rgba(255, 241, 114, 0) 100%);
    box-shadow: inset -2px -4px 4px rgba(255, 255, 255, 0.25), inset 2px 4px 4px rgba(255, 255, 255, 0.25);
    filter: drop-shadow(0px 5px 30px rgba(255, 241, 114, 0.1));
    border: 1px solid #FFF172;
    color: #FFF172;
}

.SelfMailingCabinetStatistic .errorMailing {
    background: linear-gradient(231.86deg, rgba(196, 22, 23, 0.1) 35.92%, rgba(255, 85, 85, 0.078) 67.19%, rgba(222, 58, 57, 0) 100.02%);
    box-shadow: inset -2px -4px 4px rgba(255, 255, 255, 0.25), inset 2px 4px 4px rgba(255, 255, 255, 0.25);
    filter: drop-shadow(0px 5px 30px rgba(196, 22, 23, 0.1));
    border: 1px solid #C41617;
    color: #C41617;
}

.SelfMailingCabinetStatistic .readMailing {
    background: linear-gradient(252.81deg, rgba(249, 178, 26, 0.1) 0.14%, rgba(245, 249, 26, 0.078) 64.57%, rgba(245, 249, 26, 0) 99.9%);
    box-shadow: inset 0px -2px 8px rgba(0, 0, 0, 0.15), inset 0px 2px 8px rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0px 5px 30px rgba(247, 216, 26, 0.1));
    border: 1px solid #F7D81A;
    color: #F7D81A;
}

.SelfMailingCabinetStatistic .transitionMailing {
    background: linear-gradient(210.19deg, rgba(147, 247, 172, 0.1) 0%, rgba(41, 219, 79, 0.1) 66.67%, rgba(13, 211, 55, 0) 100%);
    box-shadow: inset 0px -2px 8px rgba(0, 0, 0, 0.15), inset 0px 2px 8px rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0px 5px 30px rgba(13, 211, 55, 0.1));
    border: 1px solid #0DD337;
    color: #0DD337;
}

.SelfMailingCabinetIcons {
    margin: auto;
    width: 93%;
    padding: 5px 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
}

.SelfMailingCabinetIcons div p {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SelfMailingCabinetIcons .iconTimeInfoIconDiv p .rotate {
    transform: rotate(180deg);
}

.SelfMailingCabinetIcons .iconTimeInfoIconDiv p img {
    cursor: pointer;
    width: 10px;
}

.SelfMailingCabinetIcons .iconTimeInfoIconDiv p {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-content: center;
}

.iconTimeInfoIconDiv {
    width: 15%;
    display: flex;
}

.statusDivIconDiv {
    width: 10%;
    display: flex;
    justify-content: center;
}

.SelfMailingCabinetStatisticIconDiv {
    width: 75%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.SelfMailingCabinetStatisticIconDiv img {
    width: 23px;
    margin: 0 56px;
}

.cabinetMessageMailing {
    transition: 0.3s;
    padding: 200px 0;
    margin-top: 200px;
}

.cabinetMessageMailing .apps {
    right: 0;
    position: absolute;
}


.messageMailingActive .apps {
    right: -100%;
}

.buyMaiiling {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.1em;
    color: #F7D81A;
    background: none;
    width: 269px;
    height: 52px;
    border: 1px solid #F7D81A;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s;
    margin-left: calc(95% - 269px);
    padding: 11px;
}

.buyMaiiling:hover {
    color: #141414;
    background-color: #F7D81A;
}

.CabinetMailingFilterDiv {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-left: 2%;
}
.CabinetMailingFilterDiv button {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.1em;
    color: #F7D81A;
    background: none;
    border: 1px solid #F7D81A;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s;
    margin-left: calc(95% - 269px);
    padding: 7px 15px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CabinetMailingFilterDiv button:hover {
    color: #141414;
    background-color: #F7D81A;
}
.CabinetMailingFilterDiv .CabinetMailingFilterDivActive{
    color: #141414;
    background-color: #F7D81A;
}
.CabinetMailingNotFound{
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    margin: auto;
    margin-top: 70px;
    text-align: center;
    width: 100%;
}
@media only screen and (max-width: 1668px) {
    .cabinetMessageMailing {
        padding: 375px 0;
    }

    .SelfMailingCabinetStatistic,
    .SelfMailingCabinetStatisticIconDiv {
        width: 65%;
    }

    .statusDiv,
    .statusDivIconDiv {
        width: 15%;
    }

    .iconTimeInfo,
    .iconTimeInfoIconDiv {
        width: 20%;
    }
}

@media only screen and (max-width: 1450px) {
    .statusDiv p {
        font-size: 16px;
    }

    .SelfMailingCabinetStatistic p,
    .zaglushka {
        scale: 0.85;
    }

    .statusDiv,
    .statusDivIconDiv {
        width: 13%;
    }

    .iconTimeInfo,
    .iconTimeInfoIconDiv {
        width: 19%;
    }

    .SelfMailingCabinetStatistic,
    .SelfMailingCabinetStatisticIconDiv {
        width: 68%;
    }

    .SelfMailingCabinetElement {
        width: 96%;
        padding: 5px 1%;
    }
}

@media only screen and (max-width: 1350px) {
    .cabinetMessageMailing .apps {
        width: 100%;
    }
}

@media only screen and (max-width: 1300px) {

    .SelfMailingCabinetStatistic p,
    .zaglushka {
        scale: 1;
    }
}

@media only screen and (max-width: 1030px) {
    .cabinetMessageMailing {
        margin-top: auto;
        padding: 570px 0;
    }

    .SelfMailingCabinetStatistic p,
    .zaglushka {
        scale: 0.85;
    }
}

@media only screen and (max-width: 960px) {
    .SelfMailingCabinetIcons {
        margin-top: 30px;
    }

    .SelfMailingCabinetStatisticMobile p img {
        width: 20px;
    }

    .iconTimeInfo {
        height: 220px;
    }

    .iconTimeInfo h6 {
        font-size: 14px;
    }

    .statusDiv p {
        font-size: 20px;
    }

    .iconTimeInfo img {
        width: 100px;
    }

    .iconTimeInfo p {
        font-size: 20px;
    }

    .SelfMailingCabinetStatistic {
        display: none;
    }

    .iconTimeInfo,
    .iconTimeInfoIconDiv {
        width: 24%;
    }

    .SelfMailingCabinetStatisticMobile {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 5%;
    }

    .SelfMailingCabinetElement {
        height: auto;
        justify-content: space-around;
    }

    .SelfMailingCabinetStatisticMobile p img {
        margin-right: 8px;
    }

    .SelfMailingCabinetStatisticMobile p b {
        color: white;
        margin-left: 8px;
    }

    .SelfMailingCabinetStatisticMobile p {
        width: 100%;
        font-family: 'DaysOne';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 5px 0;
    }

    .SelfMailingCabinetStatisticMobile .sendMailing {
        color: #0EABD5;
    }

    .SelfMailingCabinetStatisticMobile .deliveryMailing {
        color: #FFF172;
    }

    .SelfMailingCabinetStatisticMobile .errorMailing {
        color: #C41617;
    }

    .SelfMailingCabinetStatisticMobile .readMailing {
        color: #F7D81A;
    }

    .SelfMailingCabinetStatisticMobile .transitionMailing {
        color: #0DD337;
    }

    .SelfMailingCabinetStatisticMobile .zaglushkaMobile {
        width: 100%;
        font-family: 'DaysOne';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.1em;
    }

    .SelfMailingCabinetStatisticMobile .zaglushkaMobile p img {
        filter: contrast(0) brightness(1.4);
        margin-right: 8px;
    }

    .SelfMailingCabinetStatisticMobile .zaglushkaMobile p {
        color: #b4b4b4;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .SelfMailingCabinetStatisticIconDiv {
        display: none;
    }
}

@media only screen and (max-width: 820px) {
    .cabinetMessageMailing{
        margin-top: 150px;
    }
}
@media only screen and (max-width: 700px) {
    .cabinetMessageMailing {
        padding: 450px 0;
        margin-top: 100px;
    }
    .cabinetMessageMailingText{
        top: 10px;
    }

    .SelfMailingCabinetElement {
        flex-direction: column;
    }

    .statusDivIconDiv {
        display: none;
    }

    .statusDiv {
        display: none;
    }

    .iconTimeInfoIconDiv {
        width: 200px;
    }

    .iconTimeInfo {
        display: none;
    }

    .iconTimeInfoMobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 30px;

    }

    .iconTimeInfoMobile div {
        width: 70%;
        min-height: 110px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
    }

    .iconTimeInfoMobile img {
        height: 110px;
        margin-left: 5%;
    }

    .SelfMailingCabinetStatisticMobile {
        padding: 0;
        width: 90%;
    }

    .SelfMailingCabinetElement {
        width: 80%;
        padding: 20px;
    }

    .iconTimeInfoMobile p {
        color: #F7D81A;
        font-size: 24px;
        line-height: 25px;
        margin: 0;
        text-transform: capitalize;
    }

    .iconTimeInfoMobile div span {
        font-family: 'Days One';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 17px;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        margin-top: 10px;
    }

    .iconTimeInfoMobile div h6 {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 17px;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        margin: 0;
        margin-top: 7px;
    }

    .iconTimeInfoMobile .status span {
        font-family: 'DaysOne';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 0.1em;
        color: #FFFFFF;
    }

    .iconTimeInfoMobile .status {
        font-family: 'DaysOne';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 0.1em;
        margin-top: 30px;
    }

    .iconTimeInfoMobile .start {
        color: #0DD337;
    }

    .iconTimeInfoMobile .finish {
        color: #C41617;
    }

}

@media only screen and (max-width: 630px) {
    .SelfMailingCabinetElement {
        width: 95%;
        padding: 20px 3%;
    }

    .iconTimeInfoMobile div {
        width: 63%;
        padding-left: 7%;
    }

    .iconTimeInfoMobile .status {
        margin-top: 15px;
    }

    .cabinetMessageMailingText {
        font-size: 16px;
    }
}

@media only screen and (max-width: 500px) {
    .cabinetMessageMailingText {
        font-size: 14px;
    }

    .buyMaiiling {
        display: block;
        margin: auto;
        width: 220px;
        height: 40px;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 450px) {

    .iconTimeInfoMobile img {
        width: 25%;
        height: auto;
    }

    .iconTimeInfoMobile p {
        font-size: 20px;
    }

    .iconTimeInfoMobile div span {
        font-size: 14px;
        margin-top: 5px;
    }

    .iconTimeInfoMobile div h6 {
        font-size: 12px;
    }

    .iconTimeInfoMobile .status {
        font-size: 14px;
    }

    .iconTimeInfoMobile .status span {
        font-size: 12px;
    }

    .iconTimeInfoMobile {
        align-items: start;
        margin-bottom: 15px;
    }

}

@media only screen and (max-width: 370px) {
    .cabinetMessageMailing {
        padding: 769px 0;
    }
}