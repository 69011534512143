.referralWidgetDiv img{
    width: 80%;
    max-width: 320px;
    position: absolute;
    top: -25px;
}
.referralWidgetDiv{
    position: fixed;
    width: 400px;
    height: 550px;
    left: 15px;
    bottom: 15px;
    border-radius: 8px;
    backdrop-filter: blur(5px);
    background-color: rgba(60, 55, 25, 0.8);
    background-image: url(../../../public/static/img/referral_fon.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 102;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    transform: translateY(0%);
}
.referralWidgetDivClosed{
    transform: translateY(110%);
}
.referralWidgetCloseDIv{
    position: absolute;
    height: 25px;
    width: 3px;
    top: 10px;
    right: 20px;
    transform: rotate(45deg);
    background-color: white;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.2s;
}
.referralWidgetCloseDIv:hover{
    opacity: 1;
}
.referralWidgetCloseDIv::after{
    content: ' ';
    position: absolute;
    width: 30px;
    top: 0;
    left: 0;
    height: 30px;
    border-radius: 50%;
    transform: translateX(-46%) translateY(-10%);
    z-index: -3;
}
.referralWidgetCloseDIv::before{
    content: ' ';
    position: absolute;
    height: 25px;
    width: 3px;
    transform: rotate(90deg);
    background-color: white;
    
}

.referralWidgetInfoDiv{
    width: 90%;
    height: 45%;
    /* min-height: ; */
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;    
}
.referralWidgetInfoDiv .h5{
    font-size: 17px;
    margin: 0;
    color: white;
    margin-bottom: 20px;
    text-transform: uppercase;
    max-width: 360px;
}
.referralWidgetInfoDiv .h2{
    font-size: 15px;
    margin: 0;
    color: white;
    font-family: Gilroy;
    margin-bottom: 15px;
    max-width: 360px;

}
.referralWidgetInfoDiv span{
    font-size: 12px;
    margin: 0;
    color: white;
    font-family: Gilroy;
    max-width: 360px;
}
.referralWidgetInfoDiv div{
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    flex-wrap: wrap;
}
.referralWidgetInfoDiv div a{
    width: 30px;
    height: 30px;    
    display: flex;
    color: white;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    transition: 0.2s;
}
.referralWidgetInfoDiv div a:hover{
    color: #F7D81A;
}
.referralWidgetInfoDiv div a:hover img{
    filter: brightness(1) invert(0);
}
.referralWidgetInfoDiv div a:nth-child(3){
    width: 100%;
    margin-top: 10px;
}
.referralWidgetInfoDiv div img{
    width: 30px;
    height: 30px;
    filter: brightness(0) invert(1);
    position: relative;
    top: 0;
    margin-right: 7px;
}

.referralWidgetFon{
    display: none;
}
@media only screen and (max-width: 750px) {
    .referralWidgetFon{
        display: block;
        position: fixed;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.615);
    }
    .closed{
        display: none;
    }
    .referralWidgetDiv{
        top: calc(50vh - 275px);
        left: calc(50% - 200px);    
        background-color: rgba(55, 54, 20, 0.8);
        
    }
    .referralWidgetDivClosed{
        transform: translateY(150%);
    }
}

@media only screen and (max-width: 430px) {
    .referralWidgetDiv{
        width: 300px;
        height: 450px;
        top: calc(50vh - 225px);
        left: calc(50% - 150px);            
    }
    .referralWidgetInfoDiv .h5{
        font-size: 13px;
        margin-bottom: 15px;
    }
    .referralWidgetInfoDiv span{
        font-size: 10px;
    }
    .referralWidgetInfoDiv .h2{
        font-size: 11px;
        margin-bottom: 10px;
    }
    .referralWidgetInfoDiv div img{
        width: 20px;
        height: 20px;
    }
    .referralWidgetInfoDiv div a{
        font-size: 12px;
    }
    .referralWidgetInfoDiv div a:nth-child(3){
        margin-top: 0;
    }
    .referralWidgetInfoDiv {
        height: 50%;
    }
}