.botBeneficeDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../../public/static/img/bot/circles.png);
    background-repeat: no-repeat;
    background-position-y: 90%;
    background-position-x: 6%;
    background-size: 20%;
}

.bot_Wave {
    width: 7.5%;
    min-height: 200px;
    background-image: url(../../../public/static/img/bot/audio_wave1.png);
    background-repeat: no-repeat;
}
.bot_WaveMobile{
    display: none;
}
.bot_Wave:first-child {
    background-image: url(../../../public/static/img/bot/audio_wave2.png);
}

.botBeneficeTable {
    width: 85%;
    border-radius: 15px;
    border: 1px solid white;
    display: flex;
    justify-content: space-evenly;
    padding: 60px 0;
    box-shadow: 0px 6px 10px -1px rgba(255, 255, 255, 0.2);
}

.botBenefices p {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #F7D81A;
    margin: 0;
    margin-bottom: 20px;
}

.botBenefices li {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin: 6px 0;
}

.botBenefices ul {
    list-style: none;
    margin: 0;
}

.botBenefices ul li::before {
    content: "\2022";
    color: #F7D81A;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1.8em;
}

@media screen and (max-width:1100px) {
    .botBeneficeTable {
        flex-direction: column;        
        align-items: center;
    }
    .botBeneficeTable{
        padding: 35px 0;
    }
    .botBenefices:nth-child(1) {
        margin-bottom: 30px;
    }

    .botBenefices{
        width: 60%;
    }
    .bot_Wave{
        margin-top: -300px;
        min-height: 100px;
    }
    .bot_Wave{
        background-position-x: 45%;
    }
    .bot_WaveMobile{
        display: block;
        position: absolute;
        width: 7.5%;
        min-height: 100px;
        background-image: url(../../../public/static/img/bot/audio_wave1.png);
        background-repeat: no-repeat;
        left: 0;
        margin-top: 300px;
    }
    .bot_WaveMobile:nth-child(2) {
        left: auto;
        right: 0;
        background-image: url(../../../public/static/img/bot/audio_wave2.png);
    }
}
@media screen and (max-width:600px) {
    .botBenefices{
        width: 85%;
    }
}
@media screen and (max-width:450px) {
    .botBenefices p{
        font-size: 19px;
        line-height: 21px;
    }
    .botBenefices li{
        font-size: 14px;
        line-height: 16px;
    }
}