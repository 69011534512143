.downloadCrmButton {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 28px;
    letter-spacing: 0.1em;
    color: #F7D81A;
    text-align: center;
    margin: auto;
    display: block;
    margin-top: 100px;
}