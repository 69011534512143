.payMetodsDiv{
    box-shadow: 0px 0px 40px rgba(247, 216, 26, 0.47);
    margin: 50px 0;
    padding: 50px 0;
    width: 100%;
}
.payMetodsDiv .botHeaderText{
    margin-top: 20px;
}
.payMetods {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
    color: #FFFFFF;

}

.payMetodsItem {
    width: 20%;
    min-width: 330px;
    min-height: 600px;
    background-color: #1e1e1e;
    background-image: url(../../../public/static/img/formFon.png);
    background-size: 40px;
    border-radius: 25px;
    position: relative;
    /* box-sizing: border-box; */
    padding: 25px;
    padding-bottom: 35px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 700px;
}
.payMetodsItemMid{
    width: 15%;    
    height: 600px;
    border: 1px solid #D1F90F;
    border-radius: 10px;
}

.payMetodsItem h5 {
    font-family: 'Gilroy';
    font-size: 20px;
    margin: 0;
    font-weight: lighter;
}

.payMetodsItem h2 {
    font-family: 'Gilroy';
    font-weight: lighter;
    font-size: 60px;
    color: #FFFFFF;
    display: flex;
    width: 100%;
    gap: 20px;
    margin: 0;
    align-items: flex-end;
}


.payMetodsItem h2 h3 {
    font-family: 'Gilroy';
    font-weight: lighter;
    font-size: 16px;
    color: #71717A;
    margin: 0;
    margin-bottom: 10px;
}

.payMetodsItem p {
    font-family: 'Gilroy';
    font-weight: lighter;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    color: #FFFFFF;
    margin: 0;

}

.payMetodsItem span {
    font-family: 'Gilroy';
    font-weight: lighter;
    font-size: 15px;
    color: #FFFFFF;
    line-height: 30px;

}


.payMetodsItem .payMetodsLogo {
    width: 90%;
    margin: auto;
}
.payMetodsItem hr {
    width: 80%;
    border-color: #6d6320;

}

.payMetodsItem a {
    width: 100%;
    display: flex;
    justify-content: center;
}
.payMetodsItem button {
    padding: 18px 32px;
    border: 1px solid #F7D81A;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    background-color: transparent;
    width: 80%;
    margin: auto;
    transition: 0.3s;
}
.payMetodsItem button:hover {
    background-color: #F7D81A;
    color: black;    
}

.payMetodsItemMid button {
    background-color: #F7D81A;
    color: black;
}
.payMetodsItemMid button:hover {
    color: #fff;
    background-color: transparent;

}
.payMetodsHideDiv{
    width: 50px;
    height: 50px;
    background-color: #323233;
    position: absolute;
    border-radius: 50%;
    bottom: -20px;
    left: calc(50% - 25px);
    margin: auto;    
    display: flex;
    justify-content: center;
    align-items: flex-end;
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
    display: none;
}

.payMetodsItemHide {
    min-height: 250px;
    height: 250px;
    gap: 10px;
}
.payMetodsItemHide .payMetodsHideDiv{
    transform: rotate(180deg);
}
.payMetodsItemHide  p{
    display: none;
}
.payMetodsItemHide  span{
    display: none;
}
.payMetodsItemHide  hr{
    display: none;
}
.payMetodsItemHide  button{
    display: none;
}



@media screen and (max-width:1400px) {
    .payMetods{
        gap: 30px;
    }
    .payMetodsItem {
        min-width: 300px;
    }
}
@media screen and (max-width:1200px) {
    .payMetods{
        flex-direction: column;
    }
    .payMetodsHideDiv{
        display: flex;
    }
}
@media screen and (max-width:500px) {
    .payMetodsItem{
        width: 95%;
        height: auto;
        min-width: 200px;
        box-sizing: border-box;
        padding-bottom: 50px;
    }
}