.supportDivFon{
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px);
    z-index: 11;
    cursor: pointer;
}
.supportDiv{
    z-index: 10;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0; 
    display: flex;
    justify-content: center;
    align-items: center;
}
.supportDiv .homeForm{
    z-index: 12;
    width: auto;
    background-color: rgba(0, 0, 0, 0.741);
}
.homeFormClose{
    position: absolute;
    z-index: 12;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
.homeFormClose img{
    width: 30px;
}
.supportDiv .formResponceDiv{
z-index: 12;

}