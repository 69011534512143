.statisticDiv {
    width: 100%;
}

.statisticGraphicDiv {
    width: 95%;
    margin: auto;
    margin-top: 50px;
    padding: 30px 10px 20px 10px;
    background: rgba(217, 217, 217, 0.05);
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1), inset 0px -2px 8px rgba(0, 0, 0, 0.15), inset 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.statisticGraphic {
    height: 350px;
    width: 98%;
    display: flex;
    justify-content: center;
}

.statisticGraphicControl {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 2%;
    margin-bottom: 20px;
}

.statisticGraphicControl p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    opacity: 0.8;
    text-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin: 0 15px;
    transition: 0.2s;
}

.statisticGraphicDelivery {
    display: none;
}

.statisticGraphicTransition {
    display: none;
}

.statisticGraphicErrors {
    display: none;
}

.statisticGraphicControl p:hover {
    color: #F7D81A;
}

.statisticGraphicDiv .recharts-cartesian-grid-vertical {
    display: none;
}

.statisticGraphicDiv .recharts-cartesian-grid-horizontal {
    opacity: 0.2;
}

.taskDiagram .recharts-cartesian-grid-vertical {
    opacity: 0.2;
}

.taskDiagram .recharts-cartesian-grid-horizontal {
    display: none;
}

.statisticKaseDiv {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
}

.statisticKase {
    width: 15%;
    height: 80px;
    background: rgba(217, 217, 217, 0.05);
    /* background: linear-gradient(180deg, #F9B21A -548.28%, rgba(245, 249, 26, 0) 92.12%); */
    border-radius: 16px;
    border: 1px solid #584717d5;
    display: flex;
    padding: 5px;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.statisticKase span {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 3px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
}

.statisticKase b {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
}

.statisticKase p img {
    width: 20px;
    margin-right: 7px;
}

.recharts-tooltip-label,
.recharts-tooltip-item {
    font-size: 18px;
}

.recharts-legend-item-text,
.recharts-default-legend li {
    font-size: 16px;
}

.recharts-text * {
    font-size: 16px;
}

.statisticKase p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.1em;
    margin: 0;
    color: #F7D81A;
    display: flex;
    justify-content: center;
    align-items: center;
}

.diagramDiv {
    width: 97%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 50px;
    margin-bottom: 150px;
}

.taskDiagramDiv {
    width: 100%;
}

.taskDiagram {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.taskDiagram .recharts-surface {
    margin-top: -20px;
}

.taskDiagram .recharts-responsive-container {
    height: 72% !important;
}

.taskDiagram .recharts-yAxis {
    opacity: 0;
}

.taskDiagram .recharts-surface {
    background: rgba(217, 217, 217, 0.05);
    box-shadow: 0px 5px 30px rgb(0 0 0 / 10%), inset 0px -2px 8px rgb(0 0 0 / 15%), inset 0px 2px 8px rgb(0 0 0 / 15%);
    border-radius: 30px;
    padding-bottom: 40px;
}

.diagram {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.diagramYAxis {
    width: 30px;
    height: 170px;
    position: absolute;
    margin-top: -75px;
    margin-left: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.diagramYAxis div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
}

.diagramYAxis img {
    width: 20px;
}

.diagramClassificator {
    width: 93%;
    height: 30px;
    z-index: 50;
    position: absolute;
    margin-top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.diagramClassificator div  {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
}
.diagramClassificator div:nth-child(1) div {
    background-color: #0EABD5;
}
.diagramClassificator div:nth-child(2) div {
    background-color: #F1E56C;
}
.diagramClassificator div:nth-child(3) div {
    background-color: #26B344;
}
.diagramClassificator div:nth-child(4) div {
    background-color: #C41617;
}
.diagramClassificator div div {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
}
.diagramClassificator p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    text-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
    margin: 0;
}

.taskAnswers::-webkit-scrollbar {
    width: 5px;
}

.recharts-bar-rectangles {
    margin: 20px;
}


.taskAnswers::-webkit-scrollbar-thumb {
    background-color: gray;
}

.taskAnswers {
    width: 25%;
    height: 800px;
    background: rgba(217, 217, 217, 0.05);
    box-shadow: 0px 5px 30px rgb(0 0 0 / 10%), inset 0px -2px 8px rgb(0 0 0 / 15%), inset 0px 2px 8px rgb(0 0 0 / 15%);
    border-radius: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
}

.taskAnswers h4 {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    color: #F7D81A;
    width: 75%;
    margin-bottom: 20px;

}

.answer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 70%;
}

.answer p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    margin: 0;
    margin-bottom: 6px;
}

.answer span {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

/* recharts-layer recharts-cartesian-axis recharts-yAxis yAxis */
.recharts-cartesian-axis-ticks {
    width: 100px;
}

.recharts-tooltip-label {
    color: white;
}

.recharts-tooltip-wrapper {
    outline: none !important;
}

.recharts-default-tooltip {
    background-color: #141414 !important;
    border: 1px solid #584717d5 !important;
    outline: none !important;
    border-radius: 12px;
}

.recharts-rectangle {
    border-radius: 12px;
}

.recharts-surface {
    margin-bottom: 2px;
}

.taskTimerDiv {
    width: 100%;
    min-height: 200px;
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.timerDiv {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
}

.timerDiv img {
    position: absolute;
    width: 320px;
    animation-name: timer;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.timerDiv p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    margin: 0;
}

.timerDiv h4 {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    letter-spacing: 0.2em;
    margin: 10px 0;
    margin-bottom: 15px;
    text-align: center;
}

.timerDiv span {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
}

.timer h3 {
    background: linear-gradient(210.19deg, #93F7AC 0%, #0DD337 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 30px;
}
.timer h4 {
    background: linear-gradient(210.19deg, #93F7AC 0%, #0DD337 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

}

.errorTimer h4 {
    background: linear-gradient(231.86deg, #C41617 35.92%, rgba(255, 85, 85, 0.78) 67.19%, rgba(222, 58, 57, 0) 100.02%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;


}

/* .recharts-default-legend {
        display: none;
    } */

@keyframes timer {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 1100px) {

    .statisticKase p,
    .statisticKase b,
    .statisticKase span {
        font-size: 15px;
    }

    .statisticKase {
        height: 70px;
    }

    .taskAnswers {
        width: 27%;
    }

    .statisticGraphicDiv .taskDiagram,
    .taskAnswers {
        border-radius: 20px;
    }

    .timerDiv img {
        width: 290px;
    }

    .timer h4 {
        font-size: 40px;
    }
}

@media only screen and (max-width: 1000px) {
    .taskTimerDiv {
        flex-direction: column;
    }

    .statisticKase {
        width: 19%;
        min-width: 140px;
    }

    .statisticKaseDiv {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 720px) {
    .statisticKase {
        margin: 10px 3%;
    }

    .diagramDiv {
        flex-direction: column-reverse;
        align-items: center;
    }

    .taskAnswers {
        width: 70%;
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 600px) {
    .statisticGraphicControl {
        justify-content: center;
    }
    .statisticGraphicControl p{
        width: 40%;
        text-align: center;
        margin-bottom: 15px;
    }

}
@media only screen and (max-width: 500px) {
    .taskAnswers {
        min-width: 320px;
        width: 100%;
    }

    .taskDiagramDiv {
        min-width: 320px;
        width: 100%;
    }
    
    .statisticGraphicDiv {
        min-width: 320px;
        width: 100%;
    }
}
@media only screen and (max-width: 350px) {
    .statisticGraphicControl p{
        width: 80%;
    }
}