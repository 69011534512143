.adminMenus{
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(0deg,#686767 -238.95%,#1e1e1e 50%);
    background: linear-gradient(0deg,#686767 -238.95%,#1e1e1e 50%);
    display: flex;
    align-items: center;
    z-index: 3;
}
.adminMenus a{
    font-size: 16px;
    color: #fff;
    margin: 10px;
    border-bottom: 1px solid rgb(131, 131, 131);
    padding-bottom: 3px;
}
.adminMenus a:last-child{
    border-bottom: 0px solid rgb(131, 131, 131);
    position: absolute;
    right: 10px;
}

.adminLogo{
    font-family: DaysOne;
    color: #f7d81a;
    margin: 0 20px;
    font-size: 23px;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: start;
    width: 30px;
    height: 30px;
}
.adminLogo::after{
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    top: -1px;
    left: -1px;
    border: 1px solid #f7d81a;
    border-radius: 2px;
}
