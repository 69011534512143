.blogDiv {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerBlog {
    color: #f4f4f4;
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    line-height: 51px;
    margin: 0;
}

.blogs {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 50px;
    justify-content: center;

}

.blog {
    width: 350px;
    max-width: 95%;
    border-radius: 20px;
    border: 1px solid rgb(72, 72, 72);
}

.blog .blogContnetdiv {
    padding: 15px;
    padding-top: 0px;
    box-sizing: border-box;
    border-radius: 0 0 20px 20px;
}

.blog .blogContnetdiv div {
    color: #a9a9a9;
    font-size: 15px;
    line-height: 16px;
    text-align: left;
}

.blog img {
    width: 100%;
    border-radius: 20px 20px 0 0;

}

.blog h2 {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0;
    margin-top: 5px;
    border-bottom: 1px solid #cecece5f;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.blog p {
    font-family: Gilroy;
    font-size: 15px;
    line-height: 16px;
    color: #a9a9a9;
    text-align: left;
    margin: 0;
    margin-top: 0px;
}

.blog span {
    font-family: Gilroy;
    font-size: 12px;
    margin: 0;
    color: gray;
}

@media only screen and (max-width: 630px) {
    .blogDiv {
        padding-top: 50px;
    }

    .blogs {
        gap: 30px;
    }

    .blogs a {
        padding-bottom: 30px;
        border-bottom: 1px solid gray;
    }

    .blogs a:last-child {
        border-bottom: 0px;

    }
}