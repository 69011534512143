.helpDiv{    
    position: absolute;       
    display: flex;
    height: calc(100% + 40px);
    margin-left: -100%;
}
.infoPng{    
    /* background-image: url(../../../../public/static/img/cabinet/info.png);
    background-size: 20px;     */
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    z-index: 4;
}
.mailingTgUTMDiv .infoPng img{    
    width: 20px;
    height: 20px;
    display: flex;   
}
.infoPng img{    
    width: 20px;
    height: 20px;
    display: flex;   

}
.infoText{
    padding: 20px;
    border: 1px solid #F7D81A;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(2px);
    border-radius: 10px;
    height: auto;
    width: 250px;
    max-width: 90vw;
    margin-top: 15px;
    margin-left: 10px;
    z-index: 3;
    position: absolute;
}
.helpDiv  .infoText p{
    color: white;
    font-size: 13px;
    line-height: 17px;
    margin: 0;
    font-family: Gilroy;
    text-transform:none;
    position: relative;
    opacity: 1;
}
@media only screen and (max-width: 1000px) {
    .helpDiv{
        display: none;
    }
}