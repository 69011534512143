
.selfMailingPageBodyStatistic {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.selfMailingPageBodyStatisticInfo {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.selfMailingPageBodyStatisticInfo div p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    text-align: center;
    letter-spacing: 0.1em;
    background: linear-gradient(252.81deg, #F9B21A 0.14%, rgba(245, 249, 26, 0.78) 64.57%, rgba(245, 249, 26, 0.8) 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0;
}

.selfMailingPageBodyStatisticInfo div span {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #FFFFFF;
}

.selfMailingPageBodyStatisticInfo div .line {
    padding: 0;
    width: 100%;
    margin: 7px 0;
}

.selfMailingPageBodyStatisticInfo div {
    padding: 10px;
    margin: 10px;
    justify-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.selfMailingPageBodyHeader {
    font-family: DaysOne;
    color: #F7D81A;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 38px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    width: 60%;
    margin-left: 5%;
}

.selfMailingPageBodyItem .selfMailingPageBodyHeader {
    text-align: right;
    margin-left: 0;
    width: auto;
}

.selfMailingPageBodyStatistic .selfMailingPageBodyHeader {
    margin-left: 0;
    text-align: center;
    margin-top: 140px;
}

.selfMailingPageBodyDescription b {
    font-size: 23px;
}

.selfMailingPageBodyDescription {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    margin: 0;
}


@media only screen and (max-width: 1400px) {
    .selfMailingPageBodyHeader {
        width: 75%;
    }

}

@media only screen and (max-width: 1100px) {
    .selfMailingPageBodyHeader {
        font-size: 25px;
    }
}

@media only screen and (max-width: 700px) {

    .selfMailingPageBodyHeader {
        font-size: 25px;
    }

    .selfMailingPageBodyStatisticInfo {
        flex-direction: column;
    }

    .selfMailingPageBodyItem .selfMailingPageBodyHeader {
        text-align: left;
    }

    .selfMailingPageBodyDescription {
        width: 95%;
        text-align: center;
    }

}

@media only screen and (max-width: 560px) {

    .selfMailingPageBodyHeader,
    .selfMailingPageBodyItem .selfMailingPageBodyHeader {
        font-size: 23px;
        text-align: center;
        width: 90%;
        margin: auto;

    }
}

@media only screen and (max-width: 500px) {

    .selfMailingPageBodyHeader {
        width: 95%;
        margin-left: 2.5%;
    }

}