.contactFon {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: linear-gradient(90deg, rgba(20, 20, 20, 1) 0%, rgba(21, 20, 20, 1) 64%, rgba(54, 36, 34, 1) 100%);
    top: 0;
    z-index: -1;
}

.contactDiv {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.contactSocial {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contactLinks img:hover {
    filter:  invert(0) brightness(1);
}
.contactLinks img {
    transition: .3s;
    filter: brightness(0) invert(1);
    height: 28px;
}
.contactLinks{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    flex-direction: column;
    margin: 30px;
    height: 25vh;
}
.contactLine{
    width: 0.5px;
    height: 30vh;
    background-color: #F4F4F4;
}

.contactInfo {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contactInfo h2 {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 76px;
    letter-spacing: 0.1em;
    background: linear-gradient(252.81deg, #F9B21A 0.14%, rgba(245, 249, 26, 0.78) 64.57%, rgba(245, 249, 26, 0.3) 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.contactInfo p {

    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
}

.contactInfo span {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;
    background: linear-gradient(231.86deg, #C41617 28.89%, rgba(255, 85, 85, 0.78) 75.53%, rgba(222, 58, 57, 0.7) 100.02%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.contactInfo div {
    width: 40%;
    min-width: 370px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media only screen and (max-width: 700px) {
    .contactSocial{
        display: none;
    }
    .contactInfo{
        width: 100%;
    }
    .contactInfo div{
        min-width: 250px;
        width: 80%;
    }
}
@media only screen and (max-width: 450px) {
    .contactInfo h2 {
        font-size: 40px;
    }
    .contactInfo p{
        font-size: 17px;
    }
    .contactInfo span{
        font-size: 15px;
    }
    .contactInfo div{
        min-width: 250px;
        width: 90%;
    }
}