.botTarifsDiv {
    width: 100%;
    box-shadow: 0px 0px 40px rgba(247, 216, 26, 0.47);
    margin-top: 140px;
    padding: 60px 0;
    padding-bottom: 30px;
    position: relative;
    background-image: url(../../../public/static/img/bot/circles.png);
    background-repeat: no-repeat;
    background-position-x: 120%;
    background-position-y: bottom;
    min-height: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.botTarifsDiv .botHeaderText {
    margin-top: 0 !important;
}

.botTarifElipse {
    position: absolute;
    width: 121px;
    height: 121px;
    background: rgba(157, 157, 157, 0.15);
    border-radius: 50%;
    top: 60px;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botTarifElipse img {
    width: 85%;
}

.botTarifElipse_2 {
    top: auto;
    bottom: 110px;
    right: -5px;
}

.botTarifElipse_2 img {
    width: 60%;
    transform: translateX(-80%) translateY(80%);
}


.phoneeithwifi {
    position: absolute;
    top: 400px;
    width: 300px;
    /* margin-bottom: 100px; */
}

.vectorLine {
    /* position: absolute; */
    bottom: 20px;
    left: 0;
    width: 100%;
    min-width: 500px;
}

.botTarif {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.botTarif div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.botTarif h5 {
    font-family: 'DaysOne';
    font-size: 150px;
    line-height: 176px;
    display: flex;
    align-items: center;
    margin: 0;
    color: #F7D81A;
    font-weight: lighter;
}

.botTarif h5 h4 {
    font-size: 90px;
    color: #FFFFFF;
    margin: 0;
    margin-top: -35px;
}

.botTarif h5 h6 {
    font-size: 90px;
    margin: 0;
    margin-top: -30px;
    margin-right: 30px;
}

.botTarif h4 {
    font-family: 'DaysOne';
    font-size: 32px;
    line-height: 50px;
    display: flex;
    align-items: center;
    margin: 0;
    margin-top: -65px;
    margin-right: -247px;
    color: #fff;
    text-shadow: #1e1d14 -5px -7px 0px, #1e1d14 0px -7px 0px, #1e1d14 5px -7px 0px, #1e1d14 5px 7px 0px;
}

.botTarif h4 i {
    margin-left: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

}

.botTarif h4 i::before {
    content: ' ';
    position: absolute;
    height: 70px;
    width: 220px;
    background-image: url(../../../public/static/img/bot/priceline.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 200px;
}

.botTarifsDiv h3 {
    font-weight: lighter;
    font-family: 'Gilroy';
    font-size: 15px;
    line-height: 15px;
    color: #FFFFFF;
    margin: 0;
    width: 100%;
    align-items: center;
    text-align: center;
}

.botTarif p {
    font-family: 'DaysOne';
    font-size: 103px;
    line-height: 88px;
    display: flex;
    align-items: center;
    margin: 0;
    margin-top: 15px;
    color: #F7D81A;

}

.botTarif span {
    font-size: 29px;
    color: #FFFFFF;
    margin-top: 30px;
    font-weight: lighter;
}

.botTarif h2 {
    font-size: 61px;
    margin: 0;
    color: #FFFFFF;
    font-weight: lighter;
}

.botTarif a img {
    position: absolute;
    transform: translatey(-90%) translateX(190%) rotate(0deg);
    transition: 0.3s;
}

.botTarif a {
    color: #1e1d14;
    font-size: 15px;
    padding: 15px 30px;
    background: #F7D81A;
    border: 1px solid #F7D81A;
    border-radius: 10px;
    margin-top: 90px;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}

.botTarif a:hover {
    color: #F7D81A;
    background: transparent;
}

.botTarif a:hover img {
    transform: translatey(-0%) translateX(50%) rotate(15deg);

    margin-left: 32%;
    margin-bottom: 17px;
}

.botTarif * {
    white-space: nowrap;
}

@media screen and (max-width:1300px) {
    .phoneeithwifi {
        width: 300px;
        opacity: 0.5;
    }

    .botTarif a img {
        color: #1e1d14;
        font-size: 15px;
        width: 130px;
    }

}

@media screen and (max-width:1000px) {
    .botTarif div {
        scale: 0.8;
    }

    .botTarif a {
        margin-top: 30px;
    }


}

@media screen and (max-width:850px) {
    .botTarif div {
        scale: 0.6;
    }

    .phoneeithwifi {
        display: none;
    }

    .botTarifElipse {
        width: 80px;
        height: 80px;
    }

    .botTarif a {
        margin-top: 20px;
        margin-bottom: 50px;
    }
}

@media screen and (max-width:700px) {
    .botTarif div {
        scale: 0.5;
    }
}

@media screen and (max-width:500px) {
    .botTarifElipse {
        opacity: 0.6;
    }

    .botTarif div {
        scale: 0.4;
    }

    .botTarif {
        height: 250px;
        margin-bottom: 50px;
    }

    .botTarif a {
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .botTarif a {
        margin-top: -50px;
        margin-bottom: 30px;
    }

    .botTarifsDiv {
        padding: 0;
    }
}