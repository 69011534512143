:root {
    --mailingWidth: 520px;
    --mailingHeight: 700px;

    --inviteWidth: 770px;
    --inviteHeight: 350px;

    --parserWidth: 630px;
    --parserHeight: 350px;
}


.telegramServiceDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8vh;
}

.tgSer {
    border-radius: 60px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: 0.2s;

}

.mailingTgLoadingDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.mailingTgLoading {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #141414;
    z-index: 10;
}

.mailingTgLoading img {
    width: 80px;
    top: calc(50vh - 40px);
    position: fixed;
    animation: spin 1s linear infinite;

}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.tgSer:hover .shadowElipse {
    width: 150px;
    height: 150px;
}

.tgSer:hover img {
    scale: 1.05;
}

/* .tgSer:hover .tgSerHover {
    opacity: 0.5;
} */
.tgSerHover {
    transition: 0.2s;
    position: absolute;
    border-radius: 60px;
    z-index: -1;
    opacity: 0;
    box-sizing: border-box;
    background: linear-gradient(210.19deg, rgba(159, 233, 249, 0.7) 0%, rgba(14, 171, 213, 0.7) 100%), #141414;
}

.tgSerMailingHover {
    width: var(--mailingWidth);
    height: var(--mailingHeight);
}

.tgSerInviteHover {
    width: var(--inviteWidth);
    height: var(--inviteHeight);
}

.tgSerParserHover {
    width: var(--parserWidth);
    height: var(--parserHeight);
}

.tgSer p {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.15em;
    color: #F7D81A;
}
.tgSer p span{
    color: #7d7331;
    font-size: 16px;
}

.tgSer img {
    height: 150px;
    transition: 0.2s;
}

.tgSer .shadowElipse {
    height: 100px;
    width: 100px;
    background: rgba(247, 216, 26, 1);
    filter: blur(90px);
    border-radius: 50%;
    position: absolute;
    transition: 0.2s;

}

.tgSerMailing {
    width: 520px;
    height: 700px;
    background-image: url(../../../../public/static/img/cabinet/tgServiceBorder3.png);
}

.tgSerInvite {
    width: 770px;
    height: 350px;
    background-image: url(../../../../public/static/img/cabinet/tgServiceBorder2.png);
}

.tgSerParser {
    width: 630px;
    height: 350px;
    background-image: url(../../../../public/static/img/cabinet/tgServiceBorder1.png);
}

.telegramServiceCase {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mailingTgDiv {
    width: 100%;
}

.mailingTgDiv legend {
    color: white;
    font-family: DaysOne;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
}

.mailingTgDiv .accauntSumm {
    width: 40%;
    padding-bottom: 15px;
    padding-top: 7px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.mailingTgDiv .accauntSumm button {
    font-family: DaysOne;
    background: none;
    border: none;
    color: #F7D81A;
    font-size: 17px;
    cursor: pointer;
}

.mailingTgDiv .accauntSumm .line {
    border-top: 0;
    border-left: 1px solid #f9b21a71;
    height: 30px;
    width: 0;
}

.mailingTgDiv .accauntSumm span i {
    font-size: 17px;
}

.mailingTgDiv .accauntSumm span {
    color: white;
    font-family: DaysOne;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: .1em;
}

.mailingTgDiv .accauntSumm input {
    display: none;
}

.mailingTgDiv p {
    color: white;
    font-family: DaysOne;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    text-transform: uppercase;

}

.mailingTgBlock1 {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    height: 100px;
    padding-top: 20px;
}

.mailingTgBorder {
    position: relative;
    border: 1px solid #f9b21a71;
    border-radius: 12px;
    padding: 4px 20px;
    margin: 5px;
    transition: 0.3s;
}

.mailingTgDiv .controlPanel {
    width: 40%;
    padding: 15px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.mailingTgDiv .controlPanel button img {
    height: 17px;
    margin-right: 10px;
}

.mailingTgDiv .controlPanel button {
    font-family: DaysOne;
    background: none;
    border: none;
    color: #F7D81A;
    font-size: 17px;
    cursor: pointer;
    width: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mailingTgDiv .controlPanel .line {
    border-top: 0;
    border-left: 1px solid #f9b21a71;
    height: 30px;
    width: 0;
}

.mailingTgDiv button {
    transition: 0.2s;
}

.mailingTgDiv button:hover {
    opacity: 0.7;
}

.mailingTgBlock2 {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    min-height: 100px;
    padding-top: 40px;
}

.mailingTgTextUTM {
    width: 37%;
    padding: 0 1.7% 0px 1.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mailingTgPostLinkDiv {
    width: 100%;
    padding: 10px 20px 22px;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.mailingTgPostLinkDiv button {
    font-family: DaysOne;
    background: none;
    border: none;
    color: #F7D81A;
    font-size: 17px;
    cursor: pointer;
    width: 95px;
}

.mailingTgPostLinkDiv button:disabled {
    cursor: default;
}

.mailingTgPostLinkDiv input::placeholder {
    color: rgb(196, 196, 196);
}

.mailingTgPostLinkDiv input {
    font-family: Gilroy;
    outline: none;
    background: none;
    border: 0;
    color: white;
    width: calc(100%);
    font-size: 14px;
}
.mailingTgUTMDiv {
    width: 40%;
    box-sizing: border-box;
    padding: 6px 20px 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.mailingTgUTMDiv P {
    position: absolute;
    color: rgb(118, 255, 0);
    opacity: 0;
    transition: 0.3s;
    z-index: -2;
}

.mailingTgUTMDiv img {
    height: 25px;
    cursor: pointer;
    transition: 0.2s;
    display: none;
}

.mailingTgUTMDiv .actoveUtm {
    display: flex;
}

.mailingTgUTMDiv img:hover {
    opacity: 0.8;
}

.mailingTgUTMDiv button {
    font-family: DaysOne;
    background: none;
    border: none;
    color: #F7D81A;
    font-size: 17px;
    cursor: pointer;
    width: 95px;
}

.mailingTgUTMDiv button:disabled {
    cursor: default;
}

.mailingTgUTMDiv input::placeholder {
    color: rgb(196, 196, 196);
}

.mailingTgUTMDiv input {
    font-family: Gilroy;
    outline: none;
    background: none;
    border: 0;
    color: white;
    width: calc(100% - 100px);
    font-size: 14px;
}

.mailingTgDiv .tgTextError {
    border: 1px solid #c41617;
}

.mailingTgDiv .tgTextError legend {
    color: #c41617;
}

.mailingTgDiv .tgText {
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mailingTgDiv .tgText legend {
    margin-left: 10px;
}

textarea::placeholder {
    color: rgb(196, 196, 196);
}

textarea {
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    color: white;
}

.mailingTgDiv .tgText textarea {
    width: 100%;
    min-height: 209px;
    height: 209px;
    resize: vertical;
    display: block;
    font-size: 15px;
    padding: 10px;
    padding-left: 1%;
    padding-right: 2%;
    font-family: 'Gilroy';
}

.tgUsersBlock {
    width: calc(40% + 50px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.mailingBlock2 .tgUsersBlock input {
    display: none;
}

.mailingBlock2 .tgUsersBlock textarea {
    height: 251px;
    min-height: 251px;
}

.tgUsersBlock fieldset {
    width: 35%;
    min-height: 228px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.tgUsersBlock fieldset .line {
    border-top: 1px solid #f9b21a71;
    border-left: 0;
    height: 0;
    width: 95%;
}

.tgUsersBlock fieldset span {
    color: white;
    font-family: DaysOne;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: .1em;
    margin-bottom: 5px;
}

.tgUsersBlock fieldset input {
    display: none;
}

.tgUsersBlock fieldset textarea {
    resize: vertical;
    min-height: 175px;
    height: 175px;
    width: 100%;
    margin-top: 10px;
    background-color: rgba(249, 178, 26, 0.025);
    box-shadow: 0px 0px 12px 6px rgba(249, 178, 26, 0.025);
    caret-color: rgba(0, 0, 0, 0);
}

.tgUsersBlock fieldset button {
    font-family: DaysOne;
    background: none;
    border: none;
    color: #F7D81A;
    font-size: 17px;
    cursor: pointer;
    margin: 5px 0;
}

.mailingTgBlock3 {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    min-height: 100px;
    padding-top: 40px;
}

.tgServicesUsersParams span {
    color: white;
    font-family: DaysOne;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: .1em;
}


.tgServicesUsersParams {
    width: 40%;
    height: 180px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin-top: 12px;
}

.userParam .input label {
    background-color: #F7D81A;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    line-height: 30px;
}

.minusLabel {
    border-radius: 8px 0 0 8px;
}

.plusLabel {
    border-radius: 0 8px 8px 0;
}

.tgServicesUsersParams input {
    background: none;
    outline: 0;
    border: 0;
    color: white;
    font-size: 12px;
    background-color: rgba(249, 178, 26, 0.025);
    height: 20px;
    width: 80px;
    text-align: center;
    border-top: 2px solid #F7D81A;
    border-bottom: 2px solid #F7D81A;
}

.userParam .input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
}

.userParam {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.tgMediaContent {
    width: 37%;
    min-height: 170px;
    padding: 10px 1.5% 15px 1.5%;
    display: flex;
    justify-content: center;
    align-content: inherit;
}

.tgContentUploadDiv {
    width: 20%;
}

.tgMediaContent .line {
    border-top: 0;
    border-left: 1px solid #f9b21a71;
    height: 150px;
    width: 0;
    margin: 0 5%;
}

.tgContent {
    width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.tgContent p {
    color: gray;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: .1em;
    margin-top: 5px;
    text-align: center;
    text-transform: none;
}

.tgContent span {
    color: white;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: .1em;
    margin-top: 5px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 350px;
}

.tgContent .contentError {
    color: rgb(255, 119, 119);
    text-align: center;
    text-overflow: inherit;
    white-space: unset;
}

.tgContent a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tgContent video,
.tgContent img {
    max-width: 90%;
    max-height: 120px;
}

.tgContentUploadDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tgContentUploadDiv input {
    display: none;
}

.tgContentUploadDiv button {
    font-family: DaysOne;
    background: none;
    border: none;
    color: #F7D81A;
    font-size: 17px;
    cursor: pointer;
}

.mailingTgBlock4 {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    min-height: 100px;
    padding-top: 40px;
    margin-bottom: 150px;
}

.mailingTgDiv .tgLog {
    width: 40%;
    min-height: 50px;
    padding: 4px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.mailingTgDiv .tgLog button {
    font-family: DaysOne;
    background: none;
    border: none;
    color: #F7D81A;
    font-size: 17px;
    cursor: pointer;
    margin: 5px 0;
}

.mailingTgDiv .tgLog legend {
    margin-left: 10px;
}

.mailingTgDiv .tgLog textarea {
    width: 100%;
    min-height: 174px;
    height: 174px;
    resize: vertical;
    display: block;
    font-size: 13px;
    padding: 10px;
    padding-left: 3%;
    font-family: 'Gilroy';
    scroll-behavior: smooth;
}

.mailingTgDiv .tgLog .line {
    border-top: 1px solid #f9b21a71;
    border-left: 0;
    height: 0;
    width: 95%;
}

.mailingTgBlock4 .tgUsersBlock {
    width: 40%;
    padding: 0 0.5% 0px 0.5%;
}

.invitingTgDiv {
    width: 100%;
}

.invitingTgDiv .mailingTgBlock1 {
    height: auto;
    align-items: flex-start;
}

.invitingTgDiv .accauntSumm {
    width: calc(100% - 50px);
}

.invitingTgDiv .mailingTgBlock1 .fitemOfBlock1 {
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.groupLink {
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 5px 20px 12px 20px;
}

.groupLink input {
    outline: none;
    background: none;
    border: 0;
    color: white;
    width: 100%;
}

.invitingTgDiv .tgServicesUsersParams {
    width: calc(100% - 50px);
    height: 140px;
}

.invitingTgDiv .mailingTgBlock1 fieldset textarea {
    min-height: 284px;
    height: 284px;
}

.invitingTgAccountsInfo {
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.mailingTgBlock4 .invitingTgAccountsInfo {
    width: 40%;
}

.invitingTgAccountsInfo p {
    width: 33%;
    text-align: center;
}

.invitingTgAccountsInfo button {
    font-family: DaysOne;
    background: none;
    border: none;
    color: #F7D81A;
    font-size: 17px;
    cursor: pointer;
}

.invitingTgAccountsInfo .line {
    border-top: 0;
    border-left: 1px solid #f9b21a71;
    height: 30px;
    width: 0;
}

.parserTgDiv {
    width: 100%;
}

.parserTgDiv .groupLink {
    width: calc(40% - 50px);
    padding: 12px 20px 22px 20px;
}

.parserTgDiv .groupLink input {
    font-size: 14px;
}

.parserTgDiv .accauntSumm span {
    color: gray;
    font-size: 14px;
}

.parserTgDiv .tgUsersBlock fieldset textarea {
    min-height: 300px;
    height: 300px;
}

.parserSettings {
    width: calc(40%);
    min-height: 20px;
    flex-direction: column;
}
.parserSettings label, .parserSettings label *{
    transition: 0.3s;
}
.parserSettings .disabledLabel1{
    opacity: 0.4;
}

.parserSettings .disabledLabel2 span:nth-child(1){
    opacity: 0.4;
}
.parserSettings .disabledLabel2 div:nth-child(2) div{
    opacity: 0.4;
}
.parserSettings .disabledLabel1 span:nth-child(1){
    opacity: 1;
}
.parserSettings .disabledLabel1 div:nth-child(2) div{
    opacity: 1;
}

.parserSettings span {
    color: white;
    font-family: DaysOne;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: .1em;
    margin-left: 20px;
}

.parserSettings div {
    display: flex;
    width: 100%;
    margin-top: 5px;

}

.parserSettings div div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.parserSettings div div label {
    color: white;
    font-family: Gilroy;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: .1em;
}

.parserSettings div input[type="radio" i] {
    appearance: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 1px solid #f9b21a9a;
    transition: 0.2s all linear;
    margin-right: 5px;
    position: relative;
    z-index: 1;
}

.parserSettings div input[type="radio" i]:checked {
    border: 5px solid #F7D81A;
}

.parserSettings .rangeInput {
    background: transparent;
    appearance: none;
    width: 90%;
    margin-top: 10px;
}

.parserSettings .rangeInput::-webkit-slider-runnable-track {
    background: gray;
    height: 5px;
    border-radius: 4px;
}

.parserSettings .rangeInput::-moz-range-track {
    background: gray;
    height: 5px;
    border-radius: 4px;
}

.parserSettings .rangeInput::-webkit-slider-thumb {
    background-color: #F7D81A;
    appearance: none;
    height: 20px;
    width: 5px;
    margin-top: -7px;
    border-radius: 2px;
}

.rangeInputDiv p {
    color: white;
    font-family: Gilroy;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: .1em;
    margin-bottom: 0;
}

.rangeInputDiv i {
    color: #F7D81A;
}

.rangeInputDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.tgOnlineRadioDiv {
    flex-wrap: wrap;
    justify-content: center;
}

.parserSettings .tgOnlineRadioDiv div {
    justify-content: flex-start;
    width: 72%;
}

.parserSettings .checkboxSetting {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

:root {
    --color-switch-thumb: #ccc;
    --color-switch-bg: #777;
    --color-switch-bg-active: #F7D81A;
    --switch-size: 32px;
}

.checkboxSetting .switch-input {
    display: none;
}

.checkboxSetting .switch {
    --switch-width: var(--switch-size);
    --switch-height: calc(var(--switch-width) / 2);
    --switch-border: calc(var(--switch-height) / 10);
    --switch-thumb-size: calc(var(--switch-height) - var(--switch-border) * 2);
    --switch-width-inside: calc(var(--switch-width) - var(--switch-border) * 2);
    display: block;
    box-sizing: border-box;
    width: var(--switch-width);
    height: var(--switch-height);
    border: var(--switch-border) solid var(--color-switch-bg);
    border-radius: var(--switch-height);
    background-color: var(--color-switch-bg);
    cursor: pointer;
    margin: var(--switch-margin) 0;
    transition: 300ms 100ms;
    position: relative;
    margin-left: 20px;
}

.checkboxSetting .switch::before {
    content: '';
    background-color: var(--color-switch-thumb);
    height: var(--switch-thumb-size);
    width: var(--switch-thumb-size);
    border-radius: var(--switch-thumb-size);
    position: absolute;
    top: 0;
    left: 0;
    transition: 300ms, width 600ms;
}

.checkboxSetting .switch-input:checked+.switch {
    background-color: var(--color-switch-bg-active);
    border-color: var(--color-switch-bg-active);
}

.checkboxSetting .switch:active::before {
    width: 80%;
}

.checkboxSetting .switch-input:checked+.switch::before {
    left: 100%;
    transform: translateX(-100%);
}


.accauntSumm .switch-input {
    display: none;
}

.accauntSumm .switch {
    --switch-width: var(--switch-size);
    --switch-height: calc(var(--switch-width) / 2);
    --switch-border: calc(var(--switch-height) / 10);
    --switch-thumb-size: calc(var(--switch-height) - var(--switch-border) * 2);
    --switch-width-inside: calc(var(--switch-width) - var(--switch-border) * 2);
    display: block;
    box-sizing: border-box;
    width: var(--switch-width);
    height: var(--switch-height);
    border: var(--switch-border) solid var(--color-switch-bg);
    border-radius: var(--switch-height);
    background-color: var(--color-switch-bg);
    cursor: pointer;
    margin: var(--switch-margin) 0;
    transition: 300ms 100ms;
    position: relative;
    /* transform: rotate(270deg); */
}

.accauntSumm .switch::before {
    content: '';
    background-color: var(--color-switch-thumb);
    height: var(--switch-thumb-size);
    width: var(--switch-thumb-size);
    border-radius: var(--switch-thumb-size);
    position: absolute;
    top: 0;
    left: 0;
    transition: 300ms, width 600ms;
}

.accauntSumm .switch-input:checked+.switch {
    background-color: var(--color-switch-bg-active);
    border-color: var(--color-switch-bg-active);
}

.accauntSumm .switch:active::before {
    width: 80%;
}

.accauntSumm .switch-input:checked+.switch::before {
    left: 100%;
    transform: translateX(-100%);
}

.checkboxSetting p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin-right: 22%;
    text-transform: none;
    margin-bottom: 0px;

}

.parsingStartDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
}

.parsingStartDiv button {
    font-family: DaysOne;
    background: none;
    border: none;
    color: #F7D81A;
    font-size: 17px;
    cursor: pointer;
}

.trainingBanner p {
    color: #f7d81a;
    font-family: DaysOne;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    width: 100%;
    margin: 0;
}

.trainingBanner button {
    background-color: #F7D81A;
    border: 0;
    border-radius: 11px;
    color: #000;
    cursor: pointer;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: .1em;
    line-height: 20px;
    transition: .3s;
    padding: 5px 40px;
}

.trainingBanner span {
    font-family: Gilroy;
    font-size: 15px;
    padding: 5px 40px;
    color: white;
    border: 1px solid #F7D81A;
    border-radius: 11px;
    cursor: pointer;
}

/* .cabinetSection .formResponceFon{
    display: flex;
} */
.trainingBanner {
    position: fixed;
    display: none;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    max-width: 320px;
    width: 95%;
    min-height: 150px;
    top: calc(50vh - 75px);
    background: url(../../../../public/static/img/formFon.png);
    background-size: 30%;
    border-radius: 20px;
    box-shadow: inset -2px -4px 4px rgb(255 255 255 / 40%), inset 2px 4px 4px rgb(255 255 255 / 40%);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    z-index: 6;
    padding: 10px 0;
    padding-bottom: 15px;
}

.mailingTgSleepDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.mailingTgSleep {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #14141490;
    backdrop-filter: blur(1px);
    z-index: 10;

}

.mailingTgSleep p {
    color: white;
    font-family: DaysOne;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    text-transform: none;
    text-align: center;
    width: 320px;
    max-width: 90%;
    margin: 0;
}

.mailingTgSleep span {
    color: #F7D81A;
    font-family: DaysOne;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    text-transform: none;
    text-align: center;
    width: 320px;
    max-width: 90%;
    margin: 20px;
}

.mailingTgSleep img {
    width: 80px;
    animation: spin 1s linear infinite;

}

.mailingTgBlockInfo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -25px;
    margin-top: 40px;
}

.mailingTgBlockInfo p {
    width: 140px;
    text-align: center;
    margin: 0 5px;
}

.mailingTgBlockInfo div {
    width: calc(45% - 70px);
    height: 2px;
    background-color: white;
}
.demoDisplay{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}
.mailingTgDiv .proxyLoadDiv{
    width: 100%;
}
@media only screen and (max-width: 1660px) {
    .tgContent span {
        width: 300px;
    }

    .tgSerMailing {
        width: 430px;
        height: 578px;
        border-radius: 50px;
    }

    .tgSerInvite {
        width: 635px;
        height: 289px;
        border-radius: 50px;
    }

    .tgSerParser {
        width: 519px;
        height: 289px;
        border-radius: 50px;
    }

    .tgSer img {
        height: 110px;
    }

    .invitingTgAccountsInfo {
        width: 80%;
    }
}

@media only screen and (max-width: 1500px) {
    .invitingTgAccountsInfo {
        width: 85%;
    }

    .tgContent span {
        width: 250px;
    }

    .tgSerMailing {
        width: 350px;
        height: 471px;
        border-radius: 39px;
    }

    .tgSerInvite {
        width: 516px;
        height: 235px;
        border-radius: 40px;
    }

    .tgSerParser {
        width: 422px;
        height: 235px;
        border-radius: 40px;
    }

    .tgSer img {
        height: 90px;
    }

    .tgSer p {
        font-size: 16px;
        margin-top: 10px;
    }
    .tgSer p span{
        font-size: 14px;
    }
}

@media only screen and (max-width: 1100px) {
    .mailingTgUTMDiv {
        width: 46%;
    }
    .mailingTgDiv {
        width: 95%;
        margin: auto;
    }

    .mailingTgBlock1,
    .mailingTgBlock2,
    .mailingTgBlock3,
    .mailingTgBlock4 {
        justify-content: space-between;
    }

    .mailingTgDiv .controlPanel {
        width: 46%;
    }

    .mailingTgDiv .accauntSumm {
        width: 46%;
    }

    .inviteTgDiv .accauntSumm {
        width: calc(100% - 50px);
    }

    .invitingTgDiv .mailingTgBlock1 {
        flex-direction: row;
    }

    .invitingTgAccountsInfo {
        width: 95%;
    }

    .cabinetSection .invitingTgDiv {
        width: 98%;
    }

    .tgUsersBlock {
        width: calc(46% + 50px);
    }

    .tgUsersBlock {
        width: calc(46% + 50px);
        box-sizing: border-box;
    }

    .mailingTgTextUTM {
        width: 43%;
    }

    .tgMediaContent {
        width: 43%;
    }

    .mailingTgDiv .tgText textarea {
        width: 99%;
    }

    .tgServicesUsersParams {
        width: 46%;
    }

    .mailingTgBlock4 .tgUsersBlock {
        width: 47%;
    }

    .mailingTgDiv .tgLog {
        width: 46%;
    }

    .parsingStartDiv {
        margin: auto;
    }

    .parserSettings .checkboxSetting {
        width: 100%;
    }

    .parserSettings .tgOnlineRadioDiv div {
        width: 92%;
    }

    .mailingTgBlockInfo div {
        width: calc(50% - 70px);
    }
}

@media only screen and (max-width: 1000px) {
    .tgContent span {
        width: 200px;
    }

    .mailingTgDiv .controlPanel button {
        font-size: 15px;
    }

    .mailingTgDiv .accauntSumm button {
        font-size: 15px;
    }

    .mailingTgDiv .accauntSumm span {
        font-size: 15px;
    }

    .tgContentUploadDiv button {
        font-size: 15px;
    }

    .mailingTgDiv .tgLog button,
    .tgUsersBlock fieldset button {
        font-size: 15px;
    }

    .mailingTgDiv .tgLog button {
        margin-top: 5px;
        margin-bottom: 6px;
    }

    .mailingTgDiv {
        width: 98%;
    }
}

@media only screen and (max-width: 900px) {

    .invitingTgDiv .tgServicesUsersParams .mailingTgBlock1,
    .mailingTgBlock2,
    .mailingTgBlock3,
    .mailingTgBlock4 {
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: auto;
    }

    .mailingTgDiv {
        width: 80%;
    }

    .mailingTgBlock1 {
        flex-direction: column-reverse;
        height: auto;
    }

    .mailingTgDiv .accauntSumm {
        width: 97%;
        box-sizing: border-box;

    }

    .mailingTgBlock1 {
        align-items: center;
    }
    .mailingTgDiv .controlPanel {
        width: 97%;
        box-sizing: border-box;
    }

    .tgUsersBlock {
        width: 100%;
        box-sizing: border-box;
    }

    .mailingTgDiv .tgText {
        width: 100%;
        box-sizing: border-box;
    }

    .mailingTgDiv .tgText textarea {
        width: 97%;
    }

    .mailingTgTextUTM {
        width: 97%;
    }

    .mailingTgUTMDiv {
        width: calc(100% - 20px);

    }
        .mailingTgPostLinkDiv{
        width: calc(100% - 40px);
    }

    .tgServicesUsersParams,
    .tgMediaContent {
        width: 100%;
        box-sizing: border-box;
    }

    .mailingTgDiv .tgLog {
        width: 100%;
        box-sizing: border-box;
    }

    .tgMediaContent .line {
        margin: 0 3%;
    }

    .mailingTgBlock4 .tgUsersBlock {
        width: 100%;
        box-sizing: border-box;
    }

    .mailingTgBlock4 {
        flex-direction: column-reverse;
        margin-bottom: 0;
    }

    .mailingTgDiv {
        padding-bottom: 150px;
    }

    .telegramServiceDiv {
        flex-direction: column;
    }

    .tgSerMailing {
        width: 516px;
        height: 235px;
        background-image: url(../../../../public/static/img/cabinet/tgServiceBorder1Mobile.png);
    }

    .mailingTgBlock3 {
        flex-direction: column-reverse;
    }

    .inviteTgDiv .mailingTgBlock3 {
        flex-direction: column;
    }

    .invitingTgDiv .mailingTgBlock1 .fitemOfBlock1 {
        width: 47%;
    }

    .mailingTgBlock4 .invitingTgAccountsInfo {
        width: 55%;
    }

    .invitingTgAccountsInfo {
        margin-top: 10px;
    }

    .invitingTgDiv .tgServicesUsersParams {
        width: 100%;
    }

    .inviteTgDiv .tgUsersBlock {
        width: calc(43% + 50px);
    }

    .mailingTgBlock3 .tgUsersBlock {
        width: 100%;
        justify-content: space-around;
        box-sizing: content-box;
    }

    .inviteTgDiv .groupLink {
        width: calc(100% - 40px);
        box-sizing: content-box;
    }

    .invitingTgAccountsInfo p {
        width: auto;
        font-size: 12px;
    }

    .parserTgDiv .groupLink {
        width: calc(100% - 40px);
        box-sizing: content-box;
    }

    .parserSettings {
        width: 100%;
        padding-bottom: 40px;
    }

    .parserSettings .checkboxSetting {
        width: 60%;
        min-width: 300px;
    }

    .mailingTgBlock2 {
        padding-top: 0;
    }

    .mailingTgBlock3 {
        padding-top: 0;
    }

    .mailingTgBlock4 {
        padding-top: 0;
    }

    .mailingTgBlockInfo {
        margin-bottom: 10px;
    }

}

@media only screen and (max-width: 700px) {
    .invitingTgAccountsInfo {
        width: 90%;
        box-sizing: content-box;
    }

    .parserTgDiv .groupLink {
        padding: 5px 20px 12px 20px;
    }

    .invitingTgDiv .mailingTgBlock1 {
        flex-direction: column;
    }

    .inviteTgDiv .tgUsersBlock {
        width: 100%;
        box-sizing: content-box;
    }

    .invitingTgDiv .mailingTgBlock1 .fitemOfBlock1 {
        width: 100%;
        box-sizing: content-box;

    }


}

@media only screen and (max-width: 600px) {

    .tgSerMailing {
        width: 380px;
        height: 172px;
        border-radius: 27px;
    }

    .tgSerInvite {
        width: 380px;
        height: 173px;
        border-radius: 30px;
    }

    .tgSerParser {
        width: 380px;
        height: 212px;
        border-radius: 30px;
    }

    .tgSer img {
        height: 60px;
    }
}

@media only screen and (max-width: 550px) {
    .invitingTgAccountsInfo {
        flex-direction: column;
    }

    .invitingTgAccountsInfo .line {
        width: 90%;
        border-top: 1px solid #f9b21a71;
        height: 0;
        border-left: 0px;
    }

    .parserTgDiv .groupLink {
        width: calc(97% - 40px);
    }

    .mailingTgDiv .accauntSumm {
        width: 97%;
    }
}

@media only screen and (max-width: 500px) {
    .mailingTgDiv legend {
        margin-left: 10px;
    }

    .tgMediaContent legend {
        margin-left: 20px;
    }

    .tgContent span {
        width: 150px;
    }

    .mailingTgDiv {
        width: 98%;
    }

    .mailingTgDiv .tgText textarea {
        width: 96%;
    }

    .tgServicesUsersParams {
        min-height: 180px;
        height: auto;
    }

    .parserSettings .checkboxSetting {
        width: 100%;
    }
    .parserSettings{
        padding-bottom: 20px;
    }

    .checkboxSetting p {
        margin-right: 0;
        width: 80%;
    }

    .parserSettings .tgOnlineRadioDiv div {
        width: 81%;
    }

}

@media only screen and (max-width: 400px) {

    .mailingTgDiv .controlPanel button,
    .mailingTgDiv .accauntSumm button,
    .mailingTgDiv .accauntSumm span,
    .mailingTgDiv .tgLog button,
    .tgUsersBlock fieldset button,
    .tgContentUploadDiv button {
        font-size: 13px;
    }

    .mailingTgDiv .controlPanel button {
        width: auto;
    }

    .tgServicesUsersParams,
    .tgMediaContent {
        flex-direction: column;
    }

    .tgMediaContent .line {
        border-top: 1px solid #F7D81A;
        border-left: 0;
        width: 95%;
        height: 0;
    }

    .tgContent {
        width: 95%;
        height: 180px;
        align-items: center;
    }

    .tgContentUploadDiv button {
        margin-top: 15px;
    }

    .tgMediaContent {
        align-items: center;

    }

    .tgServicesUsersParams {
        padding-top: 20px;
    }

    .userParam {
        margin-bottom: 20px;
    }

    .tgSerMailing {
        width: 90vw;
        height: 41vw;
        border-radius: 0px;
    }

    .tgSerInvite {
        width: 90vw;
        height: 41vw;
        border-radius: 0px;
    }

    .tgSerParser {
        width: 90vw;
        height: 50.5vw;
        border-radius: 0px;
        background-size: 95%;
        background-repeat: no-repeat;
    }

    .tgSer p {
        margin-bottom: 0;
    }

    .tgSer img {
        height: 40%;
    }

    .checkboxSetting p {
        width: 87%;
    }

    .parserSettings .tgOnlineRadioDiv div {
        width: 90%;
    }

    .parserSettings .checkboxSetting {
        min-width: 200px;

    }
}