:root{
    --userAppWidth: 1550px;
}
.crmUserBase{
    width: var(--userAppWidth);
    min-width: 100%;
    box-sizing: border-box;

    padding-right: 0;
}
.crmUserBaseHeader{
    width: var(--userAppWidth);
    min-width: 100%;
    box-sizing: border-box;

}

.crmUserBaseHeader p:nth-child(7),
.crmUserBase .crmBaseInputDiv:nth-child(7) {
    width: 200px;
}
.crmUserBaseHeader p:nth-child(6),
.crmUserBase .crmBaseInputDiv:nth-child(6) {
    width: 200px;
}
.crmUserBaseHeader p:nth-child(4),
.crmUserBase .crmBaseInputDiv:nth-child(4) {
    width: 200px;
}
.crmUserBaseHeader p:nth-child(3),
.crmUserBase .crmBaseInputDiv:nth-child(3) {
    width: 200px;
}
.crmUserBaseHeader p:nth-child(2),
.crmUserBase .crmBaseInputDiv:nth-child(2) {
    width: 140px;
}
.crmUserBaseHeader p:nth-child(1),
.crmUserBase .crmBaseInputDiv:nth-child(1) {
    width: 200px;
}
.crmBaseInputMobiletag{
    display: none;
}
.crmBaseCheckFilterDiv input{
    width: 15px;
    height: 15px;
}
@media only screen and (max-width: 800px) {
    .crmBaseFilter {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* gap: 20px; */
        height: auto;
        padding-bottom: 20px;
        padding-top: 40px;
    }

    .crmBaseFilterDiv {
        flex-direction: column;
        height: auto;
        width: 250px;
        align-items: flex-start;
        padding: 0;
        gap: 5px;
    }
    .crmBaseFilterDiv input {
        width: 227px;
        margin-right: 0;
    }

    .crmBaseHeader {
        display: none;
    }

    .crmBaseContainer {
        border-radius: 10px;
        overflow-x: hidden;
        padding: 5px;
        padding-top: 20px;
        box-sizing: border-box;
        height: auto;
    }

    .crmBaseDiv {
        padding: 0;
        
    }

    .crmUserBase {
        height: auto;
        width: 100%;
        flex-direction: column;
        gap: 10px;
        border-radius: 3px !important;
        box-sizing: border-box;
        justify-content: center;
        padding: 7px;
        padding-bottom: 20px;
        margin-bottom: 10px;
        padding-top: 20px;
    }

    .crmUserBase textarea {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }

    .crmBaseInputSelect,
    .crmBaseInputDiv {
        width: 100%;
    }

    .crmBaseInputDiv:nth-child(4) {
        width: 100%;
    }
    .crmBaseInputDiv:nth-child(2){
        margin: 10px 0;
    }
    .crmUserBase img {
        top: 17px;
        left: 17px;
    }
    .crmBaseNavigation{
        position: fixed;
        bottom: 10px;
        z-index: 6;
    }
    .crmBaseNavigation p{
        background-color: rgba(128, 128, 128, 0.405);        
        padding: 5px;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .crmUserBaseHeader p:nth-child(7),
    .crmUserBase .crmBaseInputDiv:nth-child(7),
    .crmUserBaseHeader p:nth-child(6),
    .crmUserBase .crmBaseInputDiv:nth-child(6),
    .crmUserBaseHeader p:nth-child(4),
    .crmUserBase .crmBaseInputDiv:nth-child(4),
    .crmUserBaseHeader p:nth-child(3),
    .crmUserBase .crmBaseInputDiv:nth-child(3),
    .crmUserBaseHeader p:nth-child(2),
    .crmUserBase .crmBaseInputDiv:nth-child(2),
    .crmUserBaseHeader p:nth-child(1),
    .crmUserBase .crmBaseInputDiv:nth-child(1) {
        width: 100%;
    }
    .crmUserBase .crmBaseInputDiv{
        margin: 0 !important;
        justify-content: flex-start;
        padding-left: 5px;
        box-sizing: border-box;        
    }
    .crmBaseInputMobiletag{
        margin-right: 10px;
    }
    .crmBaseInputMobiletag{
        display: block;
    }
    .crmBaseInputDiv span{
        display: flex;
    }
    .crmBaseCheckFilterDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .crmBaseCheckFilterDiv input{
        width: 15px;
        height: 15px;
    }
}