.page404Div {
    width: 100%;
    height: 100vh;
    background: linear-gradient(90deg, rgba(20, 20, 20, 1) 0%, rgba(21, 20, 20, 1) 64%, rgba(54, 36, 34, 1) 100%);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 120;
}

.social404 {
    width: 25%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.social404 .notFoundLink {
    font-family: DaysOne;  
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.15em;
    width: 45%;
    color: rgba(247, 216, 26, 0.7);
    text-align: right;
    margin: 30px;
}

.page404Line {
    width: 0.5px;
    height: 25vh;
    background-color: #F4F4F4;
}

.info404 {
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info404 div {
    height: 80vh;
}

.socialLinks404 {
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    flex-direction: column;
    margin: 30px;
    height: 25vh;

}

.socialLinks404 img {
    height: 28px;
}


.text404 {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 200px;
    letter-spacing: 0.1em;
    background: linear-gradient(252.81deg, #F9B21A 0.14%, rgba(245, 249, 26, 0.78) 64.57%, rgba(245, 249, 26, 0.25) 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 20px;
}

.notFoundText {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 46px;
    letter-spacing: 0.1em;
    background: linear-gradient(231.86deg, #C41617 28.89%, rgba(255, 85, 85, 0.78) 75.53%, rgba(222, 57, 57, 0.3) 100.02%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 0;
    margin-bottom: 30px;
}

.goBackText {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    cursor: pointer;
    width: 95%;
    text-align: right;
}

.info404 div div {
    margin-top: 20vh;

}

.info404 div div p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    text-align: center;
    user-select: none;
}

.info404 div div b {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    margin-left: 10px;
    user-select: text;
}

@media only screen and (max-width: 830px) {
    .info404 {
        width: 70%;
    }
}
@media only screen and (max-width: 630px) {
    .info404 {
        width: 100%;
    }
    .social404{
        display: none;
    }
}
@media only screen and (max-width: 540px) {
    .text404 {
     font-size: 120px;
     width: 100%;
     text-align: center;
    }
    .notFoundText {
        font-size: 28px;
        width: 100%;
        text-align: center;
    }
    .info404 div div p{
        display: flex;
        flex-direction: column;
    }
    .info404 div div b{
        margin: 5px
    };
}
@media only screen and (max-width: 300px) {
    .text404 {
        font-size: 99px;
    }
}
