@font-face {
    font-family: DaysOne;
    src: url(../../css/fonts/DaysOne-Regular.ttf);
}

@font-face {
    font-family: Gilroy;
    src: url(../../css/fonts/Gilroy-Regular.ttf)
}

.selfMailingHeader {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F7D81A;
    margin: 0;
    margin-bottom: 60px;
    margin-top: 100px;
}

.selfMailingScroll {
    width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
}

.selfMailingScroll::-webkit-scrollbar {
    height: 0;
    width: 0;
}

.selfMailingDiv {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    left: 0;
    padding: 30px 0;
    transition: 0.3s;

}

.selfMailing {
    width: 50vw;
    height: 22.2vw;
    max-width: 749px;
    max-height: 361px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-image: url(../../../public/static/img/selbBorder.png);
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 25px;
    transition: 0.2ms;
    margin: 0 30px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    position: relative;
}

.selfMailingText {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selfMailingText h2 {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 28px;
    letter-spacing: 0.1em;
    background: linear-gradient(329.81deg, #F9B21A 0%, rgba(245, 249, 26, 0.78) 64.58%, rgba(245, 249, 26, 0.25) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
    margin: 0;
    max-width: 500px;
}

.selfMailingText p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    text-align: left;
    margin-bottom: 0;
    max-width: 70%;
}

.selfMailingText div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 90%;
}

.selfMailingText button {
    font-family: DaysOne;
    font-size: 20px;
    border: 0;
    background: none;
    color: #F7D81A;
    background-image: url(../../../public/static/img/selfMailingButton.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    padding: 18px 32px;
    gap: 10px;
    width: 220px;
    height: 60px;
    margin-top: 10px;
    border-radius: 20px;
}

.selfMailingIcon {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
}

.selfMailingIcon img {
    width: 130px;
    user-select: none;
    position: absolute;
    transition: 0.3s;

}

.selfMailingIcon .self3d {
    opacity: 0;
    transform: rotate(360deg);

}

.selfMailingIcon .slef2d {
    opacity: 1;
    transform: rotate(0deg);

}


.toLeft {
    width: 50px;
    height: 100px;
    background-image: url(../../../public/static/img/next.png);
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 0% 100% 100% 0% / 51% 50% 50% 49%;
    position: absolute;
    margin-top: 155px;
    transform: rotate(180deg);
    cursor: pointer;
    z-index: 5;
    left: 15px;
}

.toLefRight {
    width: 50px;
    height: 100px;
    background-image: url(../../../public/static/img/next.png);
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 0% 100% 100% 0% / 51% 50% 50% 49%;
    position: absolute;
    margin-top: 155px;
    right: 10px;
    cursor: pointer;
    z-index: 5;
}

.toLeft:hover {
    filter: contrast(.7);
}

.toLefRight:hover {
    filter: contrast(.7);
}

@media only screen and (max-width: 1250px) {
    .selfMailingDiv {
        flex-direction: column;
        gap: 30px;
    }

    .selfMailing {
        width: 72vw;
        height: 32vw;
    }
}

@media only screen and (max-width: 1100px) {

    .selfMailing {
        width: 750px;
        height: 400px;
        max-height: 450px;
        /* max-width: 95%; */
        border-radius: 25;
        background-image: url(../../../public/static/img/smallSelbBorder.png);
    }

    /* .selfMailingText {
        width: 100%;
    }

    .selfMailingIcon {
        margin-right: -50%;
        margin-top: -30px;
    }

    .selfMailingText button {
        margin-top: 50px;
    } */
}

@media only screen and (max-width: 900px) {
    .selfMailing {
        width: 650px;
        height: 350px;
        border-radius: 30px;
    }

    .selfMailingText button {
        margin-top: 30px;
    }

    .selfMailingIcon img {
        width: 110px;
    }
}

@media only screen and (max-width: 700px) {
    .selfMailingHeader {
        font-size: 32px;
    }

    .selfMailing {
        width: 300px;
        height: 415px;
        background-image: url(../../../public/static/img/phoneSlbBorder.png);
        border-radius: 25px;
    }

    .selfMailingText h2 {
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 3px;
        text-align: center;
    }

    .selfMailingText p {
        font-size: 14px;
        text-align: center;
    }

    .selfMailingText button {
        margin-top: 100px;
    }

    .selfMailingIcon img {
        width: 90px;
    }

    .selfMailingIcon {
        margin-top: 160px;
        margin-right: 0;
        width: 100%;
        right: 0;

    }

    .selfMailingText div {
        width: 95%;
        align-items: center;
        align-content: center;
    }



    .selfTelegram {
        background: linear-gradient(45deg, rgba(17, 150, 195, .33) 0%, rgba(116, 223, 244, .33) 100%);
        box-shadow: 0px 0px 20px 10px rgba(28, 191, 227, 0.2);
    }

    .selfTelegram .self3d {
        opacity: 1;
        transform: rotate(0deg);
    }

    .selfTelegram .self2d {
        opacity: 0;
        transform: rotate(360deg);

    }

    .selfTelegram button {
        border: 1px solid rgba(116, 223, 244, .33);
        border-radius: 20px;
        background-image: none;
        color: #F4F4F4;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
    }

    .selfTelegramHover {
        background: linear-gradient(45deg, rgba(17, 150, 195, .33) 0%, rgba(116, 223, 244, .33) 100%);
        box-shadow: 0px 0px 20px 10px rgba(28, 191, 227, 0.2);
    }

    .selfTelegramHover .self3d {
        opacity: 1;
        transform: rotate(0deg);
    }

    .selfTelegramHover .self2d {
        opacity: 0;
        transform: rotate(360deg);

    }

    .selfTelegramHover button {
        border: 1px solid rgba(116, 223, 244, .33);
        border-radius: 20px;
        background-image: none;
        color: #F4F4F4;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
    }

    .selfWhatsApp {
        background: linear-gradient(45deg, rgba(12, 198, 44, .33) 0%, rgba(127, 243, 159, .33) 100%);
        box-shadow: 0px 0px 20px 10px rgba(51, 223, 80, 0.2);
    }

    .selfWhatsApp .self3d {
        opacity: 1;
        transform: rotate(0deg);
    }

    .selfWhatsApp .self2d {
        opacity: 0;
        transform: rotate(360deg);

    }

    .selfWhatsApp button {
        border: 1px solid rgba(12, 198, 44, 1);
        border-radius: 20px;
        background-image: none;
        color: #F4F4F4;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
    }

    .selfWhatsAppHover {
        background: linear-gradient(45deg, rgba(12, 198, 44, .33) 0%, rgba(127, 243, 159, .33) 100%);
        box-shadow: 0px 0px 20px 10px rgba(51, 223, 80, 0.2);
    }

    .selfWhatsAppHover .self3d {
        opacity: 1;
        transform: rotate(0deg);
    }

    .selfWhatsAppHover .self2d {
        opacity: 0;
        transform: rotate(360deg);

    }

    .selfWhatsAppHover button {
        border: 1px solid rgba(12, 198, 44, 1);
        border-radius: 20px;
        background-image: none;
        color: #F4F4F4;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
    }

    .selfViber {
        background: linear-gradient(210.19deg, rgba(146, 135, 200, 0.3) 0%, rgba(104, 93, 169, 0.3) 100%);
        box-shadow: 0px 0px 20px 10px rgba(133, 121, 192, 0.2);
    }

    .selfViber .self3d {
        opacity: 1;
        transform: rotate(0deg);
    }

    .selfViber .self2d {
        opacity: 0;
        transform: rotate(360deg);

    }

    .selfViber button {
        border: 1px solid #B15BBE;
        border-radius: 20px;
        background-image: none;
        color: #F4F4F4;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
    }

    .selfViberHover {
        background: linear-gradient(210.19deg, rgba(146, 135, 200, 0.3) 0%, rgba(104, 93, 169, 0.3) 100%);
        box-shadow: 0px 0px 20px 10px rgba(133, 121, 192, 0.2);
    }

    .selfViberHover .self3d {
        opacity: 1;
        transform: rotate(0deg);
    }

    .selfViberHover .self2d {
        opacity: 0;
        transform: rotate(360deg);

    }

    .selfViberHover button {
        border: 1px solid #B15BBE;
        border-radius: 20px;
        background-image: none;
        color: #F4F4F4;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
    }

    .selfVk {
        background: linear-gradient(45deg, rgba(17, 150, 195, .33) 0%, rgba(116, 223, 244, .33) 100%);
        box-shadow: 0px 0px 20px 10px rgba(28, 191, 227, 0.2);
    }

    .selfVk .self3d {
        opacity: 1;
        transform: rotate(0deg);
    }

    .selfVk .self2d {
        opacity: 0;
        transform: rotate(360deg);

    }

    .selfVk button {
        border: 1px solid rgba(116, 223, 244, 1);
        border-radius: 20px;
        background-image: none;
        color: #F4F4F4;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
    }

    .selfVkHover {
        background: linear-gradient(45deg, rgba(17, 150, 195, .33) 0%, rgba(116, 223, 244, .33) 100%);
        box-shadow: 0px 0px 20px 10px rgba(28, 191, 227, 0.2);
    }

    .selfVkHover .self3d {
        opacity: 1;
        transform: rotate(0deg);
    }

    .selfVkHover .self2d {
        opacity: 0;
        transform: rotate(360deg);

    }

    .selfVkHover button {
        border: 1px solid rgba(116, 223, 244, 1);
        border-radius: 20px;
        background-image: none;
        color: #F4F4F4;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
    }

    .selfInstagramHover {
        background: linear-gradient(45deg, rgba(241, 206, 88, 0.33) 0%, rgba(209, 63, 131, 0.33) 48%, rgba(131, 128, 210, 0.33) 100%);
        box-shadow: 0px 0px 20px 10px rgba(177, 91, 190, 0.2);
    }

    .selfInstagramHover .self3d {
        opacity: 1;
        transform: rotate(0deg);
    }

    .selfInstagramHover .self2d {
        opacity: 0;
        transform: rotate(360deg);

    }

    .selfInstagramHover button {
        border: 1px solid #B15BBE;
        border-radius: 20px;
        background-image: none;
        color: #F4F4F4;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
    }

    .selfInstagram {
        background: linear-gradient(45deg, rgba(241, 206, 88, 0.33) 0%, rgba(209, 63, 131, 0.33) 48%, rgba(131, 128, 210, 0.33) 100%);
        box-shadow: 0px 0px 20px 10px rgba(177, 91, 190, 0.2);
    }

    .selfInstagram .self3d {
        opacity: 1;
        transform: rotate(0deg);
    }

    .selfInstagram .self2d {
        opacity: 0;
        transform: rotate(360deg);

    }

    .selfInstagram button {
        border: 1px solid #B15BBE;
        border-radius: 20px;
        background-image: none;
        color: #F4F4F4;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
    }
}

@media only screen and (max-width: 450px) {
    .selfMailingHeader {
        font-size: 7vw;
        margin: 80px auto;
    }

    .toLefRight,
    .toLeft {
        width: 37.5px;
        height: 75px;
        margin-top: 250px;
    }
}


.selfTelegram:hover {
    background: linear-gradient(45deg, rgba(17, 150, 195, .33) 0%, rgba(116, 223, 244, .33) 100%);
    box-shadow: 0px 0px 20px 10px rgba(28, 191, 227, 0.2);
}

.selfTelegram:hover .self3d {
    opacity: 1;
    transform: rotate(0deg);
}

.selfTelegram:hover .self2d {
    opacity: 0;
    transform: rotate(360deg);

}

.selfTelegram:hover button {
    border: 1px solid rgba(116, 223, 244, .33);
    border-radius: 20px;
    background-image: none;
    color: #F4F4F4;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
}

.selfTelegramHover {
    background: linear-gradient(45deg, rgba(17, 150, 195, .33) 0%, rgba(116, 223, 244, .33) 100%);
    box-shadow: 0px 0px 20px 10px rgba(28, 191, 227, 0.2);
}

.selfTelegramHover .self3d {
    opacity: 1;
    transform: rotate(0deg);
}

.selfTelegramHover .self2d {
    opacity: 0;
    transform: rotate(360deg);

}

.selfTelegramHover button {
    border: 1px solid rgba(116, 223, 244, .33);
    border-radius: 20px;
    background-image: none;
    color: #F4F4F4;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
}

.selfWhatsApp:hover {
    background: linear-gradient(45deg, rgba(12, 198, 44, .33) 0%, rgba(127, 243, 159, .33) 100%);
    box-shadow: 0px 0px 20px 10px rgba(51, 223, 80, 0.2);
}

.selfWhatsApp:hover .self3d {
    opacity: 1;
    transform: rotate(0deg);
}

.selfWhatsApp:hover .self2d {
    opacity: 0;
    transform: rotate(360deg);

}

.selfWhatsApp:hover button {
    border: 1px solid rgba(12, 198, 44, 1);
    border-radius: 20px;
    background-image: none;
    color: #F4F4F4;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
}

.selfWhatsAppHover {
    background: linear-gradient(45deg, rgba(12, 198, 44, .33) 0%, rgba(127, 243, 159, .33) 100%);
    box-shadow: 0px 0px 20px 10px rgba(51, 223, 80, 0.2);
}

.selfWhatsAppHover .self3d {
    opacity: 1;
    transform: rotate(0deg);
}

.selfWhatsAppHover .self2d {
    opacity: 0;
    transform: rotate(360deg);

}

.selfWhatsAppHover button {
    border: 1px solid rgba(12, 198, 44, 1);
    border-radius: 20px;
    background-image: none;
    color: #F4F4F4;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
}

.selfViber:hover {
    background: linear-gradient(210.19deg, rgba(146, 135, 200, 0.3) 0%, rgba(104, 93, 169, 0.3) 100%);
    box-shadow: 0px 0px 20px 10px rgba(133, 121, 192, 0.2);
}

.selfViber:hover .self3d {
    opacity: 1;
    transform: rotate(0deg);
}

.selfViber:hover .self2d {
    opacity: 0;
    transform: rotate(360deg);

}

.selfViber:hover button {
    border: 1px solid #B15BBE;
    border-radius: 20px;
    background-image: none;
    color: #F4F4F4;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
}

.selfViberHover {
    background: linear-gradient(210.19deg, rgba(146, 135, 200, 0.3) 0%, rgba(104, 93, 169, 0.3) 100%);
    box-shadow: 0px 0px 20px 10px rgba(133, 121, 192, 0.2);
}

.selfViberHover .self3d {
    opacity: 1;
    transform: rotate(0deg);
}

.selfViberHover .self2d {
    opacity: 0;
    transform: rotate(360deg);

}

.selfViberHover button {
    border: 1px solid #B15BBE;
    border-radius: 20px;
    background-image: none;
    color: #F4F4F4;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
}

.selfVk:hover {
    background: linear-gradient(45deg, rgba(17, 150, 195, .33) 0%, rgba(116, 223, 244, .33) 100%);
    box-shadow: 0px 0px 20px 10px rgba(28, 191, 227, 0.2);
}

.selfVk:hover .self3d {
    opacity: 1;
    transform: rotate(0deg);
}

.selfVk:hover .self2d {
    opacity: 0;
    transform: rotate(360deg);

}

.selfVk:hover button {
    border: 1px solid rgba(116, 223, 244, 1);
    border-radius: 20px;
    background-image: none;
    color: #F4F4F4;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
}

.selfVkHover {
    background: linear-gradient(45deg, rgba(17, 150, 195, .33) 0%, rgba(116, 223, 244, .33) 100%);
    box-shadow: 0px 0px 20px 10px rgba(28, 191, 227, 0.2);
}

.selfVkHover .self3d {
    opacity: 1;
    transform: rotate(0deg);
}

.selfVkHover .self2d {
    opacity: 0;
    transform: rotate(360deg);

}

.selfVkHover button {
    border: 1px solid rgba(116, 223, 244, 1);
    border-radius: 20px;
    background-image: none;
    color: #F4F4F4;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
}

.selfInstagramHover {
    background: linear-gradient(45deg, rgba(241, 206, 88, 0.33) 0%, rgba(209, 63, 131, 0.33) 48%, rgba(131, 128, 210, 0.33) 100%);
    box-shadow: 0px 0px 20px 10px rgba(177, 91, 190, 0.2);
}

.selfInstagramHover .self3d {
    opacity: 1;
    transform: rotate(0deg);
}

.selfInstagramHover .self2d {
    opacity: 0;
    transform: rotate(360deg);

}

.selfInstagramHover button {
    border: 1px solid #B15BBE;
    border-radius: 20px;
    background-image: none;
    color: #F4F4F4;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
}

.selfInstagram:hover {
    background: linear-gradient(45deg, rgba(241, 206, 88, 0.33) 0%, rgba(209, 63, 131, 0.33) 48%, rgba(131, 128, 210, 0.33) 100%);
    box-shadow: 0px 0px 20px 10px rgba(177, 91, 190, 0.2);
}

.selfInstagram:hover .self3d {
    opacity: 1;
    transform: rotate(0deg);
}

.selfInstagram:hover .self2d {
    opacity: 0;
    transform: rotate(360deg);

}

.selfInstagram:hover button {
    border: 1px solid #B15BBE;
    border-radius: 20px;
    background-image: none;
    color: #F4F4F4;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2) 100%);
}