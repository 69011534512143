.beneficeDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.benefice {
    width: 30%;
    margin: 20px 1%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height:  490px;
    flex-direction: column;
}

.benefice img {
    margin-bottom: 10px;
    scale: 0.8;
    height: 300px;
}
.benefice h2 {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 46px;
    letter-spacing: -0.02em;
    color: #F7D81A;
    text-align: left;
    margin: 0;
    width: 80%;
}

.benefice p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    text-align: left;
    margin: 0;
    margin-top: 30px;
    width: 80%;
    margin-top: 15px;
}
/* 
.beneficeRight h2 {
    text-align: right;
}

.beneficeRight p {
    text-align: right;
} */
.beneficeHeader{
    text-align: center;
}

@media only screen and (max-width: 1500px) {
    .benefice img {
        scale: 0.8;
    }
    
    .benefice {
        margin: 10px 5%;
        width: 40%;
        min-width: 360px;
    }
}
@media only screen and (max-width: 1250px) {
    .benefice img{
        scale: 0.7;
    }
    .benefice h2 {
        font-size: 29px;
    }
    .benefice p {
        font-size: 16px;
    }
}
@media only screen and (max-width: 1000px) {
    .benefice{
        height: auto;
    }
    .benefice img{
        scale: 0.6;
        margin-bottom: -25px;
    }
    .beneficeRight h2 {
        text-align: left;
    }
    
    .beneficeRight p {
        text-align: left;
    }
}
@media only screen and (max-width: 500px) {
    .benefice {
        min-width: 250px;
        width: 95%;
        margin: 5px 2.5%;
        margin-bottom: 50px;
    }
    .benefice h2{
        font-size: 20px;
        width: 90%;
    }
    .benefice p{
        font-size: 14px;
        width: 90%;
    }
}