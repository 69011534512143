:root {
    --appWidth: 2500px
}

.crmBaseDiv {
    position: relative;
    height: calc(100vh - 120px);
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
}

.crmBaseContainer::-webkit-scrollbar {
    height: 2px;
}

.crmBaseContainer {
    background-color: rgba(255, 255, 255, 0.04);
    border-radius: 20px;
    height: 98%;
    width: 100%;
    overflow-x: scroll;
    padding: 30px;
    padding-top: 0;
}

.crmBase img {
    width: 15px;
    position: absolute;
    filter: invert(0.4);
    left: 20px;
    cursor: pointer;
}

.crmBase {
    width: var(--appWidth);
    background-color: #3d3d3d6b;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    padding-right: 50px;
    margin-bottom: 1px;
    position: relative;
}

.crmBase:nth-child(2) {
    border-radius: 10px 10px 0 0;
    border-radius: 10px 10px 0 0;
}

.crmBase:last-child {
    border-radius: 0 0 10px 10px;
}


.crmBase:nth-child(even) {
    background-color: #44444482;
}

.crmBase input:focus {
    outline: none;
}

.crmBaseInputSelect select option {
    background-color: rgba(46, 46, 46, 0.90);
    border-radius: 1px;
    padding: 5px;
}

.crmBaseInputSelect select {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(128, 128, 128, 0.422);
    border-radius: 5px;
    padding: 5px 10px;
    background-color: transparent;
    color: white;
}

.crmBaseInputSelect,
.crmBaseInputDiv {
    height: 30px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.crmBaseInputSelect {
    width: 100px;
}

.crmBaseInputDiv:nth-child(4) {
    width: 150px;
}

.crmBaseInputDiv span {
    font-size: 14px;
    color: white;
    user-select: all;
}

.crmBase input {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(128, 128, 128, 0.422);
    border-radius: 5px;
    padding: 5px 10px;
    background-color: transparent;
    color: white;

}

.crmBase textarea::-webkit-scrollbar {
    width: 4px;
}

.crmBase textarea {
    box-sizing: border-box;
    border: 1px solid rgba(128, 128, 128, 0.422);
    border-radius: 5px;
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    max-width: 350px;
    min-width: 350px;
    min-height: 42px;
}

.crmBaseHeader p {
    font-size: 18px;
    color: white;
    margin: 0;
    width: 250px;
    text-align: center;
}

.crmBaseHeader p:nth-child(6) {
    width: 100px;
}

.crmBaseHeader p:nth-child(3) {
    width: 150px;
}

.crmBaseHeader p:last-child {
    width: 350px;
}

.crmBaseHeader {
    width: var(--appWidth);
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
}

.crmBaseFilter {
    height: 60px;
    display: flex;
}

.crmBaseFilter input:focus {
    outline: none;
}

.crmBaseFilter input {
    background-color: initial;
    border: 1px solid hsla(0, 0%, 50%, .422);
    border-radius: 5px;
    box-sizing: border-box;
    color: #fff;
    padding: 5px 10px;
    width: 250px;
    height: 30px;
}

.crmBaseNavigation {
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.crmBaseNavigation p {
    color: white;
    font-size: 17px;
    margin: 0 20px;
    cursor: pointer;
    z-index: 5;
}

.crmBaseFilterDiv span {
    color: gray;
    align-items: center;
    font-size: 15px;
}

.crmBaseFilterDiv a{
    cursor: pointer;
}
.crmBaseFilterDiv a,
.crmBaseFilterDiv p {
    color: white;
    font-size: 15px;
    margin-right: 20px;
}

.crmBaseFilterDiv input {
    width: 150px;
    margin-right: 20px;
}

.crmBaseFilterDiv input::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.crmBaseFilterDiv {
    display: flex;
    height: 30px;
    align-items: center;
    padding: 0;
    padding-left: 40px;
}

@media only screen and (max-width: 800px) {
    .crmBaseFilter {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* gap: 20px; */
        height: auto;
        padding-bottom: 20px;
        padding-top: 40px;
    }

    .crmBaseFilterDiv {
        flex-direction: column;
        height: auto;
        width: 250px;
        align-items: flex-start;
        padding: 0;
        gap: 5px;
    }
    .crmBaseFilterDiv input {
        width: 227px;
        margin-right: 0;
    }

    .crmBaseHeader {
        display: none;
    }

    .crmBaseContainer {
        border-radius: 10px;
        overflow-x: hidden;
        padding: 5px;
        padding-top: 20px;
        box-sizing: border-box;
        height: auto;
    }

    .crmBaseDiv {
        padding: 0;
    }

    .crmBase {
        height: auto;
        width: 100%;
        flex-direction: column;
        gap: 10px;
        border-radius: 3px !important;
        box-sizing: border-box;
        justify-content: center;
        padding: 7px;
        padding-bottom: 20px;
        margin-bottom: 10px;
    }

    .crmBase textarea {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }

    .crmBaseInputSelect,
    .crmBaseInputDiv {
        width: 100%;
    }

    .crmBaseInputDiv:nth-child(4) {
        width: 100%;
    }
    .crmBaseInputDiv:nth-child(2){
        margin: 10px 0;
    }
    .crmBase img {
        top: 17px;
        left: 17px;
    }
    .crmBaseNavigation{
        position: fixed;
        bottom: 10px;
        z-index: 6;
    }
    .crmBaseNavigation p{
        background-color: rgba(128, 128, 128, 0.405);        
        padding: 5px;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}