input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    user-select: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

.profileDiv {
    width: 80%;
    height: 90vh;
    display: flex;
    padding: 50px 10%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.rofileInfo {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.profileSection {
    border: 1px solid #fff;
    border-radius: 12px;
    width: 300px;
    margin-bottom: 20px;
    padding: 2px 10px;
    padding-bottom: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileSectionHeader {
    background: 0;
    border: 0;
    color: #fff;
    font-family: Gilroy;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: .1em;
    line-height: 25px;
    color: white;
    margin: 0;
}

.profileSection input {
    font-family: Gilroy;
    background: 0;
    border: 0;
    /* border-bottom: 1px solid #fff; */
    color: #fff;
    font-size: 16px;
    font-style: normal;
    letter-spacing: .1em;
    outline: none;
    width: calc(100% - 15px);
    padding-left: 15px;
}

.profileSection input:disabled {
    opacity: 0.8;
    filter: contrast(0.3);
}

@media only screen and (max-width: 1000px) {
    .profileDiv {
        width: 90%;
        padding: 25px 5%;
    }
}

@media only screen and (max-width: 770px) {
    .profileDiv {
        width: 98%;
        padding: 25px 1%;
    }
}

@media only screen and (max-width: 700px) {
    .rofileInfo {
        flex-wrap: nowrap;
        height: auto;
    }
}

@media only screen and (max-width: 370px) {
    .profileDiv {
        width: 100%;
        padding: 25px 0;
    }

    .profileSection {
        width: 90%;
    }
}