.cookieWidgetDiv {
    width: 100%;
    height: 100px;
    background-color: #1414146f;
    border-top: 1px solid #f9b21a71;
    position: fixed;
    border-radius: 12px 12px 0 0;
    left: 0;
    bottom: 0px;
    z-index: 10;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    backdrop-filter: blur(7px);

}

.cookieWidgetDiv p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: white;
    margin: 0;
    font-weight: 400;
    width: 40%;
}

.cookieWidgetDiv a {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: white;
    margin: 0;
    font-weight: bold;
    text-decoration: underline;
}

.cookieWidgetDiv button {
    background: #F7D81A;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #141414;
    padding: 10px 40px;
    border: 0;
    cursor: pointer;
}

@media only screen and (max-width:1000px) {
    .cookieWidgetDiv p {
        width: 60%;
    }
    .cookieWidgetDiv button {
        margin-left: 20px;
    }
}
@media only screen and (max-width:750px) {
    .cookieWidgetDiv {
        flex-direction: column;
        height: auto;
    }
    .cookieWidgetDiv button {
        margin-top: 20px;
        margin-left: 0;
    }
    .cookieWidgetDiv p {
        width: 90%;
        font-size: 13px;
    }
    
}
@media only screen and (max-width:400px) {
    .cookieWidgetDiv button {
        margin-bottom: 80px;
    }
    
}