.checkEmailDiv {
    min-height: 450px;
    width: 500px;
    max-width: 100%;
    position: absolute;
    top: calc(50vh - 230px);
    left: calc(50% - 250px);
    display: none;
    flex-direction: column;
    z-index: 6;
    padding-bottom: 10px;
    background: url(../../../../public/static/img/formFon.png);
    background-size: 30%;
    border-radius: 40px;
    box-shadow: inset -2px -4px 4px rgb(255 255 255 / 40%), inset 2px 4px 4px rgb(255 255 255 / 40%);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    display: none;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.checkEmailDiv span {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1em;
    color: #bebebe;
    max-width: 85%;
    text-align: center;
    cursor: pointer;
}

.checkEmailDiv p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    max-width: 85%;
    text-align: center;
}

.checkEmailDiv h2 {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F7D81A;
    margin: 0;
    margin-top: 50px;
    text-align: center;
}

.checkEmailDiv input {
    background: 0;
    border: 0px;
    width: 50%;
    margin: 25px 0;
    border-bottom: 1px solid #818181;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 25px;
    letter-spacing: 0.1em;
    color: #ffffff;
    outline: none;
    text-align: center;

}

.checkEmailDiv input::-webkit-outer-spin-button,
.checkEmailDiv input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.formResponceDiv {
    z-index: 6;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
}

.formResponceDivActive {
    display: flex;
}

.formResponceFon {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
    top: 0;
    left: 0;
    /* display: none; */
}

@media only screen and (max-width: 550px) {
    .checkEmailDiv {
        width: 95%;
        left: auto;
    }
}
@media only screen and (max-width: 400px) {
    .checkEmailDiv h2{
        font-size: 20px;
    }
}