.splide__slide img{
    height: 500px;
    margin: 10px 0;
}
.sliderDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
#slider .splide__arrow svg{
    fill: #ffdd00;
}
#slider .splide__pagination__page.is-active{
    background: #ffdd00;
}
@media only screen and (max-width: 1500px) {
    .splide__slide img{
        height: 400px;
    }
}
@media only screen and (max-width: 1200px) {
    .splide__slide img{
        height: auto;
        width: 540px;
    }
}
@media only screen and (max-width: 600px) {
    .splide__slide img{
        height: auto;
        width: 100%;
    }
    .splide.is-initialized, .splide.is-rendered{
        padding: 5px;
    }
}