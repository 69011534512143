
.botKaceDiv {
    margin-top: -50px;
    position: relative;
}
.botKaceDiv .splide{
    padding-left: 0;
    padding-right: 0;
}
.splide__arrow svg,
.splide__arrow svg:hover{
    fill: #F7D81A;
}

 .splide__pagination__page:hover,
 .splide__pagination__page.is-active:hover,
 .splide__pagination__page.is-active{
    background: #F7D81A;
}
/* .splide__pagination__page{
    height: 14px;
    width: 14px;
    border-radius: 50%;
} */
.botKace {
    width: 60%;
    min-width: 460px;
    height: 400px;
    margin: auto;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(2px);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}

.botKace img {
    width: auto;
    height: auto;
    margin: 0;
    margin-right: 10px;
}
.botKace span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #fff;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.botKace span:nth-child(5) {
    color: #F7D81A;
}

.botKace p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin: 0 auto;
    margin-bottom: 45px;
    width: 80%;
}

.botKace h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 48px;
    color: #F7D81A;
    margin-top: 45px;
    margin-bottom: 25px;
    width: 100%;
    text-align: center;
}
.botCaseFon{
    position: absolute;
    top: 20px;
    width: 100%;
}
@media screen and (max-width:730px) {
    .botKace{
        min-width: 270px;
        height: auto;
        padding: 30px 0;
        margin-bottom: 30px;
    }
    .botKace h2{
        font-size: 26px;
        line-height: 29px;
        width: 80%;
        margin: auto;
        margin-bottom: 15px;
        text-align: left;
    }
    .botKace img{
        width: 15px;
    }
    .botKace p{
        margin-bottom: 10px;
    }
    .botKace span{
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 5px;
    }
}
@media screen and (max-width:620px) {
    .botKaceDiv{
        margin-top: 0;
    }
}