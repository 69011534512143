.botSoundTestDiv {
    width: 75%;
    height: 35vh;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.soundTestDiv {
    width: 66%;
    min-width: 600px;
    height: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
}

.soundTest {
    width: 45%;
    min-width: 280px;
    height: 45%;
    border: 1px solid #FFFFFF;
    border-radius: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center
}

.soundTest span {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.waveFormDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.waveForm {
    width: 100%;
    max-width: 270px;
    min-width: 250px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.waveForm .styles_reactWaves__1M36F {
    width: calc(100% - 74px);
    margin: 0;
    padding-left: 10px;
    padding-right: 0px;
}

.wavePlay {
    border-radius: 50%;
    background-color: #F4C729;
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    border: 2px solid #F4C729;
}

.wavePlay:hover {
    background-color: #F4C72900;
}

.wavePlay img {
    width: 18px;
    filter: grayscale(100%) brightness(200%) invert(1);
    transition: 0.1s;
}

.wavePlay:hover img {
    filter: grayscale(0%) brightness(100%) invert(0);
    scale: 0.92;
}

.soundInfo {
    width: 27%;
    padding-left: 6%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: left;
    flex-direction: column;
}

.soundInfo p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #F7D81A;
    margin: 0;
}

.soundInfo span {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.soundInfo ul {
    list-style: none;
    margin-left: 0;
    padding: 0;
}

.soundInfo li {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    color: #FFFFFF;
    margin-bottom: 20px;
    align-items: center;
}

.soundInfo li::before {
    content: "-";
    color: #F7D81A;
    font-weight: bold;
    display: inline-block;
    margin-right: 20px;
    transform: scale(1.5);
}
.botSoundTesh6 {
    font-size: 14px;
    color: #fff;
    width: 95%;
    margin: auto;
    text-align: center;
    align-items: center;    
    margin-top: 30px;
}

@media screen and (max-width:1300px) {
    .botSoundTestDiv {
        width: 85%;
    }
}

@media screen and (max-width:1000px) {
    .botSoundTestDiv {
        width: 95%;
    }

    .soundTestDiv {
        width: 66%;
    }

    .soundInfo {
        width: 30%;
        padding-left: 3%;
    }
}

@media screen and (max-width:1000px) {
    .botSoundTestDiv {
        height: auto;
        position: relative;
        justify-content: flex-start;
        align-items: flex-start;
        width: 75%;
    }

    .soundTestDiv {
        justify-content: space-between;
        align-items: flex-start;
        min-width: 0;
        width: 100%;
    }

    .soundTest:nth-child(1),
    .soundTest:nth-child(2) {
        margin-right: 20%;
    }

    .soundTest {
        margin-bottom: 30px;
        width: 45%;
        padding: 20px 0;
    }

    .soundInfo {
        height: 100%;
        width: 42%;
        right: 0;
        padding: 0;
        position: absolute;
        justify-content: flex-start;
        z-index: -1;
    }

    .soundInfo p {
        margin-bottom: 20px;
    }
}

@media screen and (max-width:750px) {
    .botSoundTestDiv {
        width: 95%;
    }
}

@media screen and (max-width:620px) {
    .botSoundTesh6 {
        font-size: 12px;
    }
    .soundInfo {
        position: relative;
        width: 90%;
        max-width: 400px;
    }

    .botSoundTestDiv {
        flex-direction: column;
        align-items: center;
    }

    .soundTest,
    .soundTest:nth-child(1),
    .soundTest:nth-child(2) {
        margin: auto;
        margin-bottom: 30px;
    }

    .soundTestDiv {
        justify-content: center;
    }
}