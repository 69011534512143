.videoWidget {
    position: fixed;
    bottom: 30px;
    left: 20px;
    transition: ransform 0.2s ease-in-out 0s, width 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s, border-color 0.2s ease-in-out 0s, opacity 1s ease-in-out 0s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.videoWidget .video .mp4 {
    transition: 0.3s;
    height: 200px;
    border-radius: 20px;
    border: 2px solid white;
    filter: contrast(0.7);
    z-index: 900;
}

.videoWidget .video:hover .mp4 {
    border: 2px solid #F7D81A;
    height: 210px;
    filter: contrast(1);
}

.videoSecurity {
    position: fixed;
    z-index: 901;
    width: 100%;
    height: 100%;
}

.videoWidget .video {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.videoWidget .video .activeVideo {
    height: calc(200px + 30vh);
    filter: contrast(1);
    border: 2px solid #F7D81A;

}

.videoWidget .video:hover .activeVideo {
    height: calc(200px + 30vh);

}

.closeVideo {
    position: absolute;
    cursor: pointer;
    z-index: 902;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    transition: 0.3s;
}

.closeVideo:before,
.closeVideo:after {
    position: absolute;
    left: 9px;
    top: 1px;
    content: " ";
    height: 18px;
    width: 2px;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 50%) 1px 1px 10px;
    transition: 0.1s;
    opacity: 0;
}

.videoWidget:hover .closeVideo:before,
.videoWidget:hover .closeVideo:after {
    opacity: 1;
    transition: .2s;
}

.closeVideo:before {
    transform: rotate(45deg);
}

.closeVideo:after {
    transform: rotate(-45deg);
}

.closeVideo:hover::after,
.closeVideo:hover::before {
    background: rgb(100, 100, 100);
    height: 20px;
}

.closeVideoActive::after {
    transform: rotate(90deg);
    opacity: 1;
    background: rgb(100, 100, 100);

}

.closeVideoActive::before {
    transform: rotate(90deg);
    background: rgb(100, 100, 100);

    opacity: 1;
}

/*************************/
.videoButtons p {
    color: black;
    background-color: #F7D81A;
    padding: 20px 40px;
    border-radius: 50px;
    cursor: pointer;
    transition: .3s;
    font-family: DaysOne;
}

.videoButtons span {
    color: #adadad;
    font-size: 12px;
    background-color: rgba(58, 58, 58, 0.7);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    cursor: pointer;
    transition: .3s;
    width: 97%;
    padding: 5px 0;
    text-align: center;
}

.videoButtons {
    width: 100%;
    position: absolute;
    height: 100px;
    z-index: 90;
    bottom: 10px;
    display: none;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transition: .3s;
    z-index: 903;
}

.videoButtonsActive {
    display: flex;

}

/* ________________________________________________ */

.buttonWidget {
    position: fixed;
    bottom: 30px;
    right: 20px;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
}

.circleButtonWidget {
    width: 38px;
    height: 38px;
    border: 7px solid #F7D81A;
    border-radius: 50%;
    background-position-y: center;
    background-repeat: no-repeat;
    background-color: white;
    background-image: url(../../../public/static/img//button/logos.png);
    background-size: 220px;
    background-position-y: center;
    background-position-x: 4px;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    cursor: pointer;
}

.msmsm {
    height: 25px;
    margin-top: -3px;
    opacity: 0;
    transition: .3s;
}

.pulsation {
    height: 62px;
    width: 62px;
    background-color: #F7D81A;
    border-radius: 50%;
    position: fixed;
    z-index: -5;
    opacity: 0.25;
    transform: scale(.5);
    transition: .9s;
}

/* ______________________________________ */
.chatWord {
    /* background-color: white; */
    font-size: 14px;
    position: fixed;
    border-radius: 7px;
    right: 80px;
    bottom: 35px;
    padding: 0 10px;
    color: #787878;
    background-image: url(../../../public/static/img/button/sms.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding-right: 20px;
    transition: .15s;
    transform: scale(0);
}

.chatWord p {
    font-family: Gilroy;
}

.widgetManu {
    width: 300px;
    background-color: white;
    position: fixed;
    border-radius: 7px;
    padding-top: 45px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    transform: scale(0);
    bottom: -70px;
    right: -100px;
    transition: .2s;
}

.activeWidgetManu {
    transform: scale(1);
    bottom: 100px;
    right: 22px;
}

.widgetManu p {
    background: #f7d81a;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    color: #141414;
    font-family: Gilroy;
    line-height: 40px;
    margin: 0;
    position: absolute;
    font-size: 16px;
    text-align: center;
    top: 0;
    width: 100%;
}

.widgetManu a {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: white;
    transition: .2s;
    padding: 5px 0;
}

.widgetManu a:hover {
    background-color: #EEEEEE;
}

.widgetManu a div {
    width: 95%;
    height: 40px;
    width: 40px;
    background-color: green;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.widgetManu a div img {
    width: 25px;
    /* height: 25px; */

}

.widgetManu a p {
    color: #141414;
    width: 200px;
    position: relative;
    font-family: Gilroy;
    line-height: 18px;
    background: none;
    margin: 0;
    text-align: left;
    font-size: 14px;
    text-align: left;
    font-weight: bold;
    margin-left: 30px;
}

.widgetManu a span {
    color: #141414;
    ;
    text-align: left;
    position: relative;
    font-family: Gilroy;
    line-height: 14px;
    background: none;
    margin: 0;
    width: 200px;
    font-size: 14px;
    margin-top: -20px;
    margin-left: 70px;
}

.closewidgetManu {
    height: 20px;
    width: 20px;
    position: absolute;
    background-image: url(../../../public/static/img/manuButtonActove.png);
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    /* background-color: white; */
    z-index: 999;
    right: 2px;
    top: 2px;
    cursor: pointer;
    filter: invert(1);
}

.widgetManu .closewidgetManu {
    top: 9px;
    right: 4px;
}

.closewidgetManu:hover {
    filter: invert(0.7);
}

@media only screen and (max-width: 900px) {
    .videoWidget {
        display: none;
    }

}