.chatFon {
    position: absolute;
    top: 110px;
    z-index: -5;
    width: 100%;
    margin: auto;
    user-select: none;
}

.eclipse {
    position: absolute;
    width: 183px;
    height: 604px;
    left: 270px;
    top: -85px;
    transform: rotate(309deg);
    background: #fff16f;
    filter: blur(200px);
    z-index: -1;
}

.chatFon img {
    filter: blur(4px);
    position: absolute;
    transform: scale(1);
    scale: 100%;
}

.blueSMS {
    width: 190px;
    transition: 0.8s;
}

.graySMS {
    width: 150px;
    transition: 0.8s;
    
}
@media only screen and (max-width: 800px) {  
    .eclipse  {
        left: 150px;
        height: 350px;
    }
    .blueSMS {
        width:125px;
    }
    
    .graySMS {
        width: 100px;
    }
    
}