.manVSbotdiv {
    width: 85%;
    height: 750px;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.manVSbotVS {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.manDiv {
    width: 40%;
    height: 100%;
    border: 2px solid #F7D81A;
    border-radius: 32px 0 0 32px;
    border-right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.manDiv::after {
    content: '';
    position: absolute;
    width: 35%;
    height: calc(100% - 0.5px);
    border: 2px solid #F7D81A;
    border-radius: 0 32px 32px 0;
    border-left: 0;
    transform: skew(-10deg) translateX(35%);
    margin-top: -2px;
}

.botDiv {
    width: 40%;
    height: 100%;
    /* border: 2px solid #F7D81A; */
    padding: 2px;
    border-radius: 0 32px 32px 0;
    border-left: 0;
    background: linear-gradient(90deg, rgba(124, 109, 17, 1) 0%, rgba(149, 130, 19, 1) 25%, rgba(157, 137, 17, 1) 35%, rgba(154, 133, 10, 1) 54%, rgb(131 116 24) 68%, rgba(119, 103, 11, 1) 77%, rgba(82, 71, 14, 1) 100%);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.botDiv::after {
    position: absolute;
    content: '';
    width: 35%;
    height: calc(100% - 0.5px);
    /* border: 2px solid #F7D81A; */
    padding: 2px;
    padding-bottom: 3px;
    border-radius: 32px 0 0 32px;
    border-right: 0;
    transform: skew(-10deg) translateX(-22%);
    background: linear-gradient(80deg, rgba(82, 71, 14, 1) 0%, rgb(125 110 17) 25%, rgb(124 109 17) 35%, rgb(128 112 17) 54%, rgba(172, 150, 20, 1) 68%, rgba(153, 134, 20, 1) 77%, rgba(78, 69, 18, 1) 100%);
    z-index: -2;
    margin-top: -2px;

}


.manDiv div,
.botDiv div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    max-width: 50%;
}


.manDiv img,
.botDiv img {
    margin-right: 12px;
}

.manDiv span,
.botDiv span {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    z-index: 2;
}

.manDiv p,
.botDiv p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 34px;
    display: flex;
    align-items: center;
    color: #F7D81A;
    margin: 32px 0;
}

.manDiv p,
.manDiv div {
    margin-left: 32px;
}

.manDivImg {
    width: 19%;
    position: absolute;
    margin-left: 25%;
    bottom: 0;
}

.botDivImg {
    width: 19%;
    position: absolute;
    margin-left: 20%;
    bottom: -2px;
}

.mobileSizes {
    display: none;
}

@media only screen and (max-width: 1680px) {

    .manDiv div:nth-child(-n+5),
    .botDiv div:nth-child(-n+5) {
        max-width: 100%;
    }

    .manDiv::after {
        transform: skew(-10deg) translateX(39%);
    }

    .botDiv {
        border-radius: 32px;
    }
}

@media only screen and (max-width: 1550px) {
    .botDivImg {
        border-bottom-right-radius: 20px;
    }
}

@media only screen and (max-width: 1440px) {
    .manVSbotVS img {
        width: 180px;
    }
}

@media only screen and (max-width: 1340px) {
    .manDivImg {
        border-bottom-right-radius: 65px;
    }
}

@media only screen and (max-width: 1200px) {
    .manVSbotdiv {
        width: 95%;
    }
}

@media only screen and (max-width: 1100px) {
    .botDivImg {
        width: 19%;
        margin-left: 21%;
    }

    .manDivImg {
        width: 18%;
    }

    .manDiv div {
        min-width: 60%;
    }
}

@media only screen and (max-width: 1000px) {
    .manVSbotdiv {
        flex-direction: column;
        height: auto;
        align-items: flex-start;
        justify-content: center;
    }

    .manDiv {
        width: 85%;
        position: relative;
        padding-bottom: 40px;
    }

    .manDiv::after {
        right: 0;
    }

    .manDivImg {
        margin: 0;
        right: 0;
        border-radius: 0;
        width: 27%;
    }

    .botDiv {
        margin-top: 70px;
        width: 85%;
        position: relative;
        margin-left: 15%;
        display: flex;
        align-items: flex-end;
    }

    .botDiv::after {
        left: 0;
        transform: skew(-10deg) translateX(-39%);
        padding: 0;
    }

    .botDivImg {
        margin: 0;
        left: 0;
        border-radius: 0;
        width: 30%;
        bottom: 0;
    }

    .manDiv div:nth-child(-n+5),
    .botDiv div:nth-child(-n+5) {
        max-width: 60%;
    }

    .manDiv p,
    .botDiv p {
        width: 60%;
    }

    .manDiv div,
    .botDiv div {
        width: 60%;
        max-width: 60%;
    }

    .manVSbotVS {
        z-index: 5;
        transform: translateY(-40%);
    }
}

@media only screen and (max-width: 800px) {
    .manDiv {
        width: 70%;
    }

    .botDiv {
        width: 70%;
        margin-left: 30%;
    }

    .manDiv::after {
        transform: skew(-10deg) translateX(70%);
    }

    .botDiv::after {
        transform: skew(-10deg) translateX(-70%);
        background: linear-gradient(80deg, rgba(82, 71, 14, 1) 0%, rgb(125 110 17) 25%, rgb(124 109 17) 35%, rgb(124 109 17) 100%);
    }

    .manDivImg {
        margin-right: 0;
        position: absolute;
        right: -19%;
    }

    .botDivImg {
        position: absolute;
        left: -19%;
    }

    .manDiv div,
    .botDiv div,
    .manDiv div:nth-child(-n+5),
    .botDiv div:nth-child(-n+5) {
        max-width: 80%;
        width: 80%;
    }

    .manVSbotVS {
        padding-top: 100px;

    }
}

@media only screen and (max-width: 800px) {
    .mobileSizes {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        margin: 0 !important;
        z-index: -1;
    }

    .manDivImg {
        position: relative;

    }

    .botDivImg {
        position: relative;
    }

    .manDiv {
        flex-direction: column;
        padding-bottom: 0;
        width: 100%;
        border: 0;
        position: relative;
        align-items: flex-start;

    }

    .botDiv {
        flex-direction: column;
        padding-bottom: 0;
        width: 100%;
        margin-left: 0;
        margin-left: 0%;
        background: none;
        position: relative;
        align-items: flex-end;
    }

    .manDiv p,
    .botDiv p {
        margin-top: 100px;
    }

    .manDiv::after {
        display: none;
    }

    .botDiv::after {
        display: none;
    }

    .manDivImg {

        left: auto;
        bottom: 3px;
        margin: 0;
    }

    .botDivImg {
        margin: 0;
        left: auto;
        bottom: 0;
        right: 20%;
    }

    .manDiv p,
    .botDiv p {
        width: 80%;
    }
}

@media only screen and (max-width: 500px) {

    .manDiv div img,
    .botDiv div img {
        width: 23px;
    }

    .manDiv div,
    .botDiv div {
        margin-bottom: 10px;
    }

    .manVSbotVS {
        padding: 0;
    }

    .manDiv p,
    .botDiv p {
        font-size: 20px;
        margin-top: 35px;
        margin-bottom: 15px;
    }
}