.tasksDiv {
    width: 100%;
    padding-top: 5vh;
}

.tasksSection {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.tasksSection .notfoundText{
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 15px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    margin: 0;
    margin-top: 50px;
}

.task {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.taskMobile {
    display: none;
}

.taskLogo {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.taskLogo img {
    width: 90px;
}

.taskLogo p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    background: linear-gradient(252.81deg, #F9B21A 0.14%, rgba(245, 249, 26, 0.78) 64.57%, rgba(245, 249, 26, 0.5) 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    letter-spacing: 0.1em;
    margin: 0;
    margin-top: 10px;
}

.taskDescription {
    width: 35%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-left: 1px solid #3A3715;
    border-right: 1px solid #3A3715;
    padding-bottom: 30px;
    padding-top: 10px;
}

.taskDescription p {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #FFFFFF;
}

.taskDescription span {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    width: 80%;
}

.taskDescription h6 {

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: rgb(193, 193, 193);
    margin: 0;
    margin-top: 10px;

}

.taskStatus {
    width: 22.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid #3A3715;
    height: 160px;
    padding-bottom: 30px;
    padding-top: 10px;
}


.taskStatus p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 23px;
    background: linear-gradient(252.81deg, #F9B21A 0.14%, rgba(245, 249, 26, 0.78) 64.57%, rgba(245, 249, 26, 0.8) 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    letter-spacing: 0.1em;

}
.taskStatus .start {
    background: linear-gradient(210.19deg, #0DD337 0%, rgba(147, 247, 172, 0.8) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.taskStatus .finish {
    background: linear-gradient(231.86deg, #C41617 35.92%, rgba(222, 58, 57, 0.8) 100.02%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.taskStatistic {
    width: 22.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.taskStatistic button {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #F7D81A;
    background-color: transparent;
    border: 1px solid #F7D81A;
    padding: 11px 35px;
    border-radius: 17px;
    cursor: pointer;
    transition: 0.2s;
    outline: none;
}

.taskStatistic button:hover {
    background-color: #F7D81A;
    color: #000;

}

.tasksSettings {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tasksSettings img {
    height: 18px;
    transform: rotate(180deg);
    margin-left: 20px;
    transition: 0.2s;
}

.tasksSettings p {
    cursor: pointer;
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.taskLogoFilter {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.taskDescriptionFilter {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #3A3715;
    border-right: 1px solid #3A3715;
}

.taskStatusFilter {
    width: 22.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #3A3715;
}

.taskStatisticFilter {
    width: 22.5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1000px) {
    .taskDescription {
        width: 32%;
    }

    .task {
        justify-content: space-evenly;
    }
    .taskDescriptionFilter,
    .taskStatusFilter{
        border: 0;
    }
}

@media only screen and (max-width: 800px) {
    .task {
        display: none;
    }

    .tasksSettings {
        display: none;

    }

    .taskMobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #3f4015ca;
        border-top: 1px solid #3f4015ca;
        margin-bottom: 30px;
        border-radius: 20px;
        width: 370px;
        max-width: 95%;
    }

    .taskMobile .taskLogo {
        width: 30%;
    }

    .taskDescriptionMobile {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding-bottom: 20px;
        padding-top: 0px;
    }

    .taskMobile .taskStatus {
        height: auto;
        border: 0;
        margin: 0;
        padding: 0;
    }

    .taskDescriptionMobile p {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 800;
        font-size: 17px;
        line-height: 20px;
        text-align: left;
        letter-spacing: 0.1em;
        color: #FFFFFF;
    }

    .taskDescriptionMobile span {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
        text-align: left;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        width: 80%;
    }

    .taskDescriptionMobile h6 {

        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1em;
        color: rgb(193, 193, 193);
        margin: 0;
        margin-top: 10px;

    }

    .taskMobile button {
        font-family: 'DaysOne';
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        color: #F7D81A;
        background-color: transparent;
        border: 1px solid #F7D81A;
        border-radius: 17px;
        cursor: pointer;
        transition: 0.2s;
        outline: none;
        font-size: 13px;
        padding: 5px 10px;
        border-radius: 8px;

    }

    .taskMobile .taskStatus span {
        color: #fff;
        width: auto;
        margin-right: 20px;
    }

    .taskMobile .taskStatus {
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {

    .taskDescriptionMobile p,
    .taskDescriptionMobile span {
        width: 95%;
    }
}

@media only screen and (max-width: 500px) {
    .taskMobile {
        align-items: flex-start;
    }
    
    .taskMobile .taskLogo {
        margin-top: 20px;
    }
    
    .taskDescriptionMobile p {
        font-size: 15px;
    }
    
    .taskDescriptionMobile span {
        font-size: 13px;
    }
    
    .taskDescriptionMobile h6 {
        font-size: 12px;
    }
    
    .taskDescriptionMobile .taskStatus p {
        font-size: 16px;
    }
    .taskLogo img{
        width: 80%;
    }
}
@media only screen and (max-width: 400px) {
    .taskMobile .taskLogo{
        width: 20%;
    }
    .taskDescriptionMobile{
        width: 80%;
    }
}