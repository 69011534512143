.homeFormDiv {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 60px;
}

.homeFormText {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.homeFormText p {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.15em;
    background: linear-gradient(9.95deg, #F9B21A -8.8%, rgba(245, 249, 26, 0.78) 64.14%, rgba(245, 249, 26, .5) 104.14%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-align: left;
    margin-bottom: 10px;
}

.homeFormText a img {
    margin-right: 20px;
}

.homeFormText a {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.08em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.homeFormText span {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.15em;
    color: #F4F4F4;
    text-align: left;
    width: 100%;

}

.homeForm {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.supportForm {
    width: 500px;
    height: 600px;
    background: url(../../../public/static/img/formFon.png);
    background-size: 30%;
    border-radius: 40px;
    box-shadow: inset -2px -4px 4px rgba(255, 255, 255, 0.4), inset 2px 4px 4px rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(7px);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.supportForm h2 {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F7D81A;
    margin: 0;
    margin-top: 50px;
    text-align: center;
}

.supportForm .form section {
    width: 100%;
}

.PhoneInputInput:focus {
    outline: none;
    padding-left: 50px;
}

.PhoneInputCountryIconImg {
    color: white;
    display: none;
}

.PhoneInputInternationalIconPhone {
    color: white;
    display: none;
}

.PhoneInputInternationalIconGlobe {
    color: white;
    display: none;

}

.PhoneInputInput::placeholder {
    opacity: 1;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;
    color: #c4c4c4;
    margin-left: 40px;
}

.supportForm p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    max-width: 85%;
}

.supportForm .form .nameInput::placeholder {
    opacity: 1;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;
    color: #c4c4c4;
}

.supportForm .form .nameInput:focus {
    outline: none;
}

.supportForm .form .nameInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.supportForm .form button {
    border: 0;
    width: 175px;
    height: 58px;
    color: #141414;
    background: linear-gradient(-45deg, #F9B21A, rgba(245, 249, 26, 0.78), rgba(245, 249, 26, 0.3));
    cursor: pointer;
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    transition: .3s;
    border-radius: 11px;
    margin-top: 40px;
}

:disabled {
    opacity: 0.8;
    filter: contrast(0);
}

.supportForm .form button:hover {
    color: #F7D81A;
    background: linear-gradient(-45deg, rgba(249, 178, 26, 0), rgba(245, 249, 26, 0.0), rgba(245, 249, 26, 0));
    background-image: url(../../../public/static/img/buttonBorder.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;
}


.supportForm .form .nameInput {
    background: 0;
    border: 0px;
    width: 100%;
    margin: 25px 0;
    border-bottom: 1px solid #F4F4F4;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;
    color: #ffffff;
}

.supportForm .form {
    width: 85%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.supportForm .form .plusNumberInput {
    display: flex;
    width: 100%;
}

.supportForm .form .formdiv span {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;
    color: #ffffff;
    position: absolute;
    margin-left: -81%;
    display: none;
}

.supportForm .form .formdiv .numberInput,
.supportForm .form .nameInput,
.supportForm .form .companyInput,
.supportForm .form .emailInput {
    width: 95%;
    padding-left: 5%;
}

.bellDiv {
    width: 700px;
    background-image: url(../../../public/static/img/bell.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 150px;
    height: 150px;
    margin-bottom: -100px;
    z-index: -1;
}

.messagesDiv {
    width: 650px;
    background-image: url(../../../public/static/img/messages.png);
    background-repeat: no-repeat;
    background-size: 150px;
    height: 150px;
    background-position: left;
    margin-top: -100px;
    z-index: -1;
}

.supportForm .form #polyticChekbox {
    height: 25px;
    width: 25px;
    margin: 10px;

}

.supportForm .form #polyticChekbox:after {
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.supportForm .form #polyticChekbox2 {
    height: 25px;
    width: 25px;
    margin: 10px;

}

.supportForm .form #polyticChekbox2:after {
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.supportForm .form #polyticChekbox3 {
    height: 25px;
    width: 25px;
    margin: 10px;

}

.supportForm .form #polyticChekbox3:after {
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.supportForm .form .formdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 105%;
}

.PhoneInputInput {
    transition: .5s;
    background: 0;
    border: 0px;
    width: 100%;
    margin: 25px 0;
    border-bottom: 1px solid #F4F4F4;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;
    color: #ffffff;
    width: 120%;
    min-width: 100%;
    padding-left: 5%;
    /* margin-left: -10.5%; */
}

section .PhoneInputCountry {
    margin-left: 10px;
    position: absolute;
    height: 75px;
}

section .PhoneInputCountrySelectArrow {
    display: none;

}

.supportForm .form .formdiv a {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    width: 90%;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: underline;
}

.supportForm .form .formdiv label {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    width: 90%;
    margin: 0;
    padding: 0;
    text-align: left;
}

.formResponceFon {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
    top: 0;
    display: none;
}

.formResponce button {
    background: none;
    background-image: url(../../../public/static/img/buttonBorder.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    border: 0;
    color: #f7d81a;
    cursor: pointer;
    font-family: DaysOne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 78px;
    letter-spacing: .1em;
    line-height: 20px;
    width: 175px;

}

.formResponce button {
    height: 58px;
    border-radius: 10px;
}

.formResponce button:hover {
    color: #141414;
    background: linear-gradient(-45deg, #F9B21A, rgba(245, 249, 26, 0.78), rgba(245, 249, 26, 0));
}

.formResponce span {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: #d6d6d6;
}

.formResponce p {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    margin: 5px 0;
}

.formResponceDiv {
    z-index: 6;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
}

.formResponce {
    position: fixed;
    min-width: 28px;
    min-height: 250px;
    background: rgba(20, 20, 20, 0.766);
    border: 2px solid #e9b84a;
    border-radius: 40px;
    box-shadow: inset -2px -4px 4px hsl(0deg 0% 100% / 25%), inset 2px 4px 4px hsl(0deg 0% 100% / 25%);
    -webkit-filter: drop-shadow(0 20px 60px rgba(196, 22, 23, .2));
    filter: drop-shadow(0 20px 60px rgba(196, 22, 23, .2));
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.formResponceFonActive,
.formResponceDivActive {
    display: flex;
}

@media only screen and (max-width: 1668px) {
    .homeFormDiv {
        width: 90%;
    }

    .garantDiv {
        width: 90%;
    }
}

@media only screen and (max-width: 1350px) {
    .supportForm {
        width: 450px;
    }

    .supportForm .form .formdiv label,
    .supportForm .form .formdiv a {
        font-size: 12px;
    }

    .bellDiv {
        width: 650px;
    }

    .messagesDiv {
        width: 60 0px;
    }
}

@media only screen and (max-width: 1100px) {

    /* .PhoneInputInput{
        margin-left: -14%;
    } */
    .homeFormDiv {
        flex-direction: column;
    }

    .homeFormText {
        width: 90%;
    }
}

@media only screen and (max-width: 700px) {

    /* .PhoneInputInput{
        margin-left: -15%;
    } */
    .homeFormText p {
        font-size: 20px;
        text-align: center;
        line-height: 32px;
        width: 100%;
    }

    .supportForm .form .formdiv label,
    .supportForm .form .formdiv a {
        font-size: 11px;
    }

    .PhoneInputInput,
    .PhoneInputInput::placeholder,
    .supportForm .form .nameInput::placeholder,
    .supportForm .form .nameInput {
        font-size: 16px;
    }

    .supportForm .form button {
        width: 137px;
        height: 46px;
        font-size: 13px;
    }

    .homeForm {
        width: 100%;
    }

    .supportForm {
        width: 90%;
        border-radius: 20px;
    }

    .bellDiv {
        width: 120%;
        background-size: 100px;
        margin-bottom: -80px;
    }

    .messagesDiv {
        width: 110%;
        background-size: 100px;

    }

    .supportForm h2 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 450px) {

    /* .PhoneInputInput{
        margin-left: -20%;
    } */
    .homeFormText p {
        margin-top: 30px;
    }

    .homeFormText span {
        font-size: 14px;
        text-align: left;
    }

    .supportForm p {
        font-size: 14px;
    }

    .supportForm {
        width: 100%;
        margin-top: 50px;
    }

    .bellDiv {
        width: 120%;
        height: 200px;
        background-size: 100px;
        margin-bottom: -80px;
        display: none;
    }

    .messagesDiv {
        width: 110%;
        height: 212px;
        background-size: 100px;
        margin-bottom: -80px;
        display: none;
    }
}