@font-face {
    font-family: DaysOne;
    src: url(../../css/fonts/DaysOne-Regular.ttf);
}

@font-face {
    font-family: Gilroy;
    src: url(../../css/fonts/Gilroy-Regular.ttf)
}

.howWeWork {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F7D81A;
    margin: 0;
    margin-bottom: 60px;
    margin-top: 200px;
}

.workSection {
    width: 80%;
    display: flex;
    justify-content: space-around;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

}

.workImage {
    width: 30%;
}

.workImage img {
    width: 100%;
}

.workText {
    width: 50%;
    padding-top: 50px;
    display: flex;
    justify-content: flex-startc;
    align-items: flex-start;
    flex-direction: column;
}

.workText h5 {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F4F4F4;
    margin: 0;

}

.workText p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    text-align: left;
    margin: 0;
}

.workText span b{
    color: #F7D81A;
}
.workText span {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    text-align: left;
    margin: 0;
    margin-top: 30px;
}

.workLine {
    width: 100%;
    height: 1px;
    background-color: #F7D81A;
    margin-top: 10px;
    margin-bottom: 20px;
}
@media only screen and (max-width: 1668px) {
    .workSection{
        width: 90%;
        margin-top: 20px;
    }
    .workImage{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .workText{
        padding-top: 10px;
    }
    
}
@media only screen and (max-width: 700px) {    
    .workImage img{
        position: absolute;
        opacity: 0.4;
        width: 250px;
        right: 5px;
        margin-top: 110px;
    }
    .workText{
        width: 90%;
    }
    .workImage{
        width: 0;
    }
    .howWeWork{
        font-size: 32px;
        margin-bottom: 0;
        margin-top: 40px;
    }
    .workText h5{
        font-size: 23px;
    }
    .workText span{
        text-shadow: 1px 0px 1px rgb(0 0 0);
    }
    .workImage {
        opacity: 0.5;
    }
}
@media only screen and (max-width: 450px) {    
    .workText span {
        font-size: 12px;
    }
    .howWeWork{
        font-size: 23px;
        margin-top: 100px;
        margin-bottom: -40px;
    }
    .workText h5{
        font-size: 19px;
    }
    .workSection {
        margin-top: 69px;
    }
}