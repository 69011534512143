.cabinetSection {
    position: absolute;
    right: 0px;
    top: 78px;
    min-height: calc(100vh - 81px);
    width: calc(100% - 330px);
    transition: .3s;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}
.cabinetSectionHidden {
    width: calc(100% - 60px);
}

@media only screen and (max-width:1300px) {
    .cabinetSection {
        width: 100%;
    }

    .cabinetSectionActive {
        width: 100%;
    }
}

@media only screen and (max-width:1000px) {
    .cabinetSectionActive {
        width: 100%;
    }
}