.mailingDiv {
    width: 100%;
    min-height: 1500px;
    background-image: url(../../../public/static/img/fonPattern.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex;
    padding-top: 100px;
    width: 100%;
    text-align: center;
}

.legs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex;
    padding-top: 20px;
    padding-bottom: 100px;
    width: 100%;
    text-align: center;

}

.concurentUseHeader {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F7D81A;
    margin: 0;
    margin-top: 140px;
    text-align: center;
}

.concurentUseText {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    max-width: 80%;
    margin: 20px auto;
}

.garantDiv {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    margin-top: 150px;
}

.garantDiv div {
    width: 45%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.garantDiv div .workLine {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
}

.garantDiv div h5 {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F4F4F4;
    text-align: left;
    margin: 0;
}

.garantDiv div p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    text-align: left;
    text-align: left;
    margin: 0;
    margin-bottom: 30px;
}

.garantDiv div span {
    font-family: Gilroy;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    text-align: left;
}

.trustDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.photoTrust img {
    width: 80%;
}

.weAreTrust {
    margin-top: 80px;
    max-width: 1000px;
    margin: auto;
}

.weAreTrust p {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F7D81A;
    margin-top: 120px;
    text-align: center;

}

.photoTrust {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.faqHeader {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F4F4F4;
    text-align: center;
    margin: 0;
    width: 100%;
    margin-top: 120px;
    margin-bottom: 30px;
}

.homeFAQ {
    width: 80%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: auto;
}

.homeFAQ li {
    color: #F4F4F4;
    font-family: DaysOne;
    text-align: left;
    cursor: pointer;
}

.homeFAQ p {
    color: #F4F4F4;
    font-family: Gilroy;
    text-align: left;
    width: 100%;
    font-size: 17px;
}

.homeFAQ {
    position: relative;
}

.FAQQuestion::marker {
    color: transparent;
}

.FAQQuestion:hover {
    color: rgb(189, 189, 189);
}
.FAQQuestion::before {
    content: '<';
    position: absolute;
    font-size: 35px;
    width: 35px;
    height: 35px;
    transform: rotate(180deg) translateY(-5px);
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;    
}

.FAQQuestionActive::before {
    transform: rotate(270deg);
}

@media only screen and (max-width: 1668px) {
    .garantDiv {
        width: 90%;
    }

    .legs {
        background-size: 39%;
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 1100px) {
    .garantDiv {
        flex-direction: column;
    }

    .garantDiv div {
        width: 90%;
    }

    .garantDiv div span {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 700px) {
    .concurentUseHeader {
        font-size: 32px;
        margin-top: 70px;
        ;
    }

    .weAreTrust p {
        font-size: 30px;
    }

    .trustDiv {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 450px) {

    .faqHeader {
        font-size: 23px;
    }

    .homeFAQ li {
        font-size: 15px;
    }

    .homeFAQ p {
        font-size: 14px;
    }

    .homeFAQ {
        width: 92%;
        margin: 0 auto;
        box-sizing: border-box;
    }
    .FAQQuestion::before {
        font-size: 25px;
        width: 25px;
        height: 25px;
        transform: rotate(180deg) translateY(-10px);
        left: 10px;
    }
    .FAQQuestionActive::before {
        transform: rotate(270deg);

    }
    .concurentUseHeader {
        font-size: 20px;
        margin-top: 110px;
    }

    .weAreTrust p {
        font-size: 30px;
    }

    .trustDiv {
        flex-wrap: wrap;
    }

    .concurentUseText {
        font-size: 12px;
        text-align: left;
    }

    .garantDiv {
        margin-top: 100px;
    }

    .garantDiv div h5 {
        font-size: 23px;
        text-align: center;
        margin-top: 50px;
    }

    .garantDiv div span {
        font-size: 14px;
        align-items: center;
    }

    .weAreTrust {
        margin-top: 40px;
    }

    .photoTrust {
        width: 45%;
        height: auto;
    }
}