.formResponceFonActive {
    display: flex;
}

.createTelegramTaskDiv {
    position: fixed;
    max-width: 750px;
    width: 95%;
    height: 50vh;
    top: 25vh;
    background-color: #141414;
    background-size: 30%;
    border-radius: 20px;
    box-shadow: inset -2px -4px 4px rgb(255 255 255 / 40%), inset 2px 4px 4px rgb(255 255 255 / 40%);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px 6px;
    padding-bottom: 15px;
}

.tgMilingTasks {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 20px 0;
}

.tgMilingTasks h4 {
    font-size: 13px;
    color: #fff;
    font-family: DaysOne;
    font-weight: 200;   
}
.tgMilingTasks a {
    width: calc(100% - 23px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tgMilingTasks .task img {
    width: 15px;
    cursor: pointer;
}

.tgMilingTasks .task {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid #f9b21a35;
}

.tgMilingTasks a:nth-child(1) .task {
    border-top: 0px;
}



.tgMilingTasks .task .start {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;
    background: linear-gradient(252.81deg, #F9B21A 0.14%, rgba(245, 249, 26, 0.78) 64.57%, rgba(245, 249, 26, 0.8) 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.tgMilingTasks .task .setting {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;
    background: linear-gradient(210.19deg, #0DD337 0%, rgba(147, 247, 172, 0.8) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.tgMilingTasks .task .finish {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;

    background: linear-gradient(231.86deg, #C41617 35.92%, rgba(222, 58, 57, 0.8) 100.02%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.tgMilingTasks .task p {
    color: #fff;
    font-family: DaysOne;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    margin: 0;
}

.tgMilingTasks .task span {
    color: gray;
    font-family: DaysOne;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    margin: 0;
}

.tgMilingTasks .task h3 {
    color: #fff;
    font-family: DaysOne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    margin: 0;
    width: 317px;
}

.tgMilingTasks .task h4 {
    color: #fff;
    font-family: DaysOne;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    margin: 0;
}

.createTelegramTaskDiv p {
    color: #fff;
    font-family: DaysOne;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
}

.createTelegramTaskDiv input {
    background: none;
    border: 0;
    color: #fff;
    outline: none;
    width: 100%;
    border: 1px solid #f9b21a71;
    border-right: 0px;
    width: 300px;
    max-width: calc(100% - 104px);
    padding: 10px;
    border-radius: 7px 0 0 7px;
    
}
.createTelegramTaskDiv button{
    height: 39px;
    background: #F7D81A;
    /* color: White; */
    border: 1px solid #f9b21a71;
    /* border-left: 0; */
    border-radius: 0 7px 7px 0;
    cursor: pointer;
    padding: 0 20px;
}
.tgMilingTasksInput{
    display: flex;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 18px;
}
.tgMilingTasksInputDisabled{
    opacity: 0.8;
    filter: contrast(0.33);
}
@media only screen and (max-width: 720px) {
    .tgMilingTasks .task{
        flex-direction: row;
        align-items: center;
        position: relative;
    }
    .tgMilingTasks a {
        flex-direction: column;
        align-items: flex-start;
    }
}