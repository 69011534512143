.crmClientCreatorDiv {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    align-items: center;

}

.crmClientCreatorFon {
    width: 100%;
    height: 100%;
    background-color: #14141454;
    backdrop-filter: blur(2px);
    cursor: pointer;
}

.crmClientCreator {
    /* width: 50%; */
    width: 400px;
    max-width: 100%;
    min-height: 50vh;
    border-radius: 20px;
    position: fixed;
    background-color: rgba(46, 46, 46, 0.95);
    backdrop-filter: blur(2px);
    /* box-shadow: 4px 4px 8px 0px rgba(46, 46, 46, 0.95); */
    box-sizing: border-box;
    padding: 20px;
}

.crmClientCreator h4 {
    color: white;
    margin: 0;
    font-size: 20px;
    margin-bottom: 15px;
}

.crmClientCreatorInputs {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 20px;
}

.crmClientCreatorInputItem:focus {
    outline: none;
}

.crmClientCreatorInputItem::placeholder {
    color: rgb(205, 205, 205);
}

.crmClientCreatorInputItem:placeholder-shown {
    color: white;
}

.crmClientCreatorInputTextarea{
    max-width: 100%;
    min-width: 100%;
    min-height: 60px;
}
.crmClientCreatorInputItem::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
.crmClientCreatorInputTextarea,
.crmClientCreatorInputItem {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    font-size: 18px;
    margin-bottom: 15px;
}

.crmClientCreatorSave {
    width: 60%;
    height: 30px;
    background-color: green;
    color: white;
    font-size: 20px;
    border: 0;
    border-radius: 5px;
    display: block;
    margin: auto;
    margin-top: 5px;
    cursor: pointer;
}

.crmClientCreatorRelation span {
    color: #cbcbcb;
    font-size: 18px;
}

.crmClientCreatorRelation select:focus {
    outline: none;
}
.crmClientCreatorRelation select option{
    background-color: rgba(46, 46, 46, 0.90);
    border-radius: 1px;
    padding: 5px;    
}
.crmClientCreatorRelation select {
    width: 50%;
    display: flex;
    background-color: transparent;
    color: white;
    border: 0;
    font-size: 18px;    
}

.crmClientCreatorRelation {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px 10px;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.clientCtreatorVipInput span {
    width: 135px;
    color: #fff;
    font-size: 18px;
    margin-left: 10px;
}

.clientCtreatorVipInput {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.clientCtreatorVipInput input {
    margin: 0;
    padding: 0;
}



/* chekbox style */
.checkbox-wrapper-12 {
    position: relative;
    margin-left: 50px;
}

.checkbox-wrapper-12>svg {
    position: absolute;
    top: -130%;
    left: -170%;
    width: 110px;
    pointer-events: none;
}

.checkbox-wrapper-12 * {
    box-sizing: border-box;
}

.checkbox-wrapper-12 input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    margin: 0;
}

.checkbox-wrapper-12 input[type="checkbox"]:focus {
    outline: 0;
}

.checkbox-wrapper-12 .cbx {
    width: 24px;
    height: 24px;
    top: calc(100px - 12px);
    left: calc(100px - 12px);
}

.checkbox-wrapper-12 .cbx input {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border: 2px solid #bfbfc0;
    border-radius: 50%;
}

.checkbox-wrapper-12 .cbx label {
    width: 24px;
    height: 24px;
    background: none;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transform: trasnlate3d(0, 0, 0);
    pointer-events: none;
}

.checkbox-wrapper-12 .cbx svg {
    position: absolute;
    top: 5px;
    left: 4px;
    z-index: 1;
    pointer-events: none;
}

.checkbox-wrapper-12 .cbx svg path {
    stroke: #fff;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 19;
    stroke-dashoffset: 19;
    transition: stroke-dashoffset 0.3s ease;
    transition-delay: 0.2s;
}

.checkbox-wrapper-12 .cbx input:checked+label {
    animation: splash-12 0.6s ease forwards;
}

.checkbox-wrapper-12 .cbx input:checked+label+svg path {
    stroke-dashoffset: 0;
}

@-moz-keyframes splash-12 {
    40% {
        background: #f7d81a;
        box-shadow: 0 -18px 0 -8px #f7d81a, 16px -8px 0 -8px #f7d81a, 16px 8px 0 -8px #f7d81a, 0 18px 0 -8px #f7d81a, -16px 8px 0 -8px #f7d81a, -16px -8px 0 -8px #f7d81a;
    }

    100% {
        background: #f7d81a;
        box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
}

@-webkit-keyframes splash-12 {
    40% {
        background: #f7d81a;
        box-shadow: 0 -18px 0 -8px #f7d81a, 16px -8px 0 -8px #f7d81a, 16px 8px 0 -8px #f7d81a, 0 18px 0 -8px #f7d81a, -16px 8px 0 -8px #f7d81a, -16px -8px 0 -8px #f7d81a;
    }

    100% {
        background: #f7d81a;
        box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
}

@-o-keyframes splash-12 {
    40% {
        background: #f7d81a;
        box-shadow: 0 -18px 0 -8px #f7d81a, 16px -8px 0 -8px #f7d81a, 16px 8px 0 -8px #f7d81a, 0 18px 0 -8px #f7d81a, -16px 8px 0 -8px #f7d81a, -16px -8px 0 -8px #f7d81a;
    }

    100% {
        background: #f7d81a;
        box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
}

@keyframes splash-12 {
    40% {
        background: #f7d81a;
        box-shadow: 0 -18px 0 -8px #f7d81a, 16px -8px 0 -8px #f7d81a, 16px 8px 0 -8px #f7d81a, 0 18px 0 -8px #f7d81a, -16px 8px 0 -8px #f7d81a, -16px -8px 0 -8px #f7d81a;
    }

    100% {
        background: #f7d81a;
        box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
}

/* chekbox style end */

.react-date-picker{
    width: 100%;
}
.crmClientCreatorDiv .react-date-picker__wrapper{
    border: 0;
}
.crmClientCreatorDiv .react-date-picker__inputGroup__input:focus,
.crmClientCreatorDiv .react-date-picker__inputGroup__input:invalid{
    outline: none;
    background-color: transparent;
}
.crmClientCreatorDiv .react-date-picker__calendar-button,
.crmClientCreatorDiv .react-date-picker__clear-button{
    filter: invert(1);
}
.crmClientCreatorDiv .react-date-picker__button:enabled:hover .react-date-picker__button__icon, .react-date-picker__button:enabled:focus .react-date-picker__button__icon{
    stroke: gray;
}
.crmClientCreator img{
    position: absolute;
    top: 15px;
    right: 15px;
    filter: invert(1);
    height: 20px;
    cursor: pointer;
}