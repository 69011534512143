.paymentPage{
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.paymentPage p{
    font-size: 25px;
    color: #fff;
    cursor: pointer;
}