.mailingShadow {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    width: 400px;
    gap: 30px;
    opacity: .4;
    z-index: -1;
}
.mailingShadow {
    filter: blur(100px);
}

.mailingShadow1 {
    height: 130px;
    width: 300px;
    background: #761717;

}
.mailingShadow2 {
    height: 300px;
    width: 150px;
    background: #7CD3EF;

}
.mailingShadow3 {
    height: 300px;
    width: 150px;
    background: #F7D81A;

}