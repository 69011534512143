.selfMailingPageHeader {
    width: 80%;
    height: 500px;
    margin: auto;
    margin-top: 130px;
    display: flex;

}

.selfMailingPageHeaderInfo {
    width: 70%;
    min-width: 860px;
    z-index: 3;
}

.selfMailingPageHeader h3 {
    width: 70%;
    color: #FFF;
    font-family: Gilroy;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.5px;
    margin: 32px 0;
}

.selfMailingPageHeader h4 {
    color: #ffffff;
    font-size: 41px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.selfMailingPageHeaderImage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30%;
}

.selfMailingPageHeaderImageSHadow {
    width: 300px;
    height: 200px;
    background-color: aquamarine;
    position: absolute;
    transform: translateX(-20%);
    filter: blur(170px);
}

.selfMailingPageHeader img {
    position: absolute;
    margin-top: 60px;
    right: 10%;
}

.selfMailingPageHeaderInfo span {
    color: #FFF;
    font-family: Gilroy;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 12px;
}

.selfMailingPageHeaderInfo span::before {
    content: ' ';
    height: 9px;
    width: 9px;
    position: absolute;
    background-color: #F7D81A;
    border-radius: 3px;
    margin-left: -20px;
}


.ourUserExperianceDiv {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 150px;
    position: relative;
}

.ourUserExperianceDiv button {
    width: 256px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #F7D81A;
    background-color: transparent;
    color: #F7D81A;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1.6px;
    margin-top: 30px;
    cursor: pointer;
    transition: 0.3s;
    max-width: 100%;
}

.ourUserExperianceDiv button:hover {
    background-color: #F7D81A;
    color: black;
}

.ourUserExperianceDiv h5 {
    color: #F7D81A;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 5.5px;
}

.ourUserExperianceImage {
    margin-top: 20px;
    width: 940px;
    height: 800px;
    background-image: url(../../../public/static/img/soft/ourUserExperianceDiv.png);
    background-size: 100%;
    background-repeat: no-repeat;
}

.ourSoftBenefice {
    width: 80%;
    display: flex;
    margin: auto;
    align-items: center;
    margin-top: 160px;
}

.ourSoftBeneficeInfo {
    width: 50%;
    flex-direction: column;
    display: flex;
    gap: 20px;
}

.ourSoftBenefice p {
    width: 100%;
    color: #F7D81A;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.ourSoftBenefice span {
    color: #F4F4F4;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    position: relative;
    padding-left: 40px;
    display: flex;
    align-items: center;
    width: 70%;
}

.ourSoftBenefice span::before {
    content: ' ';
    width: 30px;
    height: 30px;
    position: absolute;
    background-image: url(../../../public/static/img/soft/li_img.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    left: 0;
}

.ourSoftBeneficeImage {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ourSoftBeneficeImage img {
    width: 100%;
}

.softWoldClub p {
    color: rgba(255, 255, 255, 0.80);
    font-family: Gilroy;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    max-width: 600px;
}

.softWoldClub h5 {
    color: #F7D81A;
    font-size: 40px;
    max-width: 990px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 3.6px;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 30px;
    margin-top: 80px;
}

.softWoldClub button img {
    position: absolute;
    right: -150px;
    top: -55px;
}

.softWoldClubimages {
    position: absolute;
    left: 100px;
    height: 100%;
    display: flex;
    align-items: center;
}

.softWoldClub button {
    position: relative;
    width: 256px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #F7D81A;
    background-color: transparent;
    color: #F7D81A;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1.6px;
    margin-top: 79px;
    cursor: pointer;
    transition: 0.3s;
    max-width: 100%;
}

.softWoldClub button:hover {
    background-color: #F7D81A;
    color: black;
}

.softWoldClub {
    position: relative;
    margin-top: 150px;
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    background-image: url(../../../public/static/img/soft/world_map.png);
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: 1100px;

}

.softWoldClubText {
    width: 50%;
}

.softTelegram div {
    display: flex;
    flex-direction: column;
    gap: 35px;
    /* width: 50%; */
    height: 50vw;
    margin-left: 15vw;
    z-index: 1;
}

.softTelegram div p {
    color: #F7D81A;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.softTelegram div img {
    max-width: 51vw;
}

.softTelegram div span {
    color: rgba(255, 255, 255, 0.80);
    font-family: Gilroy;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 50vw;
}

.softTelegram .telegrameLogo1 {
    position: absolute;
    right: 13vw;
    top: 30px;
    width: 25vw;
}

.softTelegram {
    position: relative;
    margin-top: 80px;
    width: 100%;
    height: 60vw;
    background-image: url(../../../public/static/img/soft/tg_fon.png);
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: 100%;
    display: flex;
    align-items: center;
}

.softTraining {
    width: 100%;
    box-shadow: 0px 10px 30px 0px rgba(247, 216, 26, 0.10), 0px -7px 40px 0px rgba(247, 216, 26, 0.10);
    padding-top: 65px;
    position: relative;
    min-height: 500px;
}

.softTrainingDiv {
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-left: 15vw;

}

.softTraining img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
}

.softTraining p {
    color: #F7D81A;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.softTraining span {
    color: rgba(255, 255, 255, 0.80);
    font-family: Gilroy;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 50vw;
}

.softTrainingLi h6 {
    margin: 0;
    color: #FFF;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: lighter;
    line-height: normal;
    display: flex;
    align-items: center;
    margin-left: 12px;
}

.softTrainingLi h6::before {
    content: ' ';
    width: 5px;
    height: 5px;
    position: absolute;
    background: #F7D81A;
    border-radius: 50%;
    background-size: 100%;
    background-repeat: no-repeat;
    left: 0;
}

.softTrainingLi .softTrainingLiImg {
    position: absolute;
    width: 50px;
    left: -80px;
    top: 0;
    transform: rotateY(180deg);
}

.softTrainingLi {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    margin-left: 100px;
}

.soft3in1Div {
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.soft3in1Div img:last-child {
    display: none;
}

.soft3in1Div img:nth-child(2) {
    display: none;
}

.soft3in1Div img {
    width: 90%;
    max-width: 82vw;
}

.softPrefer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 100px;
    position: relative;
}

.softPreferInfo div span::before {
    content: ' ';
    width: 30px;
    height: 30px;
    position: absolute;
    background-image: url(../../../public/static/img/soft/li_img.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    left: 0;
}

.softPreferInfo div span {
    color: #FFF;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    padding-left: 40px;
    max-width: 250px;
}

.softPreferInfo div {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.softPreferInfo {
    width: 60%;
    box-shadow: 0px 20px 70px 0px rgba(42, 171, 238, 0.10), 0px -7px 60px 0px rgba(42, 171, 238, 0.10);
    display: flex;
    justify-content: space-evenly;
    padding: 30px;
    border-radius: 20px;
    position: relative;
    backdrop-filter: blur(3.5px);
    background: rgba(64, 63, 63, 0.10);
}

.softPreferInfoDiv {
    width: 80%;
    display: flex;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.tg_3d_icon {
    height: 350px;
}

.softPrefer h5 span {
    color: white;
}

.softPrefer h5 {
    max-width: 600px;
    color: #F7D81A;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 35%;
    margin-bottom: 80px;
    position: relative;
}

.softPrefer svg {
    position: absolute;
    left: 250px;
    top: 80px;
    z-index: 2;
}

.softsSamalyotiks {
    position: absolute;
    z-index: -1;
    right: -70px;
    bottom: -50px;
    width: 200px;
}

.softPrices {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    margin-top: 120px;
}

.softParashut {
    position: absolute;
    right: 100px;
    top: 305px;
    width: 176px;
}

.softBigText {
    margin: auto;
    width: 50%;
    max-width: 800px;
}

.softPricesLine img {
    width: 100%;
}

.softPricesLine {
    width: 100%;
}

.softPricesInfo {
    width: 75%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    padding-top: 120px;
}

.softPricesInfo button img {
    position: absolute;
    top: -180px;
}

.softPricesInfo button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 56px;
    padding: 0 25px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #F7D81A;
    background-color: transparent;
    color: #F7D81A;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1.6px;
    margin-top: 79px;
    cursor: pointer;
    transition: 0.3s;
    max-width: 100%;
}

.softPricesInfo button:hover {
    background-color: #F7D81A;
    color: black;
}

.softWhatsapp {
    width: 100%;
    height: 44vw;
    background-image: url(../../../public/static/img/soft/whatsapp.png);
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    margin-top: 100px;
}

.softWhatsappInfo {
    width: 50%;
    margin-left: 15vw;
    max-width: 500px;
    padding-top: 50px;
}

.softWhatsappInfo h5 {
    color: #F7D81A;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    margin-bottom: 20px;
}

.softWhatsappInfo p {
    color: white;
    font-family: Gilroy;
    font-size: 18px;
    line-height: 18px;
    margin: 0;
}

.selfMailingPageHeaderImageMobile {
    display: none;
    position: relative;
}

@media only screen and (max-width: 1668px) {

    .ourSoftBenefice,
    .ourUserExperianceDiv,
    .selfMailingPageHeader {
        width: 90%;
    }
}

@media only screen and (max-width: 1400px) {

    .selfMailingPageHeader img {
        margin-top: 180px;
    }

    .ourSoftBenefice span {
        width: 100%;
    }
}

@media only screen and (max-width: 1200px) {
    .selfMailingPageHeader img {
        margin-top: 50px;
        width: 500px;
    }

    .selfMailingPageHeaderImageSHadow {
        scale: 0.6;
        transform: translateX(-75%);
    }

    .ourUserExperianceImage {
        max-width: 100%;
        max-height: 78vw;
    }

    .softWoldClub {
        background-size: 900px;
        height: 515px;
    }

    .tg_3d_icon {
        height: 280px;
    }

    .softPrefer svg {
        left: 170px;
    }

    .softPricesInfo {
        max-width: 100%;
        width: 85%;
    }

    .softBigText {
        width: 40%;

    }

    .softParashut {
        right: 174px;
        top: 166px;
        width: 146px;
    }

    .softPreferInfoDiv {
        width: 95%;
    }
}

@media only screen and (max-width: 1200px) {
    .ourSoftBeneficeImage {
        position: absolute;
        z-index: -1;
        right: 0;
        opacity: 0.5;
        width: 60%;
    }

    .ourSoftBeneficeInfo {
        width: 70%;

    }

    .softWoldClubimages {
        left: 30px;
    }
}

@media only screen and (max-width: 1000px) {
    .soft3in1Div img {
        max-width: 100%;
        width: 100%;
    }

    .selfMailingPage button {
        height: 40px;
        font-size: 14px;
    }

    .softPricesInfo button {
        margin-top: 30px;
    }

    .softPricesInfo button img {
        display: none;
    }

    .softParashut {
        z-index: -1;
        opacity: 0.8;
        width: 100px;
    }

    .softPreferInfo div span {
        font-size: 14px;
    }

    .softPrefer h5 {
        font-size: 28px;
        max-width: 460px;
        margin-right: 42%;
    }

    .tg_3d_icon {
        height: 230px;
    }

    .softTelegram div span {
        font-size: 14px;
    }

    .softTelegram {
        align-items: flex-start;
    }

    .selfMailingPageHeader {
        position: relative;
        height: 370px;
    }

    .selfMailingPageHeader img {
        width: 340px;
        right: 0px;
        bottom: 0;
    }

    .ourUserExperianceImage {
        max-width: 90%;
        max-height: 69vw;
    }

    .selfMailingPageHeader h4 {
        font-size: 30px;
    }

    .selfMailingPageHeader h3 {
        font-size: 20px;
        margin: 20px 0;
    }

    .selfMailingPageHeaderInfo span {
        font-size: 16px;
    }

    .ourSoftBenefice,
    .softWoldClub,
    .ourUserExperianceDiv {
        margin-top: 90px;
    }

    .ourUserExperianceDiv h5 {
        font-size: 30px;
    }

    .ourSoftBenefice p {
        font-size: 26px;
        max-width: 400px;
    }

    .ourSoftBenefice span {
        font-size: 14px;
    }

    .ourSoftBeneficeInfo {
        gap: 12px;
    }

    .ourSoftBeneficeImage {
        width: 50%;
    }

    .ourSoftBenefice span::before {
        height: 20px;
        width: 20px;
    }

    .softWoldClub {
        justify-content: flex-start;
    }

    .softWoldClub h5 {
        font-size: 30px;
        margin-top: 40px;
        margin-bottom: 15px;
    }

    .softWoldClub p {
        font-size: 15px;
    }

    .selfMailingPage button {
        margin-top: 30px;
    }

    .softWoldClub button img {
        display: none;
    }

    .softWoldClub {
        background-size: 650px;
        height: 350px;
    }

    .softWoldClubimages img {
        width: 100px;
    }

    .softWoldClubText {
        margin-left: 15%;
    }

    .softTelegram div {
        gap: 15px;
    }

    .softTraining p {
        font-size: 26px;
    }

    .softTraining span {
        font-size: 14px;
    }

    .softTrainingLi h6 {
        font-size: 14px;
    }

    .softTrainingDiv {
        gap: 15px;
    }

    .softTraining {
        min-height: 300px;
    }

    .softTraining img {
        width: 40%;
    }

    .softPrefer,
    .soft3in1Div {
        margin-top: 60px;
    }

    .softPrefer h5 {
        font-size: 26px;
        margin-right: auto;
        margin-left: 30px;
        margin-bottom: 20px;
    }

    .tg_3d_icon {
        position: absolute;
        z-index: -1;
        left: 20px;
        opacity: 0.3;
    }

    .softPrefer svg {
        display: none;
    }

    .softPreferInfo {
        width: 90%;
    }

    .softParashut {
        right: 50px;
        top: 100px;
    }

    .selfMailingPage button {
        margin-top: 0;
        width: 320px;
        max-width: 100%;
    }

    .softPricesInfo {
        flex-direction: column;
        align-items: center;
        padding-top: 30px;
        margin-bottom: 20px;
    }

    .softWhatsapp {
        margin-top: 60px;
    }

    .softWhatsappInfo h5 {
        font-size: 26px;
        margin-bottom: 10px;
    }

    .softWhatsappInfo p {
        font-size: 14px;
    }

    .selfMailingPage .homeFormText p {
        font-size: 26px;
        line-height: 28px;
    }

    .homeFormText span {
        font-size: 14px;
    }

    .soft3in1Div img:last-child {
        display: none;
    }

    .soft3in1Div img:nth-child(2) {
        display: flex;
        width: 95%;
    }

    .soft3in1Div img:first-child {
        display: none;
    }
}

@media only screen and (max-width: 700px) {

    .softTelegram div {
        width: 70%;
    }

    .softTelegram div span {
        max-width: 100%;
    }

    .softTelegram .telegrameLogo1 {
        right: 0;
        top: 0;
        width: 20vw;
        opacity: 0.8;
    }

    .softTrainingDiv,
    .softWhatsappInfo,
    .softTelegram div {
        margin-left: 10vw;
    }

    .selfMailingPageHeader img {
        display: none;
    }

    .selfMailingPageHeader .selfMailingPageHeaderImageMobile {
        display: flex;
        width: 200px;
        position: relative;
        margin: 0;
        margin-top: 30px;
    }

    .selfMailingPageHeader {
        margin-top: 50px;
        box-sizing: border-box;
        height: auto;
    }

    .selfMailingPageHeader h4 {
        transform: translateX(1000%);
        height: 200px;
        max-width: 450px;
    }

    .selfMailingPageHeader h4::before {
        transform: translateX(-1000%);
        display: flex;
        content: "BAKSBO-SOFT это самостоятельная рассылка без ограничений и скачивания!";

    }

    .selfMailingPageHeader h4::after {
        transform: translateX(-1000%) translateY(-1000%);
        color: #F7D81A;
        display: flex;
        content: "BAKSBO-SOFT";

    }

    .selfMailingPageHeader h3 br {
        display: none;
    }

    .selfMailingPageHeaderInfo span {
        max-width: 90vw;
    }

    .selfMailingPageHeader h3 {
        font-size: 15px;
        max-width: 90vw;
    }

    .ourUserExperianceDiv {
        margin-top: 50px;
    }

    .ourUserExperianceDiv h5 {
        font-size: 22px;
        margin: auto;
        text-align: center;
    }

    .ourUserExperianceImage {
        max-height: 80vw;

    }

    .ourSoftBenefice {
        flex-direction: column-reverse;

    }

    .ourSoftBenefice p {
        font-size: 22px;
        max-width: 100%;
    }

    .ourSoftBeneficeInfo {
        width: 100%;
    }

    .ourSoftBenefice span {
        box-sizing: border-box;
    }

    .ourSoftBeneficeImage {
        position: relative;
    }

    .ourSoftBeneficeImage {
        width: 100%;
        opacity: 1;
        z-index: 1;
    }

    .softWoldClubText {
        margin-left: 20px;
        width: 100%;
    }

    .softWoldClub h5 {
        font-size: 22px;
        margin-top: 0;
    }

    .softWoldClub {
        background-position: bottom;
        height: 430px;
        background-size: 100%;
    }

    .softWoldClubimages {
        left: 20px;
        align-items: flex-end;
        bottom: 70px;
    }

    .softWoldClubimages img {
        width: 60px;
    }

    .softWoldClubText a {
        margin-top: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .softTelegram {
        height: 500px;
        background-size: 800px;
        padding-top: 240px;
    }

    .softTelegram div {
        width: 100%;
        margin-left: 0;
        padding-left: 20px;
        box-sizing: border-box;
    }

    .softTelegram .telegrameLogo1 {
        right: calc(50% - 125px);
        opacity: 1;
        width: 250px;
    }

    .softTelegram div img {
        max-width: calc(100% - 10px);
        width: 100%;
        margin-top: 20px;
    }

    .softTrainingDiv {
        margin-left: 20px;
    }

    .softTraining span {
        max-width: calc(100% - 10px);
    }

    .softTraining p {
        font-size: 22px;
    }

    .softTraining {
        position: relative;
        padding-bottom: 150px;
    }

    .softTraining img {
        position: absolute;
        width: 95%;
        right: 0;
    }

    .softPrefer h5 {
        font-size: 22px;
    }

    .softPreferInfo {
        padding: 15px;
    }

    .softPreferInfo div span::before {
        height: 20px;
        width: 20px;
    }

    .softPreferInfo div {
        padding-bottom: 20px;
    }

    .softPreferInfo div span {
        padding-left: 25px;
        font-size: 13px;
        height: 35px;
    }

    .softBigText {
        width: 80%;
    }

    .softParashut {
        right: 16px;
        top: 355px;
    }
    .softPrices button{
        margin-bottom: 20px;
    }
    .softWhatsapp{
        background-size: 190%;
        background-position: right;
        height: 100vw;
    }
    .softWhatsappInfo{
        margin-left: 20px;
    }
    .softWhatsappInfo{
        width: 95%;
    }
    .softWhatsapp{
        display: flex;
        align-items: flex-end;

    }
}


@media only screen and (max-width: 500px) {
    .soft3in1Div img:first-child {
        display: none;
    }

    .soft3in1Div img:nth-child(2) {
        display: none;
    }

    .soft3in1Div img:last-child {
        display: flex;
        width: 350px;
        max-width: 80%;
    }
}