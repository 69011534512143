.botEducationDiv {
    width: 85%;
    margin: auto;
}

.botEducation {
    margin-bottom: 50px;
}

.forSomeoneDiv {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 5%;
    width: 100%;
    margin-bottom: 100px;
    margin-top: 50px;
    padding-top: 50px;
    position: relative;
}

.forSomeoneDiv svg {
    position: absolute;
    top: -50px;
    left: 30%;
}

.forFreelance {}


.forSomeone {
    position: relative;
    width: 45%;
    max-width: 500px;
    height: 500px;
    border: 1px solid #414149;
    background-color: #141414;
    z-index: 2;
    border-radius: 20px;
    padding: 30px;
    box-sizing: border-box;
}

.forBusines {
    height: 600px;
}

.forSomeone h2 {
    font-weight: 400;
    font-size: 32px;
    color: #F7D81A;
    display: flex;
    align-items: center;
    gap: 40px;
    width: 100%;
    margin: 0;
    margin-top: 10px;
    border-bottom: 1px solid #414149;
    padding-bottom: 30px;
}


.forSomeone h2 img {
    width: 90px;
}

.forSomeone ul {
    padding-left: 25px;
}

.forSomeone li::before {
    content: ' ';
    position: absolute;
    left: -25px;
    width: 20px;
    height: 20px;
    background-image: url(../../../public/static/img/bot/pseudo.png);
    background-size: 20px;
    background-repeat: no-repeat;
}

.forSomeone li {
    position: relative;
    font-weight: lighter;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forSomeone li::marker {
    color: transparent;
}

.forSomeoneCoins {
    position: absolute;
    width: 100px;
}

.forSomeoneElipseShadow {
    position: absolute;
    width: 400px;
    height: 100px;
    background-image: linear-gradient(0deg, rgba(118, 23, 23, 1) 0%, rgba(247, 216, 26, 1) 100%);
    filter: blur(120px);
    opacity: 0.6;
    bottom: -300px;
    right: -300px;
}

.forSomeoneCoins1 {
    left: 0;
    bottom: 0;
    transform: translateX(-80%);
}

.forSomeoneCoins2 {
    right: 0;
    top: 0;
    transform: translateX(50%) translateY(-30%);

}

.forSomeoneCoins3 {
    right: 0;
    bottom: 0;
    transform: translateX(80%) translateY(20%);

}

.botEducation .botHeaderText {
    margin: 0;
}

.botEducation p {
    font-family: 'Gilroy';
    font-weight: lighter;
    font-size: 20px;
    color: #FFFFFF;
    max-width: 60%;
}

.botEducationProgres {
    width: 75vw;
    height: 40vw;
    position: relative;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 200px;
}

.botEducationProgres .forSomeoneElipseShadow {
    bottom: 0;
}


.botEducationProgresVector {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.botEducationIdk {
    font-weight: lighter;
    font-family: 'Gilroy';
    font-size: 15px;
    line-height: 15px;
    color: #FFFFFF;
    margin: 0;

}


.educationPoint {
    position: absolute;
    display: flex;
    transform: translateX(-50%) translateY(-50%);
    transition: 0.3s;
    width: 80px;
    height: 80px;

    /* background-color: #F7D81A; */
}


.educationPoint::before {
    content: '';
    width: 80px;
    height: 80px;
    z-index: 1;
    background-color: #F7D81A;
    transition: 0.3s;
    position: absolute;
    border-radius: 50%;
    top: 0px;
    left: 0px;
}

.educationPoint::after {
    content: '';
    width: 52px;
    height: 52px;
    z-index: 1;
    background-color: #141414;
    transition: 0.3s;
    border-radius: 50%;
    position: absolute;
    left: 14px;
    top: 14px;
}

.educationPoint:hover::before {
    width: 52px;
    height: 52px;
    left: 14px;
    top: 14px;
}

.educationPoint:hover::after {
    background-color: #F7D81A;
}

.educationPoint:hover {
    z-index: 10;
}

.educationPoint:hover .educationPointInfo {
    display: flex;
}

.educationPointHeader {
    font-size: 16px;
    flex-direction: column;
    color: white;
    display: flex;
    margin: 0;
}

.educationPointHeaderAbsolute {
    position: absolute;
    top: 111px;
    left: 31px;
}

.educationPointHeader p {
    margin: 0;
    display: flex;
    align-items: flex-end;
    font-size: 28px;
}

.educationPointHeader h2 {
    margin: 0;
    font-size: 45px;
}


.educationPointInfo {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 80px;
    width: 400px;
    height: auto;
    border: 1px solid #414149;
    background-color: #141414;
    z-index: 2;
    border-radius: 20px;
    padding: 30px;
    box-sizing: border-box;
}

.educationPointInfo ul {
    margin: 0;
    font-family: 'Gilroy';
    font-weight: lighter;
    font-size: 18px;
    color: #FFFFFF;
    padding-left: 25px;
}

.educationPointInfo li::marker {
    color: transparent;
}

.educationPointInfo li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.educationPointInfo li::before {
    content: " ";
    width: 12px;
    height: 12px;
    background-color: #F7D81A;
    position: absolute;
    border-radius: 50%;
    left: 30px;
}

.educationPointInfoHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #414149;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.educationPointTime {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.educationPointTime h4 {
    margin: 0;
    font-size: 17px;
    color: #FFFFFF;
    font-weight: lighter;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.educationPointTime h4 img {}

.educationPointTime h4 {
    margin: 0;
    color: #FFFFFF;
    font-size: 22px;
    font-weight: lighter;

}

.educationPoint6 .educationPointInfo {
    top: auto;
    bottom: 80px;
}

.educationPoint7 .educationPointInfo {
    transform: translateX(-60%);
}

.educationPoint1 {
    top: 24%;
    left: 1%;
}

.educationPoint2 {
    top: 1%;
    left: 28.5714286%;
}

.educationPoint3 {
    top: 72%;
    left: 14.2857143%;
}

.educationPoint4 {
    top: 50%;
    left: 49.5%;
}

.educationPoint5 {
    top: 20%;
    left: 71.142857%;
}

.educationPoint6 {
    top: 99%;
    left: 82.7142858%;
}

.educationPoint7 {
    top: 11%;
    left: 98%;
}

.studnetsReviewsDiv {
    width: 100%;
    display: flex;
    margin-top: 100px;
}

.studnetsReviewsInfo {
    width: 60%;
    position: relative;
}

.studnetsReviewsInfo .botHeaderText {
    margin: 0;
    margin-top: 50px;
}

.studnetsReviewsInfo p {
    font-family: 'Gilroy';
    font-weight: lighter;
    font-size: 22px;
    color: #FFFFFF;
}

.studnetsReviewsInfo svg {
    position: absolute;
    right: 10%;
}

.studnetsReviewsInfo div {
    margin-top: 130px;
    display: flex;
    gap: 30px;
}

.studnetsReviewsInfo div img {
    border-radius: 15px;
    width: 200px;
}

.studnetsReviewsSlider {
    width: 450px;
}

.studnetsReviewsSlider .splide__slide {
    display: flex;
    justify-content: center;
}

.studnetsReviewsSlider .splide.is-initialized {
    padding-bottom: 60px;
}

.selectEduFormatInfo {
    margin-top: 80px;
}

.selectEduFormatInfo .botHeaderText {    
    margin: 0;
    margin-bottom: 20px;
    margin-top: 100px;
}

.selectEduFormatInfo h4 {
    font-family: 'Gilroy';
    font-weight: 400;
    font-size: 23px;
    color: #FFFFFF;
    margin: 0;
    margin-bottom: 15px;

}

.selectEduFormatInfo p {
    font-family: 'Gilroy';
    font-size: 20px;
    color: #FFFFFF;
    margin: 0;
    width: 50%;
}

.selectEduFormat {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 60px;
}

.selectEduFormatBlock {
    position: relative;
    /* height: 500px; */
    border: 1px solid #414149;
    background-color: #141414;
    z-index: 2;
    border-radius: 20px;
    padding: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.selectEduFormatBlock1 {
    width: 25%;
    height: 460px;
}

.selectEduFormatBlock2 {
    width: 30%;
    height: 650px;
}

.selectEduFormatBlock3 h2 {
    font-size: 37px;
}

.selectEduFormatBlock3 {
    width: 40%;
    height: 750px;
}

.selectEduFormatBlock h2 {
    font-weight: lighter;
    font-size: 45px;
    color: #F0F0F2;
    display: flex;
    gap: 30px;
    align-items: flex-start;
    margin: 0;
    position: relative;
}

.selectEduFormatBlock h2 img {
    margin-top: 10px;
    height: 70px;
}

.selectEduFormatBlock h4 {
    font-family: 'Gilroy';
    font-weight: 400;
    font-size: 20px;
    color: #F0F0F2;
    margin: 0;
    position: absolute;
    left: 150px;
    top: 120px;
}

.selectEduFormatBlock h4 h4 {
    font-weight: lighter;
    font-size: 25px;
    color: #F0F0F2;
    margin: 0;
}

.selectEduFormatBlock h5 {
    font-weight: lighter;
    font-size: 38px;
    color: #F0F0F2;
    margin-top: 70px;
}

.selectEduFormatBlock hr {
    width: 100%;
    border-color: #414149;
    margin: 0;
    margin-bottom: 15px;
}

.selectEduFormatBlock ul {
    padding-left: 25px;
}

.selectEduFormatBlock li::marker {
    color: transparent;
}

.selectEduFormatBlock li::before {
    content: ' ';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #F7D81A;
    border-radius: 50%;
    margin-left: -25px;
}

.selectEduFormatBlock li {
    font-family: 'Gilroy';
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.selectEduFormatBlockButtonDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 30px;
    left: 0;
}


.selectEduFormatBlock button {
    padding: 18px 32px;
    background: #F7D81A;
    border: 1px solid #141414;
    box-shadow: 0px 20px 60px rgba(247, 216, 26, 0.2), inset -2px -4px 4px rgba(255, 255, 255, 0.25), inset 2px 4px 4px rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    color: #141414;
    font-size: 16px;
    margin: auto;
    cursor: pointer;
}

.selectEduFormatBlock button:hover {
    transition: 0.3s;
    background: #141414;
    color: #F7D81A;

}

.botEducationCredidDiv {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.botEducationCredidDiv p {
    font-weight: lighter;
    font-size: 42px;
    line-height: 70px;
    color: #FFFFFF;
    text-align: center;
}

.botEducationCredidImg1 {
    position: absolute;
    height: 80px;
    transform: rotate(60deg);
    margin-right: -650px;
    top: 129px;
}

.botEducationCredidImg2 {
    position: absolute;
    height: 80px;
    margin-left: -1200px;
    transform: rotate(255deg);
    top: 16px;
}

.botEducationCredidImg3 {
    position: absolute;
    height: 80px;
    margin-bottom: -200px;
}


.forSomeoneHide {
    height: auto !important;
}

.forSomeoneHide li {
    display: none;
}

.forSomeoneHide .payMetodsHideDiv {
    transform: rotate(180deg);
}

@media screen and (max-width:1700px) {
    .selectEduFormatBlock h2 {
        font-size: 40px;
        gap: 20px;
    }
}

@media screen and (max-width:1400px) {
    .botEducationProgres {
        width: 80vw;
    }

    .selectEduFormatBlock h2 {
        font-size: 32px;
        gap: 15px;
    }

    .selectEduFormatBlock h2 img {
        height: 55px;
    }

    .selectEduFormatBlock h5 {
        margin-top: 50px;
    }

    .selectEduFormatBlock h5 {
        font-size: 30px;
    }

    .selectEduFormatBlock h4 {
        top: 70px;
        left: 100px;
    }

    .selectEduFormatBlock {
        padding: 30px;
    }


    .studnetsReviewsInfo div {
        margin-top: 50px;
    }

    .studnetsReviewsInfo svg {
        display: none;
    }

    .studnetsReviewsInfo div img {
        width: 180px;
    }


    .educationPoint {
        scale: 0.8;
    }
}

@media screen and (max-width:1342px) {
    .botEducationCredidImg1 {        
        margin-right: -350px;
        top: 121px;
    }
    
    .botEducationCredidImg2 {        
        margin-left: -1000px;        
    }
}
    @media screen and (max-width:1200px) {
    .botEducationDiv {
        width: 95%;
    }

    .selectEduFormatBlock h2 {
        font-size: 28px;
        gap: 15px;
    }

    .selectEduFormatBlock h2 img {
        height: 55px;
    }

    .selectEduFormatBlock h5 {
        margin-top: 50px;
    }

    .selectEduFormatBlock h5 {
        font-size: 30px;
    }

    .selectEduFormatBlock h4 {
        top: 70px;
        left: 100px;
    }

    .selectEduFormatBlock {
        padding: 30px;
    }


    .studnetsReviewsInfo div {
        margin-top: 50px;
    }

    .studnetsReviewsInfo svg {
        display: none;
    }

    .studnetsReviewsInfo div img {
        width: 180px;
    }


    .educationPoint {
        scale: 0.8;
    }

    .forSomeone h2 {
        gap: 25px;
        font-size: 28px;

    }

    .botEducation p {
        max-width: 100%;
    }

    .forSomeoneDiv {
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    .forSomeone {
        max-width: 95%;
        min-width: 500px;
        height: auto !important;
    }

    .forSomeoneDiv svg {
        display: none;
    }

    .studnetsReviewsInfo div img {
        width: 150px;
    }

    .studnetsReviewsInfo p {
        font-size: 16px;
    }

    .studnetsReviewsSlider .splide__slide img {
        height: 400px;
    }

    .selectEduFormatBlock button {
        padding: 12px 25px;
    }
}

@media screen and (max-width:1000px) {
    .botEducationProgres {
        width: 100%;
        min-width: 1000px;
        height: 450px;
    }

    .botEducationProgresScroll {
        overflow-y: hidden;
        overflow-x: scroll;
        padding: 0 30px;

    }

    .educationPoint7 .educationPointInfo {
        transform: translateX(0%);
    }

    .educationPoint3 .educationPointInfo {
        transform: translateY(-50%);
    }

    .educationPoint4 .educationPointInfo {
        transform: translateY(-30%);
    }

    .botEducationProgres {
        margin-top: 60px;
    }

    .selectEduFormat {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .selectEduFormatBlock {
        width: 100%;
        max-width: 500px;
        height: auto;
    }

    .selectEduFormatBlockButtonDiv {
        position: relative;
        bottom: 0;
    }

    .selectEduFormatInfo p {
        width: 100%;
        margin-bottom: 70px;
    }

    .botEducationCredidDiv p {
        font-size: 25px;
        line-height: 30px;
    }

    .selectEduFormatBlock h2 {
        font-size: 20px;

    }

    .botEducation .botHeaderText {
        font-size: 20px;
    }

    .botEducation p {
        font-size: 16px;
    }

    .forSomeoneDiv {
        margin-top: 0;
    }

    .botEducationIdk {
        font-size: 14px;
    }

    .studnetsReviewsDiv {
        flex-direction: column;
    }

    .studnetsReviewsInfo p {
        font-size: 16px;
    }

    .studnetsReviewsInfo {
        width: 100%;
    }

    .studnetsReviewsInfo div {
        display: none;
    }

    .studnetsReviewsSlider {
        max-width: 100%;
        box-sizing: border-box;
    }

    .selectEduFormatInfo .botHeaderText {
        font-size: 20px;
    }

    .selectEduFormatInfo h4 {
        font-size: 18px;
    }

    .selectEduFormatInfo p {
        font-size: 16px;
    }

    .selectEduFormatBlock li {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .selectEduFormatBlock h2 img {
        height: 35px;
    }

    .selectEduFormatBlock h4 {
        top: 53px;
        left: 80px;
    }

    .selectEduFormatBlock h5 {
        font-size: 22px;
        margin-top: 40px;

    }

    .botEducationCredidImg1{
        margin-right: -300px;
    }
    .botEducationCredidImg2 {
        margin-left: -1000px;
    }
    .botEducationCredidImg1,
    .botEducationCredidImg2 {
        display: none;
        
    }

    .botEducationCredidImg3 {
        margin-bottom: -162px;
    }

    .botEducationCredidDiv p {
        margin-top: 50px;
    }
}

@media screen and (max-width:600px) {
    .forSomeone {
        min-width: auto;
        width: 95%;
        padding: 15px;
        padding-bottom: 30px;
    }

    .forSomeone li {
        font-size: 14px;
        font-weight: lighter;
        margin-bottom: 10px;
    }

    .forSomeone ul {
        padding-left: 15px;
    }

    .forSomeone h2 {
        font-size: 20px;
        gap: 10px;
        justify-content: center;
    }

    .forSomeone h2 img {
        width: 50px;
    }

    .forSomeoneCoins1,
    .forSomeoneCoins2,
    .forSomeoneCoins3 {
        display: none;
    }
}