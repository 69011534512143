/* border-image: linear-gradient(45deg, purple, orange) 1; */
@font-face {
    font-family: DaysOne;
    src: url(../../css/fonts/DaysOne-Regular.ttf);
}

@font-face {
    font-family: Gilroy;
    src: url(../../css/fonts/Gilroy-Regular.ttf)
}

@font-face {
    font-family: Dekko;
    src: url(../../css/fonts/Dekko-Regular.ttf)
}

.messageMailing {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.3;
}

.messageMailing h5 {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F7D81A;
    margin: 0;
    margin-bottom: 20px;
}

.messageMailing p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    margin: 0;
    margin-bottom: 55px;
    width: 50%;
}

.apps {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    user-select: none;
    transition: 0.3s;
}

.mailingApp .name {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    text-align: center;
    letter-spacing: 0.1em;
    background: linear-gradient(329.81deg, #F9B21A 0%, rgba(245, 249, 26, 0.78) 64.58%, rgba(245, 249, 26, 0) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 10px 0;
}

.mailingApp .price {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.05em;
    background: linear-gradient(329.81deg, #F9B21A 0%, rgba(245, 249, 26, 0.78) 64.58%, rgba(245, 249, 26, 0) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin: 0;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 90%;
}

.mailingApp span {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #F4F4F4;
    margin: 14px 0;
}

.mailingApp button {
    font-family: DaysOne;
    border: 0;
    background: none;
    color: #F7D81A;
    width: 150px;
    height: 50px;
    background-image: url(../../../public/static/img/buttonBorder.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.mailingApp img {
    width: 80px;
}

.mailingApp .treeD {
    position: absolute;
    opacity: 0;
    transform: rotate(180deg);
    transition: .3s;
}

.mailingApp .twoD {
    position: absolute;
    opacity: 1;
    transform: rotate(0deg);
    transition: .3s;
}

.mailingApp {
    margin-top: 40px;
    border-radius: 40px;
    width: 240px;
    height: 320px;
    background: linear-gradient(210.19deg, rgba(146, 135, 200, 0) 0%, rgba(104, 93, 169, 0) 100%);
    background-image: url(../../../public/static/img/appBorder.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.3s;    
    border: 1px solid #b15bbe00;

}
.mailingApp div{
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mailingApp:hover {
    margin-top: 10px;
    margin-bottom: 30px;
}

.instagram:hover {
    background: linear-gradient(45deg, rgba(241,206,88,0.33) 0%, rgba(209,63,131,0.33) 48%, rgba(131,128,210,0.33) 100%);
    box-shadow: 0px 0px 60px 25px rgba(177, 91, 190, 0.2);
    border-radius: 40px;
}

.mailingApp:hover .instagram3d {
    opacity: 1;
    transform: rotate(0deg)
}

.mailingApp:hover .instagram2d {
    opacity: 0;
    transform: rotate(-180deg)
}

.instagram:hover .instagramPrice{
    background: none;
    -webkit-background-clip: none;
    -webkit-text-fill-color: White;
    background-clip: none;
    text-shadow: none;
}
.instagram:hover .instagramButton{
    border: 1px solid #B15BBE;
    border-radius: 10px;
    background-image: none;
    color: #F4F4F4;
    background-color: rgba(255, 255, 255, 0.156);
}

.telegram:hover {
    background: linear-gradient(45deg, rgba(17,150,195,.33) 0%, rgba(116,223,244,.33) 100%);
    box-shadow: 0px 0px 60px 25px rgba(28, 191, 227, 0.2);
    border-radius: 40px;
}

.mailingApp:hover .telegram3d {
    opacity: 1;
    transform: rotate(0deg)
}

.mailingApp:hover .telegram2d {
    opacity: 0;
    transform: rotate(-180deg)
}
.telegram:hover .telegramPrice{
    background: none;
    -webkit-background-clip: none;
    -webkit-text-fill-color: White;
    background-clip: none;
    text-shadow: none;
}
.telegram:hover .telegramButton{
    border: 1px solid rgba(116,223,244,.33);
    border-radius: 10px;
    background-image: none;
    color: #F4F4F4;
    background-color: rgba(255, 255, 255, 0.156);
}
.whatsApp:hover {
    background: linear-gradient(45deg, rgba(12,198,44,.33) 0%, rgba(127,243,159,.33) 100%);
    box-shadow: 0px 0px 60px 25px rgba(51, 223, 80, 0.2);
    border-radius: 40px;
}

.mailingApp:hover .whatsApp3d {
    opacity: 1;
    transform: rotate(0deg)
}

.mailingApp:hover .whatsApp2d {
    opacity: 0;
    transform: rotate(180deg)
}

.whatsApp:hover .whatsAppPrice{
    background: none;
    -webkit-background-clip: none;
    -webkit-text-fill-color: White;
    background-clip: none;
    text-shadow: none;
}
.whatsApp:hover .whatsAppButton{
    border: 1px solid rgba(12,198,44,1);
    border-radius: 10px;
    background-image: none;
    color: #F4F4F4;
    background-color: rgba(255, 255, 255, 0.156);
}

.viber:hover {
    background: linear-gradient(210.19deg, rgba(146, 135, 200, 0.3) 0%, rgba(104, 93, 169, 0.3) 100%);
    box-shadow: 0px 0px 60px 25px rgba(133, 121, 192, 0.2);
    border-radius: 40px;
}

.mailingApp:hover .viber3d {
    opacity: 1;
    transform: rotate(0deg)
}

.mailingApp:hover .viber2d {
    opacity: 0;
    transform: rotate(180deg)
}

.viber:hover .viberPrice{
    background: none;
    -webkit-background-clip: none;
    -webkit-text-fill-color: White;
    background-clip: none;
    text-shadow: none;
}
.viber:hover .viberButton{
    border: 1px solid #B15BBE;
    border-radius: 10px;
    background-image: none;
    color: #F4F4F4;
    background-color: rgba(255, 255, 255, 0.156);
}
.vkontakte:hover {
    background: linear-gradient(45deg, rgba(17,150,195,.33) 0%, rgba(116,223,244,.33) 100%);
    box-shadow: 0px 0px 60px 25px rgba(28, 191, 227, 0.2);
    border-radius: 40px;
}

.mailingApp:hover .vkontakte3d {
    opacity: 1;
    transform: rotate(0deg)
}

.mailingApp:hover .vkontakte2d {
    opacity: 0;
    transform: rotate(180deg)
}
.vkontakte:hover .vkontaktePrice{
    background: none;
    -webkit-background-clip: none;
    -webkit-text-fill-color: White;
    background-clip: none;
    text-shadow: none;
}
.vkontakte:hover .vkontakteButton{
    border: 1px solid rgba(116,223,244,1);
    border-radius: 10px;
    background-image: none;
    color: #F4F4F4;
    background-color: rgba(255, 255, 255, 0.156);
}
@media only screen and (max-width: 1668px) {
    .apps{
        flex-wrap: wrap;
    }
    .mailingApp{
        margin-left: 15px;
        margin-right: 15px;
    }
}
@media only screen and (max-width: 1350px) {
    .apps{
        width: 80%;
    }
    .messageMailing p {
        width: 80%;
    }
}
@media only screen and (max-width: 700px) {    
    .messageMailing h5{
        font-size: 32px;
    }
    .mailingApp {
        width: 150px;
        height: 219px;
        border-radius: 25px;
        margin-top: 20px;
        background-image: url(../../../public/static/img/appBorderVerical.png);

    }
    .mailingApp:hover {
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 30px;
    }
    .mailingApp img{
        width: 40px;
        transition: .3s;
    }
    .mailingApp .price{
        font-size: 21px;
        line-height: 28px;

    }
    .mailingApp span{
        margin: 5px 0;
    }
    .mailingApp div{
        width: 40px;
        height: 40px;
    }
    .mailingApp .name{
        margin: 10px 0;
    }
    .mailingApp button{
        width: 115px;
        height: 38px;
        margin-left: 0;
        margin-right: 0;
        margin-top: 15px;
    }
    .apps{
        width: 100%;
    }
    .messageMailing p {
        width: 90%;
    }
    .messageMailing h5{
        font-size: 23px;
    }
}
@media only screen and (max-width: 500px) {    
    .messageMailing p {    
        font-size: 13px;
        text-align: left;
    }
    
    .instagram {
        background: linear-gradient(45deg, rgba(241,206,88,0.33) 0%, rgba(209,63,131,0.33) 48%, rgba(131,128,210,0.33) 100%);
        box-shadow: 0px 0px 5px 3px rgba(177, 91, 190, 0.2);
        border-radius: 40px;
    }
    
    .mailingApp .instagram3d {
        opacity: 1;
        transform: rotate(0deg)
    }
    
    .mailingApp .instagram2d {
        opacity: 0;
        transform: rotate(-180deg)
    }
    
    .instagram .instagramPrice{
        background: none;
        -webkit-background-clip: none;
        -webkit-text-fill-color: White;
        background-clip: none;
        text-shadow: none;
    }
    .instagram .instagramButton{
        border: 1px solid #B15BBE;
        border-radius: 10px;
        background-image: none;
        color: #F4F4F4;
        background-color: rgba(255, 255, 255, 0.156);
    }
    
    .telegram {
        background: linear-gradient(45deg, rgba(17,150,195,.33) 0%, rgba(116,223,244,.33) 100%);
        box-shadow: 0px 0px 5px 3px rgba(28, 191, 227, 0.2);
        border-radius: 40px;
    }
    
    .mailingApp .telegram3d {
        opacity: 1;
        transform: rotate(0deg)
    }
    
    .mailingApp .telegram2d {
        opacity: 0;
        transform: rotate(-180deg)
    }
    .telegram .telegramPrice{
        background: none;
        -webkit-background-clip: none;
        -webkit-text-fill-color: White;
        background-clip: none;
        text-shadow: none;
    }
    .telegram .telegramButton{
        border: 1px solid rgba(116,223,244,.33);
        border-radius: 10px;
        background-image: none;
        color: #F4F4F4;
        background-color: rgba(255, 255, 255, 0.156);
    }
    .whatsApp {
        background: linear-gradient(45deg, rgba(12,198,44,.33) 0%, rgba(127,243,159,.33) 100%);
        box-shadow: 0px 0px 5px 3px rgba(51, 223, 80, 0.2);
        border-radius: 40px;
    }
    
    .mailingApp .whatsApp3d {
        opacity: 1;
        transform: rotate(0deg)
    }
    
    .mailingApp .whatsApp2d {
        opacity: 0;
        transform: rotate(180deg)
    }
    
    .whatsApp .whatsAppPrice{
        background: none;
        -webkit-background-clip: none;
        -webkit-text-fill-color: White;
        background-clip: none;
        text-shadow: none;
    }
    .whatsApp .whatsAppButton{
        border: 1px solid rgba(12,198,44,1);
        border-radius: 10px;
        background-image: none;
        color: #F4F4F4;
        background-color: rgba(255, 255, 255, 0.156);
    }
    
    .viber {
        background: linear-gradient(210.19deg, rgba(146, 135, 200, 0.3) 0%, rgba(104, 93, 169, 0.3) 100%);
        box-shadow: 0px 0px 5px 3px rgba(133, 121, 192, 0.2);
        border-radius: 40px;
    }
    
    .mailingApp .viber3d {
        opacity: 1;
        transform: rotate(0deg)
    }
    
    .mailingApp .viber2d {
        opacity: 0;
        transform: rotate(180deg)
    }
    
    .viber .viberPrice{
        background: none;
        -webkit-background-clip: none;
        -webkit-text-fill-color: White;
        background-clip: none;
        text-shadow: none;
    }
    .viber .viberButton{
        border: 1px solid #B15BBE;
        border-radius: 10px;
        background-image: none;
        color: #F4F4F4;
        background-color: rgba(255, 255, 255, 0.156);
    }
    .vkontakte {
        background: linear-gradient(45deg, rgba(17,150,195,.33) 0%, rgba(116,223,244,.33) 100%);
        box-shadow: 0px 0px 5px 3px rgba(28, 191, 227, 0.2);
        border-radius: 40px;
    }
    
    .mailingApp .vkontakte3d {
        opacity: 1;
        transform: rotate(0deg)
    }
    
    .mailingApp .vkontakte2d {
        opacity: 0;
        transform: rotate(180deg)
    }
    .vkontakte .vkontaktePrice{
        background: none;
        -webkit-background-clip: none;
        -webkit-text-fill-color: White;
        background-clip: none;
        text-shadow: none;
    }
    .vkontakte .vkontakteButton{
        border: 1px solid rgba(116,223,244,1);
        border-radius: 10px;
        background-image: none;
        color: #F4F4F4;
        background-color: rgba(255, 255, 255, 0.156);
    }
    .mailingApp {
        border-radius: 20px;
    }
}