.botUnderKeydiv {
    width: 85%;
    margin: auto;
    position: relative;
    height: auto;
    display: flex;
    margin-top: -40px;
}

.botUnderKeyInfo {
    width: 60%;
}

.botUnderKeyInfo .botHeaderText {
    margin: 0;
    margin-top: 50px;
}
.botUnderKeyInfo p {
    font-family: 'DaysOne';
    font-weight: 400;
    font-size: 20px;
    color: #F4F4F4;
}

.botUnderKeyInfo li {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    max-width: 80%;
}

.botUnderKeyInfo ol {
    list-style-type: none;
    counter-reset: my-counter;
    padding-left: 20px;
}

.botUnderKeyInfo li::before {
    content: counter(my-counter);
    counter-increment: my-counter;
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: #F7D81A;
    margin-right: 15px;
}

.botUnderKeyImage {
    width: 60%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    right: 0;
}

.botUnderKeyImage img {
    width: 80%;
    margin-top: -50px;
}

@media screen and (max-width:1100px) {
    .botUnderKeydiv {
        width: 95%;
    }
    .botUnderKeyInfo p {
        font-size: 16px;
    }

    .botUnderKeyInfo li {
        font-size: 15px;
        margin-bottom: 15px;
    }

}

@media screen and (max-width:800px) {
    .botUnderKeydiv {
        width: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        margin: 0;
    }

    .botUnderKeyImage {
        position: relative;
        width: 100%;
        justify-content: center;
    }

    .botUnderKeyImage img {
        max-width: 500px;
        margin: 0;
    }

    .botUnderKeyInfo {
        width: 100%;
        max-width: 500px;
        margin-top: 30px;
    }

    .botUnderKeyInfo p {
        font-size: 16px;
    }

    .botUnderKeyInfo li {
        font-size: 15px;
        margin-bottom: 15px;
    }

}

@media screen and (max-width:600px) {
    .botUnderKeyImage img {
        width: 60%;
    }

    .botUnderKeyInfo {
        width: 80%;
        margin-top: 15px;
    }
    .botUnderKeyInfo li::before{
        font-size: 25px;
        margin-right: 10px;
    }
    .botUnderKeyInfo li:nth-child(1)::before{
        margin-right: 15px;
    }
    .botUnderKeyInfo li{
        font-size: 13px;
        max-width: 90%;
    }
        
    .botUnderKeyInfo p{
        font-size: 14px;
    }
}
@media screen and (max-width:450px) {
    .botUnderKeyImage img {
        width: 80%;
    }

    .botUnderKeyInfo {
        width: 95%;
        margin-top: 15px;
    }
}