.botCabinetDiv {
    width: 100%;
    display: flex;
    padding-top: 45px;
    justify-content: space-around;
}

.botCabinetInfo {
    width: 70%;
    min-width: 350px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
}

.botCabinetInfo fieldset {
    border: 1px solid #fff;
    border-radius: 9px;
    width: 100%;
    max-width: 95%;
    margin-bottom: 20px;
    padding: 2px 10px;
    padding-bottom: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botCabinetInfo fieldset legend {
    background: 0;
    border: 0;
    color: #fff;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: .1em;
    color: white;
    margin: 0;
    margin-left: 10px;
}

.botCabinetInfoBalanceDiv {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.botCabinetInfo .botCabinetInfoBalanceInfoDiv fieldset{
    border-bottom: 1px solid white;
    border-radius: 9px;
}

.botCabinetInfo .botCabinetInfoBalance {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px 9px 0 0;
    border-bottom: 0;
    margin-bottom: 0;
}

.botCabinetInfoBalance p {
    color: white;
    font-family: 'DaysOne';
    font-size: 23px;
}

.botCabinetInfoBalanceDiv button img {
    filter: sepia(1) brightness(10) invert(1);
    height: 25px;
    transition: 0.2s;
}

.botCabinetBalanceInput {
    width: 307px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.botCabinetBalanceInput input::placeholder {
    color: #fff;
    font-size: 12px;
}

.botCabinetInfoBalance input:focus {
    outline: none;
}

.botCabinetInfoBalance input {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background-color:transparent;
    height: 41px;
    margin: 0;
    width: 100%;
    color: #fff;
    border-radius: 0 0 0px 9px;
    text-align: center;
    font-size: 16px;
    border-right: 0;
}

.botCabinetBalanceInput .wrongPopup {
    border-color: #de2727;
    color: #de2727;
}

.botCabinetBalanceInput button p {
    margin: 0;
    margin-right: 10px;
    font-size: 14px;
}
.botCabinetBalanceInput button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F7D81A;
    border: 1px solid #F7D81A;
    border-radius: 0 0 9px 9px;
    margin-bottom: 30px;
    height: 35px;
    font-size: 14px;
    /* border-right: 1px solid #fff; */
    /* border-left: 1px solid #fff; */
    transition: 0.2s;
    cursor: pointer;
    margin: 0;
}

.botCabinetBalanceInput button:disabled {
    opacity: 0.7;
    filter: contrast(0.6);
    cursor: not-allowed;
}

.botCabinetBalanceInput button:hover img {
    filter: none;
}

.botCabinetBalanceInput button:hover {
    background-color: #F7D81A20;
    color: #F7D81A;
}

.botCabinetInfo .botCabinetInfoHistoryDiv {
    height: 400px;
    overflow-y: scroll;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    min-width: 650px;
    margin-top: 50px;
}

.botCabinetHistory {
    width: 90%;    
    height: 30px;
    border-top: 1px solid #fff;    
    display: flex;
    justify-content: space-around;
    padding-top: 10px;    
}

.botCabinetHistory:nth-child(2) {
    border-top: 0px solid #fff;
}

.botCabinetHistory p {
    color: #fff;
    font-size: 15px;
    margin: 0;
    margin-bottom: 4px;
    width: 40%;
    font-family: Gilroy;
}

.botCabinetHistory span {
    color: rgb(177, 177, 177);
    font-size: 14px;
    font-family: Gilroy;
}

.botCabinetContent {
    width: 60%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: flex-start;
}


.botCabinetContent a:nth-child(1) div {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 40px;
}

.botCabinetContent a:nth-child(1) {
    width: 100%;

}

.botCabinetContent a {
    display: flex;
    width: 47.5%;
    height: auto;
}

.botCabinetContent div {
    width: 100%;
    height: 282px;
    border: 1px solid #fff;
    border-radius: 9px;
    box-shadow: inset 1px 2px 2px rgba(255, 255, 255, 0.25), inset -1px -2px 2px rgba(255, 255, 255, 0.5);
    background: linear-gradient(130deg, rgba(247, 218, 26, 0.00) 10%, rgba(247, 218, 26, 0.06) 75%, rgba(255, 255, 255, 0) 100%);
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-around;
}

.botCabinetContentCallBot {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-around;
}

.botCabinetContent div section {
    width: 90%;
}

.botCabinetContent .botCabinetContentCallBot section {
    width: 50%;
}

.botCabinetContent div section span {
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.botCabinetContent div section p {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #F7D81A;
    margin: 0;
    margin-top: 0px;
    margin-bottom: 20px;

}

.botCabinetContentCallBot img {
    height: 80%;
}
.botCabinetInfo fieldset h4{
    color: white;
    margin: auto;
    font-size: 16px;
    transform: translateY(-200%);
    font-family: Gilroy;
}
.paymentHidediv{
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: #0000008a;
    z-index: 5;
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.paymentHidediv p{
    font-size: 30px;
}
@media screen and (max-width:950px) {
    .botCabinetInfo{
        justify-content: space-around;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .botCabinetInfo .botCabinetInfoHistoryDiv{
        margin-top: 0;
    }
    .botCabinetInfoBalanceDiv {
        margin-bottom: 30px;
    }
}
@media screen and (max-width:750px) {
    .botCabinetInfo .botCabinetInfoHistoryDiv {
        min-width: 300px;
        width: 300px;
    }
    .botCabinetHistory{
        display: block;
        height: 100px;
    }
    .botCabinetHistory p{
        width: 100%;
}
}