#mobileSlider img {
    width: 250px;
    border-radius: 10px;
    margin: 10px;
}

#mobileSlider {
    /* display: none;
    width: 90%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: auto;
    column-count: 1; */

    width: 90%;
    flex-wrap: wrap;
    column-count: 6;
    margin: auto;
    margin-top: 100px;
}

#slider {
    display: block;
}

.chatfonReview {
    background-image: url(../../../public/static/img/review.png);
    height: 50vh;
    background-size: 400px;
    background-position-x: 80%;
    background-position-y: center;
    background-repeat: no-repeat;
}

.reviewBottom {
    background-image: url(../../../public/static/img/path4529.svg);
    background-size: 500px;
    background-repeat: no-repeat;
}

.homeHeaderReview {
    margin-bottom: 0;
}

@media only screen and (max-width: 1700px) {
    #mobileSlider {
        column-count: 5;
    }

    .chatfonReview {
        background-position-x: 100%;
    }
}

@media only screen and (max-width: 1500px) {
    #mobileSlider {
        column-count: 4;
    }
}

@media only screen and (max-width: 1200px) {
    #mobileSlider {
        column-count: 3;
    }

    .chatfonReview {
        opacity: .5;
    }
}

@media only screen and (max-width: 1100px) {
    .reviewBottom {
        background-size: 200px;
    }
}

@media only screen and (max-width: 900px) {
    #mobileSlider {
        column-count: 2;
    }

    #mobileSlider {
        display: block;
    }

    /* #slider{
        display: none;    
    } */
    #mobileSlider img {
        margin: 30px;
    }
}

@media only screen and (max-width: 600px) {
    #mobileSlider {
        display: flex;
        justify-content: center;
        align-items: center;
        column-count: 1;
    }

    .chatfonReview {
        background-size: 70%;
    }
}