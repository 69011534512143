.bonusDiv {
    width: 80%;
    display: flex;
    justify-content: space-around;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0px;
    margin-bottom: 0;
    box-shadow: 0px 0px 35px rgba(247, 216, 26, 0.2);
    padding: 80px 10%;
}

.bonusImage {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bonusImage div {
    position: absolute;
    width: 219px;
    border-radius: 50%;
    height: 219px;

    background: #FFF172;
    filter: blur(200px);
}

.bonusImage img {
    width: 65%;
}
.bonusText h2 {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    background: linear-gradient(231.86deg, #C41617 28.89%, rgba(255, 85, 85, 0.78) 75.53%, rgba(222, 58, 57, 0.5) 100.02%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0;
    margin-bottom: -10px;
}
@media only screen and (max-width: 1668px) {
    .bonusDiv {
        width: 90%;
        padding: 80px 5%;
    }
}
@media only screen and (max-width: 1350px) {
    .bonusImage img{
        width: 50%;
    }
}
@media only screen and (max-width: 1100px) {    
    .bonusDiv{
        flex-direction: column;
        align-items: center;
    }
    .workText{
        width: 90%;
    }
    .bonusImage{
        margin-top: 50px;
    }
}
@media only screen and (max-width: 700px) {    
    .bonusDiv{
        margin-top: 0;
    }
}
@media only screen and (max-width: 450px) {
    .bonusText h2{
        text-align: center;
        width: 100%;
        font-size: 30px;
    }
}