@font-face {
    font-family: DaysOne;
    src: url(fonts/DaysOne-Regular.ttf);
}

@font-face {
    font-family: Gilroy;
    src: url(fonts/Gilroy-Regular.ttf);
}

body {
    margin: 0;
    background: #141414;
}

.hiddetH1Seo {
    position: absolute;
    display: none;
}

* {
    font-family: DaysOne;
    -webkit-tap-highlight-color: transparent;
}

a,
p,
span,
h5,
h2,
h3,
h4,
h4,
label,
img,
li,
br,
svg,
div,
button {
    user-select: none;
}

a {
    text-decoration: none;
}

*::selection {
    color: #141414;
    background: #F7D81A;
}

i {
    font-style: normal;
    color: #F7D81A;
}

html {
    overflow-x: hidden;
    scroll-behavior: smooth;

}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 9px;
    background: #141414;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #F7D81A;
}

/* #root{
    height: 200vh;
} */
/* ______Header______ */
.header {
    width: 100%;
    height: 80px;
    padding-bottom: 5px;
    border-bottom: 0.6px solid #F4F4F4;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.headerNumber {
    display: flex;
    justify-content: center;
    align-items: center;
}



.headerNumber p {
    font-family: DaysOne;
    color: #F7D81A;
    font-size: 19px;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.15em;
    margin: 5px;
    user-select: text;
    text-align: center;
}

.headerCabinet {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerCabinet span {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #F7D81A;
    margin: 0;
    margin-right: 20px;
}

.headerCabinet p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    background-color: #F7D81A;
    padding: 8px 22px;
    border-radius: 10px;
    margin: 0;
}

.manu {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.manuElements {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}


.manuElements a {
    text-decoration: none;
}

.manuElements p:hover {
    color: #F7D81A;
}

.manuElements p {
    font-family: DaysOne;
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.15em;
    margin: 1px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid #F7D81A00;
    padding-bottom: 5px;
    margin-bottom: -6px;
    transition: 0.3s;
}

.manuElements .bottomBorder {
    border-bottom: 3px solid #F7D81A;
}

.openMailingManu p div {
    border: solid #ACACAC;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-top: -1px;
    transform: rotate(45deg);
    margin-left: 7px;
}

.linkActive {
    border-bottom: 3px solid #F7D81A;
}

.manuSlider {
    width: 100%;
}

.phoneManu div {
    width: 100%;
    top: 120px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.phoneManu {
    display: none;
}

/* ______Home_______ */
.rates {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
    flex-wrap: wrap;
}

.rate img {
    width: 15px;
}

.rate span {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    margin-left: 5px;
}

.homeHeader {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 15vh;
    margin-bottom: 70px;
}

.homeHeaderText {
    width: 50%;
}

.homeHeaderText h4 {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 76px;
    letter-spacing: 0.1em;
    color: #F7D81A;
    margin: 0;
}

.homeHeaderText h2 {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    margin: 0;
}

.homeHeaderText h3 {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 51px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    margin: 0;
}

.homeHeaderText p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    width: 70%;
    margin-bottom: 40px;
}

.homeHeaderImage {
    width: 45%;
    display: flex;
    justify-content: flex-end;
}

.homeHeaderButtonDiv {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.homeHeaderButtonDiv a {
    width: 48%;
}


.homeHeaderButtonDiv button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 56px;
    cursor: pointer;
}

.getPresent:hover {
    background: rgba(0, 0, 0, 0);
    border: 1px solid #F7D81A;
    color: #F7D81A;
}

.getPresent {
    transition: .25s;
    background: #F7D81A;
    border: 0.5px solid #141414;
    /* box-shadow: 0px 20px 60px rgba(247, 216, 26, 0.2), inset -2px -4px 4px rgba(255, 255, 255, 0.25), inset 2px 4px 4px rgba(255, 255, 255, 0.25); */
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #141414;
}

.selfMailig {
    transition: .25s;
    background: rgba(20, 20, 20, 0);
    border: 1px solid #F7D81A;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #F7D81A;
}

.selfMailig:hover {
    background-color: #F7D81A;
    color: #141414;
    box-shadow: 1px 1px 20px 5px rgba(247, 218, 26, 0.2);

}

.headerSocialLinks {
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
}

.headerSocialText {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.1em;
    color: #F7D81A;
    width: 100%;
    text-align: center;
    margin: 0;
    margin-bottom: 25px;
    margin-top: 40px;
}

.headerSocialLinks img:hover {
    filter: invert(0) brightness(1);
}

.headerSocialLinks img {
    transition: .3s;
    filter: brightness(0) invert(1);
    height: 28px;
}

.mailingManu p:hover {
    color: #F7D81A;
}

.mailingManu p {
    color: white;
    opacity: 0;
    transition: 0.3s;
    margin-left: -70px;
}

.mailingManuDiv {
    position: absolute;
    width: 200px;
    height: 0px;
    display: flex;
    justify-content: center;
}

.mailingManu {
    width: 200px;
    border: 1px solid#F4F4F4;
    margin-top: 30px;
    transition: .2s;
    background-color: #141414;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 15px;
    z-index: 4;
    height: 0px;
    display: none;
}

.openMailingManu .mailingManuActive {
    height: 250px;
    width: 200px;
    margin-left: 0;
}

.openMailingManu {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 5;
}



/* _________Footer___________ */
.footer {
    margin-top: 150px;
    width: 100%;
    min-height: 150px;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 100px;
}

.topFooter {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.midFooter {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.footerIINInfo {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

}

.footerIINInfo p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    margin: 2px 0;
}

.footerTopManu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
}

.footerTopManu p:hover {
    color: #F7D81A;
}

.footerTopManu p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
}

.footerSocialLinks {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}

.footerSocialLinks a {

    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    margin: 2px 0;
    text-decoration: none;
}

.footerSocialLinks a:hover {
    color: #F7D81A;
}

.bottomFooter {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.bottomFooter div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.bottomFooter div a {
    text-align: right;
}

.bottomFooter div a:hover {
    color: #F7D81A;
}


.bottomFooter a,
.bottomFooter p {
    margin: 0;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    text-align: left;

}

.footer .freekassa {
    display: flex;
    width: 170px;
    height: 60px;
    margin-top: 30px;
}

.footer .freekassa img {
    width: 170px;
    height: 60px;
}

.homeHeaderButtonDiv .headerSocialLinks {
    display: none;
}

.supportForm .errorText {
    color: #ff6060;
    text-align: center;
}

/* _________Loader__________ */
.loaderDiv {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100vh;
    top: 0;
    transition: .9s;
}

.loaderDiv1 {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 50vh;
    background-color: #141414;
    top: 0;
    transition: .5s;
}

.loaderDiv2 {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 51vh;
    background-color: #141414;
    bottom: 0;
    transition: .5s;
}

.loaderDiv span {
    position: fixed;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F4F4F4;
    width: 100%;
    text-align: center;
    left: 0;
    bottom: calc(50px);
    opacity: 1;
    transition: .5s;
    z-index: 999999;
}

.baksboLogo {
    height: 50px;
    width: 273px;
    background-image: url(../../public/static/img/baksbo.png);
    background-size: cover;
    position: fixed;
    left: calc(50% - 137px);
    top: calc(50vh - 75px);
    opacity: 1;
    transition: .5s;
    z-index: 999999;
}

.loadingText {
    position: fixed;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 36px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F4F4F4;
    width: 200px;
    text-align: center;
    left: calc(50% - 100px);
    top: calc(50vh - 50px);
    opacity: 1;
    transition: .5s;
    z-index: 999999;
}

.loaderImg {
    background-image: url(../../public/static/img/loading.png);
    background-size: cover;
    width: 80px;
    height: 80px;
    position: fixed;
    left: calc(50% - 40px);
    top: calc(50vh + 20px);
    animation-name: loader;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    opacity: 1;
    transition: .5s;
    z-index: 999999;

}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loaderAnimation {
    width: 140px;
    height: 140px;
    position: fixed;
    left: calc(50% - 70px);
    top: calc(50vh + 30px);
    opacity: 1;
    transition: .5s;
    z-index: 999999;
    /* background-color: green;
    color: red; */
    font: 1em/1.5 sans-serif;
    display: grid;
    place-items: center;
    transition: 0.1s;
}

:root {
    --hue: 223;
    --bg: hsl(var(--hue), 10%, 10%);
    --fg: hsl(var(--hue), 10%, 90%);
    font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));
}

main {
    padding: 1.5em 0;
}

.loaderAnimationFon {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: 128px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #141414;
    transition: background-color 0.3s;
}


main {
    padding: 1.5em 0;
}

.pl,
.pl__worm {
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

.pl {
    animation-name: bump;
    animation-timing-function: linear;
    width: 140px;
    height: 140px;
}

.pl__ring {
    stroke: hsla(var(--hue), 10%, 90%, 0.1);
    transition: stroke 0.3s;
}

.pl__worm {
    animation-name: worm;
    animation-timing-function: cubic-bezier(0.42, 0.17, 0.75, 0.83);
}

/* Animations */
@keyframes bump {

    from,
    42%,
    46%,
    51%,
    55%,
    59%,
    63%,
    67%,
    71%,
    74%,
    78%,
    81%,
    85%,
    88%,
    92%,
    to {
        transform: translate(0, 0);
    }

    44% {
        transform: translate(1.33%, 6.75%);
    }

    53% {
        transform: translate(-16.67%, -0.54%);
    }

    61% {
        transform: translate(3.66%, -2.46%);
    }

    69% {
        transform: translate(-0.59%, 15.27%);
    }

    76% {
        transform: translate(-1.92%, -4.68%);
    }

    83% {
        transform: translate(9.38%, 0.96%);
    }

    90% {
        transform: translate(-4.55%, 1.98%);
    }
}

@keyframes worm {
    from {
        stroke-dashoffset: 10;
    }

    25% {
        stroke-dashoffset: 295;
    }

    to {
        stroke-dashoffset: 1165;
    }
}


@media only screen and (max-width: 1668px) {
    .homeHeaderButtonDiv {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 130px;
    }

    .homeHeader {
        justify-content: center;
        margin-bottom: 110px;
    }

    .homeHeaderText {
        width: 70%;
    }

    .homeHeaderImage {
        margin-top: 230px;
        position: absolute;
        right: 0;
    }

    .homeHeaderText p {
        width: 90%;
    }




    .mailingDiv {
        padding-top: 30px;
    }

    .topFooter,
    .midFooter {
        width: 90%;
    }

    .bottomFooter {
        width: 90%;
    }

    .rates {
        width: 50%;
        justify-content: space-between;
    }

    .rate {
        margin: 30px;
    }
}

/* @media only screen and (max-width: 1480px) {
    .rates {
        display: none;
    }
} */

@media only screen and (max-width: 1350px) {
    .homeHeaderText {
        width: 90%;
    }

    .topFooter,
    .midFooter {
        width: 98%;
    }

    .bottomFooter {
        width: 98%;
    }

    .manu {
        align-items: flex-end;
        width: 45%;
        justify-content: flex-end;
    }

    .manuButton {
        position: fixed;
        transition: .25s;
        background-image: url(../../public/static/img/manuButton.png);
        width: 40px;
        height: 27px;
        background-repeat: no-repeat;
        background-size: 100%;
        transform: rotate(0deg);
        z-index: 111;
        right: 40px;
        top: 45px;
    }

    .manuElements p,
    .manuSlider {
        display: none;
    }

    .phoneManu {
        position: fixed;
        width: 20%;
        min-width: 250px;
        height: 100vh;
        right: -280px;
        transition: .3s;
        top: 0;
        background: linear-gradient(90deg, #616161 -238.95%, #141414 50%);
        z-index: 104;
        display: flex;

    }

    .manuFon {
        transition: .3s;
        top: 0;
        left: 0;
        z-index: -9;
        width: 100%;
        height: 100vh;
        background: rgba(20, 20, 20, 0.42);
        position: fixed;
        opacity: 0;
    }

    .phoneManuActive {
        right: 0;

    }

    .phoneManu div a {
        width: 90%;
        border-bottom: 1px solid #f9b21a20;
        display: flex;
        justify-content: center;

    }

    .phoneManu div a:last-child {
        border-bottom: 0;
    }

    .phoneManu div p {
        font-family: DaysOne;
        color: #F7D81A;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 30px;
        letter-spacing: 0.15em;
        margin: 1px;
        cursor: pointer;
        user-select: none;
        width: 90%;
        align-items: left;
    }

    .phoneManu div p:hover {
        color: #ffec71;
    }

    .phoneManu .phoneMailingManu {
        transition: .3s;
        width: 100%;
        height: 0;
        position: relative;
        top: 0;
        display: none;
        justify-content: right;
        align-items: flex-end;
    }

    .phoneManu .phoneMailingManu a {
        text-align: left;
        transition: .3s;
        opacity: 0;
    }

    .phoneManu .phoneMailingManu p {
        font-size: 15px;
        color: #F7D81A;
    }

    .phoneManu .phoneMailingManu p:hover {
        font-size: 15px;
        color: #ffec71;
    }

    .manuButtonActive {
        background-image: url(../../public/static/img/manuButtonActove.png);
        transform: rotate(180deg);
    }

    /* .reviewMobileButton{
        margin-top: 200px;
    } */
    .rates {
        width: 40%;
    }

    .rate {
        margin: 10px;
        width: 215px;
    }

    .headerCabinet {
        display: none;
    }

    .headerNumber p {
        min-width: auto;
    }


}

@media only screen and (max-width: 1100px) {
    .headerCabinet {
        margin-right: 110px;
    }

    .rates {
        width: 60%;
        margin: auto;
        margin-top: 80px;
    }

    .header {
        border: 0;
    }

    .homeHeaderButtonDiv .headerSocialLinks {
        margin: 0;
        width: 48%;
        margin-top: 30px;
        display: flex;
        min-width: 320px;
    }

    #headerSocialLinks {
        display: none;
    }

    .homeHeaderButtonDiv a {
        width: auto;
    }


    /* .header{
        justify-content: space-around;
    } */
    .headerNumber {
        width: 45%;
        min-width: 270px;
    }

    .phoneManu div {
        width: 100%;
        top: 120px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }


    .manuFonActive {
        z-index: 9;
        opacity: 1;
    }
}

@media only screen and (max-width: 1100px) {

    .homeHeader {
        flex-direction: column;
        align-items: center;
    }

    .homeHeaderImage img {
        width: 70%;
        margin-top: 80px;
    }

    .homeHeaderImage {
        position: relative;
        margin-top: 0;
        width: 100%;
    }

    .homeHeaderText {
        justify-content: center;
    }

    .homeHeaderText h4 {
        font-size: 45px;
    }

    .homeHeaderText h2 {
        font-size: 30px;
    }

    .homeHeaderText p {
        width: 100%;
    }

    .homeHeaderButtonDiv button {
        min-width: 320px;
        margin-bottom: 20px;
    }

    .homeHeader {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 900px) {
    .rates {
        width: 90%;
    }
}

@media only screen and (max-width: 700px) {
    .baksboLogo {
        top: calc(30vh - 75px);
    }

    .loadingText {
        top: calc(35vh - 75px);
    }

    .loaderAnimation {
        top: calc(49vh - 75px);
    }

    .rates {
        width: 100%;
    }

    .rate {
        margin: 10px 0;
        width: 175px;
        text-align: center;
    }

    .rate span {
        font-size: 12px;

    }

    .rate img {
        width: 12px;
    }

    .homeHeaderButtonDiv .headerSocialLinks {
        display: none;
    }

    #headerSocialLinks {
        display: flex;
    }

    .homeHeaderButtonDiv a {
        width: auto;
    }

    .headerNumber {
        width: 80%;
    }

    .manu {
        width: 10px;
    }

    .homeHeaderText {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .homeHeaderText h4 {
        font-size: 39px;
        letter-spacing: 2px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 20px;
    }

    /* .homeHeader{
        margin-top: 15vh;
    } */
    .homeHeaderText h2 {
        font-size: 30px;
        letter-spacing: 2px;
        text-align: center;
        line-height: 27px;
    }

    .homeHeaderText p {
        letter-spacing: 2px;
        text-align: center;
        text-shadow: 1px 0px 1px rgb(0 0 0);
    }

    .homeHeaderText {
        width: 95%;
    }

    .footerTopManu {
        display: none;
    }

    .topFooter {
        justify-content: space-between;
    }

    .bottomFooter a,
    .bottomFooter p {
        font-size: 9px;
        text-align: center;
    }

    .footerIINInfo,
    .footerSocialLinks {
        width: 50%;
    }

    .homeHeaderButtonDiv {
        width: 100%;
        align-items: center;
    }
}

@media only screen and (max-width: 450px) {
    .rates {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .headerNumber {
        width: 95%;
    }

    .headerNumber p {
        font-size: 18px;
    }

    .manuButton {
        right: 10px;
    }

    .homeHeaderButtonDiv button {
        min-width: 230px;
        margin-bottom: 30px;
    }

    .homeHeaderButtonDiv a {
        width: 100%;
    }

    .headerSocialLinks {
        width: 230px;
    }

    .homeHeaderText h4 {
        font-size: 7vw;
        max-width: 100%;
    }

    .homeHeaderText h2 {
        font-size: 5.5vw;
    }

    .homeHeaderText p {
        font-size: 14px;
        width: 95%;
        text-align: left;
    }

    .mailingApp {
        width: 190px;
        height: 278px;
    }

    #selfMailing {
        margin-bottom: 30px;
        font-size: 23px;
        margin-top: 120px;
    }

    .bottomFooter {
        margin-top: 20px;
        align-items: center;
    }

    .bottomFooter p {
        text-align: left;
    }

}