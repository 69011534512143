.trainingDiv {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin: 50px 0;
}

.traningKeys {
    border-radius: 30px;
    width: 200px;
    height: 270px;
    background: linear-gradient(210.19deg, rgba(146, 135, 200, 0) 0%, rgba(104, 93, 169, 0) 100%);
    background-image: url(../../../../public/static/img/appBorder.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.3s;
    border: 1px solid #b15bbe00;
    margin: 10px;
}

.traningKeys p b {
    color: #F7D81A;
}

.traningKeys p {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    text-align: center;
    letter-spacing: 0.1em;
    color: white;
}

.traningKeys a {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    text-align: center;
    letter-spacing: 0.1em;
    background: linear-gradient(329.81deg, #F9B21A 0%, rgba(245, 249, 26, 0.78) 64.58%, rgba(245, 249, 26, 0.8) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.trainingImgDiv {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.traningKeys img {
    width: 80px;
    position: absolute;
    transition: 0.2s;
}

.traningKeys .trainingImg2d {
    opacity: 1;
    transform: rotate(0deg);
}

.traningKeys .trainingImg3d {
    opacity: 0;
    transform: rotate(180deg);
}

.traningKeys:hover .trainingImg2d {
    transform: rotate(180deg);
    opacity: 0;
}

.traningKeys:hover .trainingImg3d {
    opacity: 1;
    transform: rotate(0deg);
}

.traningKeys:hover {
    box-shadow: 2px 2px 30px 0px rgba(247, 218, 29, 0.15);
}

.trainingDiv .selfMailingInfo {
    width: 90%;
}

.trainingDiv .selfMailingInfoButtonSection a{
    margin: 0 2%;
}
.trainingDiv .selfMailingInfoButtonSection {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;
    gap: 30px;
}

.trainingDiv .selfMailingInfoButtonDiv {
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
}

.trainingDiv .selfMailingInfoButtonDiv div {
    margin: 0 15px;
}

.selfMailingInfoBuySession {
    width: 20%;
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.selfMailingInfoBuySession p {
    margin: 0;
    height: 8.5vw;
    margin-top: 8px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F7D81A;
    background: linear-gradient(210.19deg, rgba(159, 233, 249, 0.5) 0%, rgba(14, 171, 213, 0.5) 100%);
    border-radius: 20px;
    border: 1px solid #F9B21A;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 12px;
}

.selfMailingInfoBuySession a {
    color: white;
    font-size: 14px;
    text-transform: none;
    transition: .25s;
    background: #F7D81A;
    border: 0.5px solid #14141485;
    /* box-shadow: 0px 20px 60px rgb(247 216 26 / 20%), inset -2px -4px 4px rgb(255 255 255 / 25%), inset 2px 4px 4px rgb(255 255 255 / 25%); */
    border-radius: 12px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #141414;
    width: 100%;
    padding: 3px 0;
    text-align: center;
    margin-top: 7px;
    height: 50px;
}

.selfMailingInfoTraningButton {
    width: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.selfMailingInfoTraningButtonSession {
    width: 13vw;
}

.selfMailingInfoTraningButtonSession div {
    width: 100%;
}

.trainingDiv .selfMailingInfoTraningButton a {
    transition: .25s;
    background: #F7D81A;
    border: 0.5px solid #141414;
    /* box-shadow: 0px 20px 60px rgb(247 216 26 / 20%), inset -2px -4px 4px rgb(255 255 255 / 25%), inset 2px 4px 4px rgb(255 255 255 / 25%); */
    border-radius: 12px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #141414;
    width: 100%;
    padding: 10px 0;
    text-align: center;
    margin-top: 20px;

}
.trainingDiv .selfMailingInfoTraningButtonBuyAccount {
    margin: 0 2%;
}
.trainingDiv .selfMailingInfoTraningButtonBuyAccount a{
    font-size: 11px;
}

.trainingDiv .selfMailingInfoTraningButtonSession a {
    font-size: 12px;
}

.trainingDivHidden p {
    color: #ffffff;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    margin-top: 40px;
    margin-bottom: 15px;
    margin-top: 300px;
    text-align: center;
}

.trainingDivHidden {
    width: 100%;
    height: 100%;
    background-color: #000000b9;
    position: absolute;
    z-index: 6;
    display: flex;
    justify-content: center;
    backdrop-filter: blur(2px);
}

.trainingDiv .selfMailingInfoTraining p {
    color: white;
    font-size: 15px;
    text-transform: none;
    margin-bottom: 40px;
}

@media only screen and (max-width: 1300px) {
    .selfMailingInfoTraningButton {
        width: 15vw;
    }
}

@media only screen and (max-width: 1050px) {
    .selfMailingInfoButtonDiv {
        flex-direction: column;
    }

    .selfMailingInfoButtonDiv div {
        width: 80%;
        margin: 10px 0;
        min-width: 250px;
    }

    .trainingDiv .selfMailingInfoButtonDiv div {
        width: 100%;
    }

    .trainingDiv .selfMailingInfoTraningButton a {
        margin-bottom: 30px;
        margin-top: 5px;
    }

    .selfMailingInfoBuySession a {
        margin-top: 20px;
    }

    .selfMailingInfoTraningButton {
        min-width: 250px;
    }

    .selfMailingInfoBuySession {
        width: 14vw;
        min-width: 250px;
        align-items: center;
        height: auto;
    }
    .trainingDiv .selfMailingInfoButtonSection{
        width: 100%;
        gap: 0;
    }
}

@media only screen and (max-width: 1000px) {
    .trainingDiv .selfMailingInfoButtonDiv {
        width: 100%;
    }

    /* .selfMailingInfoTraningButton {
        min-width: 135px;
    }
     */
}


@media only screen and (max-width: 500px) {
    .trainingDivHidden p {
        font-size: 16px;
    }

    .trainingDiv .selfMailingInfoTraining p {
        text-align: left;
    }
}