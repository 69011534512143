.checkEmailDiv button {
    border: 0;
    width: 175px;
    height: 58px;
    color: #141414;
    background: linear-gradient(-45deg, #F9B21A, rgba(245, 249, 26, 0.78), rgba(245, 249, 26, 0));
    cursor: pointer;
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    transition: .3s;
    border-radius: 11px;
    margin-bottom: 10px;
}
.checkEmailDiv button:hover {
    color: #F7D81A;
    background: linear-gradient(-45deg, rgba(249, 178, 26, 0), rgba(245, 249, 26, 0.0), rgba(245, 249, 26, 0));
    background-image: url(../../../../public/static/img/buttonBorder.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;
    
}

.checkEmailDiv button:disabled {
    opacity: 0.8;    
    filter: contrast(0);
}
.forgetPassword input{
    width: 80%;
    font-size: 25px;
}
.checkEmailDiv .errorText{
    margin-bottom: 15px; 
}
.forgetChange{
    margin-top: -50px;
}
.forgetChange .nameInput{
    width: 80%;
    font-size: 21px;
}