.blogItemDiv {
    max-width: 1000px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    margin-top: 120px;
}

.blogItemDiv h1 {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;

    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    margin-top: 5px;
}

.blogItemDiv hr {
    width: 100%;
}

.blogItemDiv img {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    margin-top: 30px;
}

.blogItemDiv p {    
    font-size: 16px;
    color: #F4F4F4;
}

@media only screen and (max-width: 900px) {
    .blogItemDiv h1 {
        margin-left: 5px;
    }

    .blogItemDiv {
        margin-top: 50px;
        max-width: 95%;
    }
}