.profileMailing {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 42px;
}

.selfMailingItem {
    width: 90%;
    max-width: 1350px;
    border-radius: 50px;
    border: 1px solid #f9b21a71;
    margin: 18px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px 0;
}

.selfMailingItemCover {
    width: 70px;
    height: 100px;
    position: absolute;
    background: #53d0ec99;
    box-shadow: inset 2px 4px 4px rgba(255, 255, 255, 0.25), inset -2px -4px 4px rgba(255, 255, 255, 0.5);
    border-radius: 0px 0px 16px 16px;
    margin-top: -50px;
    margin-left: 50px;
    z-index: -5;
}

.whatsappItem {
    background: linear-gradient(210.19deg, rgba(147, 247, 172, 0.2) 0%, rgba(13, 211, 55, 0.2) 100%);
    box-shadow: 0px 20px 40px rgba(13, 211, 55, 0.1), inset -2px -4px 4px rgba(0, 0, 0, 0.25), inset 2px 4px 4px rgba(0, 0, 0, 0.25);

}

.telegramItem {
    background: linear-gradient(210.19deg, rgba(159, 233, 249, 0.2) 0%, rgba(14, 171, 213, 0.2) 100%);
    box-shadow: 0px 20px 40px rgba(153, 213, 229, 0.1), inset -2px -4px 4px rgba(0, 0, 0, 0.25), inset 2px 4px 4px rgba(0, 0, 0, 0.25);
}

.selfMailingInfo {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 5%;
}

.selfMailingInfoButtons {
    width: 100%;
}

.selfMailingInfoButtons p {
    color: #FDF1A9;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 15px;
}

.selfMailingInfoButtonDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selfMailingInfoButtonDiv div p {
    margin: 0;
    height: 30px;
    margin-top: 8px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F7D81A;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selfMailingInfoButtonDiv div img {
    filter: sepia(50) brightness(10);
}

.selfMailingInfoButtonDiv div {
    width: 10vw;
    height: 8.5vw;
    background: linear-gradient(210.19deg, rgba(159, 233, 249, 0.5) 0%, rgba(14, 171, 213, 0.5) 100%);
    border-radius: 20px;
    border: 1px solid #F9B21A;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.selfMailingRigth {
    width: 40%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;

}

.selfMailingInfoImg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.selfMailingInfoImg img {
    width: 60%;
    margin-bottom: 50px;
}

.selfMailingInfoMessage {
    width: 200px;
    height: 165px;
    background-image: url(../../../../public/static/img/cabinet/messageFon.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    margin-right: 27vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-right: 30px;
    z-index: 4;
}

.selfMailingInfoMessage span {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
    color: #C41617;
    /* background: linear-gradient(231.86deg, #C41617 28.89%, rgba(255, 85, 85, 0.78) 75.53%, rgba(222, 58, 57, 0.8) 100.02%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    margin: 0;
}

.selfMailingInfoMessage p {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    color: #000;
    margin: 0;
    margin-bottom: 20px;
}

.selfMailingInfoMessage h3 {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
    color: #c41616ce;
    /* background: linear-gradient(231.86deg, #C41617 28.89%, rgba(255, 85, 85, 0.78) 75.53%, rgba(222, 58, 57, 0.8) 100.02%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent; */
    margin: 0;
}

.selfMailingInfoText {
    width: 100%;
    z-index: 5;
    
}

.selfMailingInfoHeader {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    letter-spacing: 0.1em;
    margin: 0;
    text-transform: uppercase;
    color: #fff;
}

.line {
    width: 90%;
    border-top: 1px solid #F7D81A;
}

.selfMailingInfoSpan {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.selfMailingInfoSpan b {
    color: #F7D81A;
}

.selfMailingInfoSpan span {
    line-height: 16px;
    max-width: 500px;
}

.selfMailingButtons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    flex-direction: column;
}

.selfMailingButtons p {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.1em;
    color: #cf2929;
}

.selfMailingButtons span b {
    margin: 0;
    font-size: 16px;
}

.selfMailingButtons span {
    color: white;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.1em;
    margin-bottom: 30px;
}

.selfMailingButtons b {
    color: #F7D81A;
    margin-left: 20px;
}

.selfMailingButtons i {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.1em;
    text-decoration-line: line-through;
    color: #fbe138c9;
}

.selfMailingButtons a {
    width: 100%;
    text-align: center;
}
.selfMailingButtons button {
    border: 1px solid #F7D81A00;
    background-color: #F7D81A;
    color: #141414;
    transition: .2s;
    cursor: pointer;
    width: 80%;
    max-width: 330px;
    padding: 13px 0;
    border-radius: 10px;
    font-size: 16px;
}

.selfMailingButtons .demo {
    border: 1px solid #F7D81A;
    background-color: transparent;
    color: #F7D81A;
    margin-top: 12.5px;
    width: auto;
    padding: 7px 13px;
    font-size: 14px;
}
.selfMailingButtons .demo_tarif {
    border: 1px solid #F7D81A;
    background-color: transparent;
    color: #F7D81A;
    margin-top: 12.5px;
    width: auto;
    padding: 7px 13px;
    font-size: 14px;
    width: 80%;
}
.selfMailingButtons .demo_tarif:hover {
    border: 1px solid #F7D81A00;
    background-color: #F7D81A;
    color: #000000 !important;
}
.selfMailingButtons .demo:hover {
    border: 1px solid #F7D81A00;
    background-color: #F7D81A;
    color: #000000 !important;
}
.selfMailingButtons button:hover {
    color: #F7D81A;
    background-color: transparent;
    border: 1px solid #F7D81A;
}

.whatsappItem .selfMailingButtons button:hover {
    color: #F7D81A;
}

.telegramItem .selfMailingButtons button:hover {
    color: #F7D81A;
}

.comingSoonDiv {
    width: calc(90%);
    max-width: 1350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-bottom: 120px;
}
.selfMailingLK .comingSoonDiv{
    margin-bottom: 0;

}

.comingSoon {
    width: 100%;
    border-radius: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #f9b21a71;
    height: 120px;
    margin: 15px;
    transition: 0.2s;
}

.comingSoon:hover {
    opacity: 0.8;
    filter: contrast(0.5);
}

.comingSoon img {
    width: 80px;
}

.comingSoon p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    background: linear-gradient(252.81deg, #F9B21A 0.14%, rgba(245, 249, 26, 0.78) 64.57%, rgba(245, 249, 26, 0.8) 99.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.comingSoon span {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    background: linear-gradient(231.86deg, #C41617 28.89%, rgba(255, 85, 85, 0.78) 75.53%, rgba(222, 58, 57, 0.8) 100.02%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.payBlock{
    position: fixed;
    max-width: 750px;
    width: 95%;
    /* height: 50vh; */
    top: 25vh;
    background-color: #141414;
    background-size: 30%;
    border-radius: 20px;
    box-shadow: inset -2px -4px 4px rgb(255 255 255 / 40%), inset 2px 4px 4px rgb(255 255 255 / 40%);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    z-index: 8;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px 6px;
    padding-bottom: 15px;
}
.payBlock .methodsDiv{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

}
.payBlock .methods{
    width: 40%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    background: rgb(247,216,26);
    background: radial-gradient(circle, rgba(247,216,26,0.2) 5%, rgba(247,216,26,0.1) 30%, rgba(247,216,26,0) 60%, rgba(247,216,26,0) 100%);
    transition: 0.3s;
}
.payBlock .methods:hover{    
    background: radial-gradient(circle, rgba(247,216,26,0.25) 5%, rgba(247,216,26,0.15) 30%, rgba(247,216,26,0) 60%, rgba(247,216,26,0) 100%);
}
.payBlock .methods img{
    width: 100%;
}
.payBlock .methods p{
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}
.payBlock p{
    text-align: center;
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.1em;
    margin: 0;
    margin-top: 20px;
    color: #fff;
}
.payBlockFon{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
    cursor: pointer;
    backdrop-filter: blur(3px);
}

@media only screen and (max-width: 1620px) {
    .selfMailingInfoText {
        width: 80%;
    }

    .selfMailingInfoMessage {
        margin-right: 29vw;
    }

    .selfMailingButtons {
        margin-top: 50px;
    }

    .selfMailingItem {
        width: 90%;
    }

    .selfMailingInfoSpan {
        font-size: 13px;
        margin-top: 15px;
    }

    .selfMailingInfoSpan span {
        line-height: 16px;
    }

    .selfMailingButtons p {
        font-size: 23px;
    }

}

@media only screen and (max-width: 1360px) {
    .selfMailingItem {
        border-radius: 40px;
    }

    .selfMailingInfo {
        padding-top: 20px;
    }

    .selfMailingButtons b {
        margin-left: 5px;
    }

    .selfMailingButtons p {
        font-size: 20px;
    }

    .selfMailingInfoSpan {
        font-size: 12px;
        margin-top: 10px;
    }

    .selfMailingRigth {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 1300px) {
    .selfMailingItem {
        width: 90%;
    }

    .selfMailingInfoButtonDiv div {
        width: 14vw;
        height: 11vw;
    }

    .selfMailingInfo {
        padding-top: 25px;
    }

    .selfMailingButtons p {
        font-size: 24px;
    }

    .selfMailingInfoSpan {
        margin-top: 20px;
        font-size: 14px;
    }

    .selfMailingInfoSpan span {
        line-height: 16px;
    }
}


@media only screen and (max-width: 1000px) {
    .selfMailingItem {
        flex-direction: column;
    }

    .selfMailingInfo {
        width: 90%;
        padding: 0;
        padding-left: 5%;
    }

    .selfMailingInfoText {
        width: 100%;
    }

    .selfMailingRigth {
        width: 100%;
    }

    .selfMailingInfoImg img {
        width: auto;
        height: 150px;
        margin-left: 20px;
    }

    .selfMailingInfoMessage {
        position: relative;
        margin-right: -34px;
        background-size: 220px;
    }

    .selfMailingInfoImg {
        flex-direction: row-reverse;
    }

    .selfMailingRigth {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 30px;
    }

    .selfMailingButtons {
        width: 50%;
        margin: 0;
    }

    .selfMailingInfoImg {
        width: 50%;
    }

    .selfMailingInfoButtonDiv {
        width: 70%;
    }

    .selfMailingItemCover {
        width: 50px;
        height: 103px;
        border-radius: 0px 0px 10px 10px;
    }

    .selfMailingInfoButtonDiv div {
        min-height: 100px;
        min-width: 135px;
        margin: 0 10px;
    }
}

@media only screen and (max-width: 850px) {
    .selfMailingButtons {
        width: 45%;
    }

    .selfMailingButtons p {
        font-size: 20px;
    }
    .selfMailingInfoButtonDiv{
        width: auto;
        justify-content: flex-start;
    }
}

@media only screen and (max-width: 750px) {
    .selfMailingRigth {
        flex-direction: column;
    }

    .selfMailingInfoImg {
        width: 90%;
    }

    .selfMailingButtons {
        width: 90%;
        justify-content: flex-start;
    }

    .selfMailingInfoMessage {
        margin-right: -10px;
    }
}

@media only screen and (max-width: 600px) {
    .payBlock{
        top: 60px;
        padding-bottom: 30px;
    }
    .payBlock .methodsDiv{
        flex-direction: column;        
    }
    .payBlock .methods {
        width: 65%;
        height: 260px;
    }
    .selfMailingInfoHeader {
        font-size: 35px;
    }
    .comingSoon{
        border-radius: 40px;
        flex-direction: column;
        height: auto;
        padding: 20px 0;
    }

    .selfMailingInfoSpan {
        font-size: 13px;
        margin-top: 10px;
    }

    .selfMailingInfoSpan span {
        line-height: 16px;
    }

    .selfMailingButtons p {
        font-size: 20px;
    }

    .selfMailingItem {
        border-radius: 35px;
    }

    .selfMailingInfoText {
        width: 80%;
    }
    .selfMailingButtons p{
        font-size: 25px;
    }
    .selfMailingInfoButtonDiv{
        width: 100%;
        justify-content: center;        
    }
    .selfMailingInfoButtonDiv div{
        width: 30%;
        min-width: auto;
        border-radius: 12px;
    }
    .selfMailingInfoButtonDiv div p{
        font-size: 12px;
    }
    .selfMailingItemCover{
        margin-left: 35px;
    }
    .selfMailingInfoMessage {
        margin-right:-60px
    }
    .selfMailingInfoImg img{
        margin-bottom: 0;        
        height: 130px;
    }
    .selfMailingInfoImg {
        align-items: center;
    }
}

@media only screen and (max-width: 500px) {
    .selfMailingInfoImg img{
        height: 130px;
    }
    .selfMailingInfoMessage {
        margin-right: -30px;
    }
    .selfMailingItem {
        border-radius: 25px;
    }
    .selfMailingItemCover{
        margin-left: 25px;
    }
    .selfMailingInfoImg {
        width: 100%;
    }

    .line {
        width: 70%;
    }

    .selfMailingInfoText {
        width: 90%;
    }

    .selfMailingInfo {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .selfMailingButtons {
        width: 100%;
        justify-content: center;
        flex-direction: column;
    }

    .selfMailingButtons p {
        font-size: 23px;
    }

    .selfMailingButtons button {
        width: 242px;
        margin-top: 5px;
    }
    .selfMailingButtons .demo_tarif {
        width: 242px;
        font-size: 12px;
    }
    .comingSoon p {
        font-size: 16px;
    }

    .comingSoon span {
        font-size: 15px;
    }

    .comingSoon img {
        width: 50px;
    }

    .selfMailingRigth{
        justify-content: center;
    }

    .selfMailingInfoButtons p{
        text-align: center;
        font-size: 16px;
    }
    .selfMailingInfoImg img{
        margin-left: -15px;
    }

}

@media only screen and (max-width: 450px) {
    .selfMailingInfoMessage{
        height: 140px;
        padding-right: 15px;
        background-size: 180px;
    }
    .selfMailingInfoMessage h3{
        font-size: 18px;
    }.selfMailingInfoImg img{
        height: 110px;
    }
    .selfMailingInfoMessage{
        margin-right: -30px;
    }
}
@media only screen and (max-width: 400px) {
    
    .selfMailingItem {
        width: 95%;
        margin: 0;
    }    
    .selfMailingInfoImg img{
        height: 90px;
        margin-left: -25px;
    }
    .selfMailingInfoMessage{
        height: 125px;
    }
    .selfMailingButtons p{
        font-size: 18px;
    }
    .selfMailingInfoMessage{
        scale: 0.8;
    }
}

@media only screen and (max-width: 350px) {
    .selfMailingInfoMessage{    
        margin-left: -30px;
    }

    .selfMailingInfoHeader{
        font-size: 30px;
        text-align: center;
    }
}

@media only screen and (max-width: 300px) {
    .selfMailingButtons p{
        text-align: center;
    }
    .selfMailingInfoButtonDiv div{
        min-width: 230px;
    }
    .selfMailingInfoHeader{
        text-align: center;
        font-size: 23px;
    }
}