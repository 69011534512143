.botMarketingDiv {
    width: 85%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: wrap;
}

.botMarketingItem {
    position: relative;
    width: 28%;
    padding: 0 2%;
    height: 390px;
    border-radius: 15px;
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 30px;
}

.botMarketingItem:first-child {
    width: 62%;
}

.botMarketingItem a {
    position: absolute;
    width: 38px;
    height: 38px;
    background-color: #F7D81A;
    border-radius: 50%;
    right: 20px;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    border: 2px solid #F7D81A;
    display: none;
}

.botMarketingItem a:hover {
    background-color: #F7D81A00;

}

.botMarketingItem img {
    filter: grayscale(100%) brightness(200%) invert(1);
    transition: 0.1s;
}

.botMarketingItem a:hover img {
    filter: grayscale(0%) brightness(100%) invert(0);

}

.botMarketingItem p {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #F7D81A;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 50px;
}

.botMarketingItem span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #fff;
    margin-bottom: 20px;
}

.botMarketingItem li {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.botMarketingItem ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.botMarketingItem ul li::before {
    content: "\2022";
    color: #F7D81A;
    font-weight: bold;
    display: inline-block;
    width: 20px;
    margin-left: 10px;
    transform: scale(1.5);
    height: 21px;
}
.splide__list{
    align-items: center;
}
.botMarketingSlide .splide {
    padding-left: 0;
    padding-right: 0;
}

.botMarketingSlide .splide__arrow svg,
.botMarketingSlide .splide__arrow svg:hover {
    fill: #F7D81A;
}

.botMarketingSlide .splide__pagination__page:hover,
.botMarketingSlide .splide__pagination__page.is-active:hover,
.botMarketingSlide .splide__pagination__page.is-active {
    background: #F7D81A;
}

.botMarketingKase img {
    width: auto;
    height: auto;
    margin: 0;
    margin-right: 10px;
}

.botMarketingSlide {
    display: none;
}

@media screen and (max-width:1450px) {
    .botMarketingItem {
        height: auto;
        padding-bottom: 30px;
    }
}

@media screen and (max-width:1200px) {
    .botMarketingDiv {
        width: 95%;
    }
}

@media screen and (max-width:1000px) {
    .botMarketingItem li {
        font-size: 14px;
        line-height: 17px;
    }
}

@media screen and (max-width:900px) {
    .botMarketingItem {
        display: none;
    }

    .botMarketingSlide {
        display: block;
    }

    .botMarketingKase {
        width: 60%;
        min-width: 460px;
        min-height: 200px;
        margin: auto;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(2px);
        border-radius: 15px;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
        padding: 30px 20px;
    }

    .botMarketingKase p {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: #F7D81A;
        margin: 0;
        margin-bottom: 20px;
        margin-top: 0;
    }

    .botMarketingKase span {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: #fff;
        margin-bottom: 20px;
    }

    .botMarketingKase li {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    .botMarketingKase ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
        padding-left: 20px;
    }

    .botMarketingKase ul li::before {
        content: "\2022";
        color: #F7D81A;
        font-weight: bold;
        display: inline-block;
        width: 20px;
        margin-left: 10px;
        transform: scale(1.5);
        height: 21px;
        position: absolute;
        margin-left: -10px;
    }

}

@media screen and (max-width:640px) {
    .botMarketingKase {
        min-width: 220px;
        height: auto;
        margin-bottom: 30px;
    }

    .botMarketingKase p {
        font-size: 18px;
        margin: 0;
    }

    .botMarketingKase span {
        font-size: 16px;
        margin: 0;
    }

    .botMarketingKase li {
        font-size: 14px;
        margin-bottom: 10px;
    }
}