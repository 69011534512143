/* .botPriceDiv {
    width: 100%;
    position: relative;
}

.botPriceItem {
    width: 85%;
    margin: auto;
}

.botPriceItem:nth-child(6),
.botPriceItem:nth-child(4) {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.botPrice {
    width: 1000px;
    height: 440px;
    -webkit-mask-image: url(../../../public/static/img/bot/priceFonMask.png);
    mask-image: url(../../../public/static/img/bot/priceFonMask.png);
    -webkit-mask-repeat: no-repeat;
    mask-size: 100%;
    mask-repeat: no-repeat;
    background: url(../../../public/static/img/bot/priceFon.png);
    background-size: 100%;
    backdrop-filter: blur(6px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botPriceFon1 {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: -350px;
    z-index: -1;
    opacity: 0.4;
}

.botPriceFon2 {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: -300px;
    z-index: -1;
    opacity: 0.4;

}

.botPriceInfo {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    width: 37%;
    height: 54%;
}

.botPriceInfo h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    display: flex;
    align-items: center;
    color: #F7D81A;
    margin: 0;
    margin-bottom: 15px;
}

.botPriceInfo h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 37px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin: 0;
    margin-bottom: 30px;
}

.botPriceInfo span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    display: flex;
    color: #FFFFFF;
    margin-bottom: 25px;
}

.botPriceInfo span:last-child {
    margin-bottom: 0;
}

.botPriceInfo p {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding: 24px 32px;
    background-image: url(../../../public/static/img/bot/circleBorder.png);
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 0;
    margin-bottom: 23px;
}

.botPriceBonus {
    justify-content: flex-end;
}

.botPriceButtonDiv {
    width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botPriceButtonDiv span {
    cursor: pointer;
    padding: 17px 76px;
    background-color: #F7D81A;
    border: 1px solid #F7D81A;
    color: black;
    font-size: 16px;
    border-radius: 10px;
    margin-top: 40px;
    margin-bottom: 90px;
    transition: 0.3s;
}

.botPriceButtonDiv a:hover {
    color: #F7D81A;
    background-color: #F7D81A00;
    backdrop-filter: blur(4px);
}
.botPriceInfo  .mobileSpans{
    display: none;
}
@media screen and (max-width:1400px) {
    .botPrice {
        width: 900px;
        height: 396px;
    }

    .botPriceButtonDiv {
        width: 900px;
    }

    .botPriceInfo h5 {
        font-size: 29px;
    }

    .botPriceInfo h2 {
        font-size: 30px;
    }

    .botPriceInfo p {
        font-size: 20px;
        padding: 15px 23px;
    }

    .botPriceInfo span {
        font-size: 18px;
    }
}

@media screen and (max-width:1200px) {
    .botPriceItem {
        width: 95%;
    }
}

@media screen and (max-width:1000px) {
    .botPriceInfo {
        width: 42%;
    }

    .botPriceItem {
        width: 95%;
    }

    .botPrice {
        width: 600px;
        height: 264px;
    }

    .botPriceButtonDiv {
        width: 600px;
    }

    .botPriceInfo h5 {
        font-size: 22px;
        margin-bottom: 15px;
    }

    .botPriceInfo h2 {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .botPriceInfo p {
        font-size: 18px;
        padding: 15px 23px;
        margin-bottom: 10px;
    }

    .botPriceInfo span {
        font-size: 15px;
        margin-bottom: 9px;
    }

    .botPriceButtonDiv span {
        padding: 12px 50px;
        margin-top: 20px;
        margin-bottom: 40px;
    }
}

@media screen and (max-width:700px) {

    .botPriceItem:nth-child(6),
    .botPriceItem:nth-child(4),
    .botPriceItem {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .botPriceInfo {
        width: 42%;
    }

    .botPriceItem {
        width: 95%;
    }

    .botPrice {
        width: 500px;
        height: 220px;
        -webkit-mask-repeat: no-repeat;
        mask-size: 100%;
        mask-repeat: no-repeat;
        background-size: 100%;
        backdrop-filter: blur(6px);
        position: relative;
    }

    .botPriceButtonDiv {
        width: 400px;
    }

    .botPriceInfo h5 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .botPriceInfo h2 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .botPriceInfo p {
        font-size: 18px;
        padding: 15px 23px;
        margin-bottom: 30px;
    }

    .botPriceInfo span {
        font-size: 10px;
        margin-bottom: 3px;
    }

    .botPriceButtonDiv span {
        padding: 9px 35px;
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 15px;
        border-radius: 7px;
    }
    .botPriceFon1{
        margin-top: -150px;
    }
    .botPriceFon2{
        bottom: 180px;
    }
}

@media screen and (max-width:550px) {
    .botPriceInfo {
        width: 41%;
        padding-left: 2%;
        justify-content: center;
        height: 60%;
    }

    .botPriceItem {
        width: 95%;
    }

    .botPrice {
        width: 300px;
        height: 180px;
        -webkit-mask-image: url(../../../public/static/img/bot/priceFonMaskMobile.png);
        mask-image: url(../../../public/static/img/bot/priceFonMaskMobile.png);
        background: url(../../../public/static/img/bot/priceFonMobile.png);
        -webkit-mask-repeat: no-repeat;
        mask-size: 100%;
        mask-repeat: no-repeat;
        background-size: 100%;
        backdrop-filter: blur(4px);
        position: relative;
    }

    .botPriceButtonDiv {
        width: 400px;
    }

    .botPriceInfo h5 {
        font-size: 15px;
        margin-bottom: 15px;
        white-space: nowrap;
    }

    .botPriceInfo h2 {
        font-size: 14px;
        margin-bottom: 13px;
    }

    .botPriceInfo p {
        font-size: 14px;
        padding: 13px 17px;
        margin-bottom: 7px;
        background-repeat: no-repeat;
        margin-left: 30px;
    }

    .botPriceInfo .mobileSpans {
        display: flex;
        font-size: 9px;
        margin-bottom: 5px;
    }
    .botPriceInfo span {
        display: none;
    }

    .botPriceButtonDiv span {
        padding: 9px 35px;
        margin-top: 15px;
        margin-bottom: 30px;
        font-size: 15px;
        border-radius: 7px;
    }
} */


/* ------------------------------------------------ */



.newBotPriceDiv .botTarif h5{
    font-size: 100px;
}
.newBotPriceDiv .phoneeithwifi{
    /* transform: rotate(15deg); */
    margin-top: 150px;
    max-width: 300px;
}
.newBotPriceDiv .botTarif p{
    font-size: 84px;    
    margin-top: -80px;
}
.newBotPriceDiv .botTarif a{
    margin-top: 45px;
}
.botTarif svg{
    position: absolute;
    margin-top: 50px;
    margin-left: 450px;
    scale: 0.8;
    transform: rotate(30deg) rotateY(180deg);
}
@media (max-width:600px) {
    .newBotPriceDiv .botTarifElipse:nth-child(4){
        top: 110px;
        right: 5px;
    }
    .botTarif svg{
        display: none;
    }
}
@media (max-width:500px) {
    .newBotPriceDiv .botTarif h5{
        font-size: 40px !important;
    }
    .newBotPriceDiv .phoneeithwifi{
        margin-top: 50px;
    }
    .newBotPriceDiv .botTarif p{
        font-size: 40px !important;
        line-height: 30px !important;

    }
}