.authorizationPage {
    width: 100%;
    margin: auto;
    min-height: 100vh;
    background: linear-gradient(100deg, rgba(20, 20, 20, 1) 0%, rgba(21, 20, 20, 1) 64%, rgba(54, 36, 34, 1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.authorizationForm {
    width: 500px;
    min-height: 600px;
    background: url(../../../public/static/img/formFon.png);
    background-size: 30%;
    border-radius: 40px;
    box-shadow: inset -2px -4px 4px rgba(255, 255, 255, 0.4), inset 2px 4px 4px rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(7px);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin: 50px 0;
}

.authorizationForm img {
    width: 26px;
}

.authorizationForm h2 {
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F7D81A;
    margin: 0;
    margin-top: 50px;
    text-align: center;
}

.authorizationForm p b {
    color: #F7D81A;
}

.authorizationForm p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    max-width: 85%;
    text-align: center;
}

.authorizationForm .form input::placeholder {
    opacity: 1;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;
    color: #c4c4c4;
}

.authorizationForm .form input:focus {
    outline: none;
}

.authorizationForm .form input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.authorizationForm .form button {
    border: 0;
    width: 175px;
    height: 58px;
    color: #141414;
    background: linear-gradient(-45deg, #F9B21A, rgba(245, 249, 26, 0.78), rgba(245, 249, 26, 0));
    
    cursor: pointer;
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    transition: .3s;
    border-radius: 11px;
    margin-top: 25px;
}

.authorizationForm .form button:hover {
    color: #F7D81A;
    background: linear-gradient(-45deg, rgba(249, 178, 26, 0), rgba(245, 249, 26, 0.0), rgba(245, 249, 26, 0));
    background-image: url(../../../public/static/img/buttonBorder.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;
    
}

.authorizationForm .form button:disabled {
    opacity: 0.8;
    filter: contrast(0);

}


.authorizationForm .form input {
    background: 0;
    border: 0px;
    width: 100%;
    margin: 25px 0;
    border-bottom: 1px solid #F4F4F4;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;
    color: #ffffff;
}

.authorizationForm .form {
    width: 85%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.authorizationForm .form a {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1em;
    color: #F7D81A;
    text-align: center;
    margin-top: 12px;
}

.authorizationForm .form span {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    text-align: center;
    margin-top: 45px;
}

.authorizationForm .form span a {
    font-weight: bold;
    color: #F4F4F4;

}

.authorizationForm .form .plusNumberInput {
    display: flex;
    width: 100%;
}

.authorizationForm .form div span {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;
    color: #ffffff;
    position: absolute;
    margin-left: -81%;
    display: none;
}

.authorizationForm .form div .numberInput,
.authorizationForm .form .nameInput,
.authorizationForm .form .companyInput,
.authorizationForm .form .emailInput {
    width: 95%;
    padding-left: 5%;
}

.authorizationForm .errorText {
    color: #ff6060;
}

.checkEmailDiv .errorText {
    color: #ff6060;
    margin: 0;
    margin-bottom: 15px;
}

.authorizationForm .form .PhoneInputInput:focus {
    outline: none;
    padding-left: 50px;
}

.authorizationForm .form .PhoneInputInput::placeholder {
    opacity: 1;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;
    color: #c4c4c4;
    margin-left: 40px;
}

.authorizationForm .form .PhoneInputInput {
    transition: .5s;
    background: 0;
    border: 0px;
    width: 100%;
    margin: 25px 0;
    border-bottom: 1px solid #F4F4F4;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;
    color: #ffffff;
    width: 120%;
    padding-left: 5%;
    /* margin-left: -10.5%; */
}

.form section {
    width: 100%;
}

section .PhoneInputCountry {
    margin-left: 10px;
    position: absolute;
    height: 75px;
}

section .PhoneInputCountrySelectArrow {
    display: none;
}


.authorizationForm .form .formdiv span {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.1em;
    color: #ffffff;
    position: absolute;
    margin-left: -81%;
    display: none;
}

.authorizationForm .form .formdiv .numberInput,
.authorizationForm .form .nameInput,
.authorizationForm .form .companyInput,
.authorizationForm .form .emailInput {
    width: 95%;
    padding-left: 5%;
}

.bellDiv {
    width: 700px;
    background-image: url(../../../public/static/img/bell.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 150px;
    height: 150px;
    margin-bottom: -100px;
    z-index: -1;
}

.messagesDiv {
    width: 650px;
    background-image: url(../../../public/static/img/messages.png);
    background-repeat: no-repeat;
    background-size: 150px;
    height: 150px;
    background-position: left;
    margin-top: -100px;
    z-index: -1;
}

.authorizationForm .form #polyticChekbox {
    height: 25px;
    width: 25px;
    margin: 10px;

}
.authorizationForm .form #polyticChekbox2 {
    height: 25px;
    width: 25px;
    margin: 10px;

}

.authorizationForm .form #polyticChekbox:after {
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.authorizationForm .form #polyticChekbox2:after {
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.authorizationForm .form .formdiv {
    display: flex;
    justify-content: center;
    width: 105%;
}

.authorizationForm .form .formdiv label {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    width: 90%;
    margin: 0;
    padding: 0;
    text-align: left;
}
.authorizationForm .form .formdiv a {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    width: 90%;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: underline;
}
.passwordHideDiv {
    width: 100%;    
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.passwordHideDiv  img{
    width: 20px;
    margin-right: 5%;
    margin-bottom: 5px;
    position: absolute;
    cursor: pointer;
}
@media only screen and (max-width: 1350px) {
    .authorizationForm {
        width: 450px;
    }

    .authorizationForm .form div label,
    .authorizationForm .form div a {
        font-size: 12px;
    }
}

@media only screen and (max-width: 700px) {
    .authorizationForm {
        width: 90%;
    }
}

@media only screen and (max-width: 450px) {
    .authorizationForm p {
        font-size: 14px;
    }

    .authorizationForm {
        width: 100%;
    }
}