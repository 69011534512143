.cabinetManu {
    width: 330px;
    max-width: 100%;
    height: 100vh;
    background: linear-gradient(270deg, #616161 -238.95%, #141414 50%);
    border-radius: 0px 0px 30px 0px;
    position: fixed;
    transition: .3s;
    z-index: 111111111111;
}
.cabinetManuHidden .cabinetLink p{
    display: none;
}
.cabinetManuHidden .cabinetLink img{
    margin-right: 0;
    margin-left: 13px;
}
.cabinetManuHeader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    transition: 0.3s;
}

.cabinetManuHeader h2 {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 33px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #F7D81A;
    z-index: 11111111111111;
}

.cabinetManuLinks {
    width: 100%;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    flex-direction: column;
    /* height: 320px; */
}

.cabinetLink {
    width: 95%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    
}
.cabinetLinkDisabled {
    opacity: 0.8;
    filter: contrast(0);

}

.cabinetLinkFon {
    width: 100%;
    height: 40px;
    position: absolute;
    background: linear-gradient(90deg, rgba(247, 216, 26, 0.18) 0%, rgba(247, 216, 26, 0) 100%);
    filter: blur(4px);
    opacity: 0;
    transition: .2s;
}

.cabinetLink img {
    height: 30px;
    margin-right: 18px;
    filter: sepia(1) brightness(10);
    transition: .2s;
}

.cabinetLink:hover img {
    filter: sepia(0) brightness(1);
}

.cabinetLink:hover .cabinetLinkFon {
    opacity: 1;
}

.cabinetLink:hover p {
    color: #F7D81A;
}

.cabinetLink p {
    transition: .2s;
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    margin: 0;
}

.cabinetHeader {
    position: absolute;
    width: calc(100% - 330px);
    height: 80px;
    border-bottom: 1px solid #D9D9D9;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s;
}

.cabinetHeader div {
    /* min-width: 400px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.cabinetHeaderInfo img {
    height: 40px;
    margin: 35px;
}

.cabinetHeaderInfo p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.1em;
    color: #F7D81A;
}

.cabinetHeaderUserInfo img {
    height: 40px;
    margin: 25px;
}

.cabinetHeaderUserInfo p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #C41617;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cabinetHeader h2 {
    display: none;
}

.cabinetHeaderUserInfo p img {
    height: 15px;
    margin: 0;
    margin-left: 7px;
    cursor: pointer;
}

.cabinetHeaderUserInfo p a {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #C41617;
    cursor: pointer;
    margin-bottom: -2px;
    transition: 0.2s;
}

.cabinetHeaderUserInfo p a:hover {
    opacity: 0.8;
}

.cabinetHeaderUserInfo p b {
    margin: 0 7px;
}

.cabinetHeader .cabinetManuButton {
    position: fixed;
    transition: .25s;
    background-image: url(../../../../public/static/img/manuButton.png);
    width: 40px;
    height: 27px;
    background-repeat: no-repeat;
    background-size: 100%;
    transform: rotate(0deg);
    z-index: 111;
    left: 40px;
    z-index: 100000;
    display: none;
    cursor: pointer;
    z-index: 1111111111111;

}

.cabinetHeader .cabinetManuButtonActive {
    background-image: url(../../../../public/static/img/manuButtonActove.png);
    transform: rotate(180deg);
}

.cabinetHeaderUserInfo .profileDropdown p {
    font-family: 'DaysOne';
    font-style: normal;
    font-size: 15px;
    letter-spacing: 0.1em;
    color: #C41617;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.cabinetHeaderUserInfo .profileDropdown p img {
    height: 20px;
}

.profileDropdown a span {
    color: #616161;
    font-size: 15px;
    margin-right: 8px;
}

.profileDropdown a {
    font-family: 'DaysOne';
    width: 90%;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.1em;
    color: #F7D81A;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    text-overflow: ellipsis;
}

.cabinetHeader .profileDropdown {
    position: absolute;
    top: 35px;
    right: 20px;
    min-height: 80px;
    padding: 3px 10px;
    max-width: 100%;
    border-radius: 15px 0 15px 15px;
    border: 1px solid #f9b21a71;
    z-index: 3;
    background: linear-gradient(270deg, #616161 -238.95%, #141414 50%);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    transition: 0.2s;
    opacity: 1;
    display: none;
}

.cabinetHeader .profileDropdownActive {
    opacity: 0;
    right: 0;
    top: 0;
}

.cabinetLinkMobile {
    display: none;
}

.manuSupport {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    bottom: 15px;
}

.manuSupport p {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    text-align: center;
    transition: 0.2s;
}

.manuSupport img {
    width: 25px;
    margin: 5px 15px;
    filter: brightness(0) invert(1);
}
.manuSupport img:hover {
    filter: brightness(1) invert(0);

}
.hideManuDiv{
    width: 20px;
    height: 40px;
    background-image: url(../../../../public/static/img/cabinet/chevron-left-solid.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    left: calc(330px - 25px);
    bottom: calc(30vh - 20px);
    cursor: pointer;
    transition: .3s;
    background-color: transparent;
    border: 0;
    z-index: 1;
}
.hideManuDiv:hover{
    filter: brightness(2);
}
.hideManuDivHidden{
    transform: rotate(180deg);
}
.manuLine{
    width: 95%;
    height: 1px;
    margin: 3px 0;
    margin-bottom: 10px;
    background-color: #F7D81A90;
    background-color: #888888;
}
@media only screen and (max-width:1300px) {
    .hideManuDiv {
        display: none;
    }
    .cabinetHeader .cabinetManuButton {
        display: flex;
    }

    .cabinetManu {
        width: 330px;
        left: -340px;
    }

    .cabinetManuActive {
        left: 0;
    }

    .cabinetHeader {
        width: 100% !important;
    }

    .cabinetHeader h2 {
        display: block;
        font-family: 'DaysOne';
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 33px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        color: #F7D81A;
        margin-left: 100px;
    }

    .cabinetManuHeader h2 {
        display: none;
    }

}

@media only screen and (max-width: 1100px) {
    .cabinetHeader h2 {
        display: none;
    }

    .cabinetHeaderInfo {
        margin-left: 100px;
    }

    .cabinetHeaderInfo img {
        margin: 15px;
    }

    .cabinetHeaderInfo p {
        font-size: 19px;
    }

    .cabinetHeaderUserInfo img {
        margin: 10px;
    }
}

@media only screen and (max-width: 800px) {
    .cabinetHeaderInfo p {
        font-size: 16px;
        line-height: 16px;
    }

    .cabinetHeaderUserInfo p a {
        font-size: 12px;
    }

    .cabinetHeader {
        height: 60px;
    }

    .cabinetHeaderInfo img {
        height: 30px;
        margin: 35px;
    }
}

@media only screen and (max-width: 580px) {
    .cabinetLinkMobile {
        display: flex;
    }

    .cabinetHeader .profileDropdown {
        display: flex;
    }

    .cabinetHeaderUserInfo p {
        display: none;
    }

    .cabinetHeaderInfo p {
        font-size: 14px;
        line-height: 16px;
    }

    .cabinetHeader .cabinetManuButton {
        left: 20px;
        width: 32px;
        height: 22px;
    }

    .cabinetHeaderInfo {
        margin-left: 60px;
    }

    .cabinetHeaderInfo img {
        margin: 4px;
        margin-right: 10px;
    }

    .cabinetHeaderUserInfo img {
        display: none;
    }
}

@media only screen and (max-width: 350px) {
    .cabinetHeaderInfo p {
        font-size: 12px;
    }

    .cabinetHeader .cabinetManuButton {
        left: 12px;
    }

    .cabinetHeaderInfo {
        margin-left: 48px;
    }

}