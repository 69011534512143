.botDevelopmentDiv {
    width: 100%;
}

.botDevelopmentDiv .botHeaderText {
    margin-top: 50px;
    font-size: 30px;
    margin-bottom: 40px;
}

.botDevelopmentDiv .botUnderKeyInfo p {
    font-size: 16px;
}

.botDevelopmentDiv .botUnderKeyInfo {
    width: 100%;
}

.botDevelopmentDiv .botUnderKeyImage img {
    display: none;

}

.botDevelopmentDiv .botUnderKeyInfo li::before {
    font-size: 25px;
}

.botDevelopmentDiv .botUnderKeyInfo li {
    max-width: 45%;
    width: 45%;
    font-size: 14px;
    margin-bottom: 20px;
}

.botDevelopmentDiv .botUnderKeyInfo ol {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}



.botDevelopmentDiv .botPriceInfo {
    width: 42%;
}

.botDevelopmentDiv .botPriceFon2 {
    bottom: -150px;
}

.botDevelopmentDiv .botPriceItem {
    width: 70%;
}

.botDevelopmentDiv .botPrice {
    width: 600px;
    height: 264px;
}

.botDevelopmentDiv .botPriceButtonDiv {
    width: 600px;
}

.botDevelopmentDiv .botPriceInfo h5 {
    font-size: 22px;
    margin-bottom: 15px;
}

.botDevelopmentDiv .botPriceInfo h2 {
    font-size: 24px;
    margin-bottom: 15px;
}

.botDevelopmentDiv .botPriceInfo p {
    font-size: 18px;
    padding: 15px 23px;
    margin-bottom: 10px;
}

.botDevelopmentDiv .botPriceInfo span {
    font-size: 15px;
    margin-bottom: 9px;
}

.botDevelopmentDiv .botPriceButtonDiv span {
    padding: 12px 50px;
    margin-top: 20px;
    margin-bottom: 40px;
}

@media only screen and (max-width: 1800px) {
    .botDevelopmentDiv .phoneeithwifi{
        opacity: 0.5;
        width: 200px;
    }

}
@media only screen and (max-width: 1100px) {
    .botDevelopmentDiv .botHeaderText {
        font-size: 30px;
        line-height: 35px;
        margin-top: 100px;
        margin-bottom: 50px;
        margin-left: 3%;
    }
}

@media only screen and (max-width: 700px) {
    .botDevelopmentDiv .botHeaderText {
        font-size: 25px;
        line-height: 30px;
        margin-top: 80px;
        margin-bottom: 40px;
        margin-left: 3%;
    }
}

@media screen and (max-width:580px) {
    .botDevelopmentDiv .botUnderKeyInfo li {
        max-width: 100%;
    }

    .botDevelopmentDiv .botUnderKeyInfo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .botUnderKeyInfo p {
        width: 90%;

    }
}

@media screen and (max-width:550px) {
    .botDevelopmentDiv .botPriceInfo {
        width: 41%;
        padding-left: 2%;
        height: 60%;
    }

    .botDevelopmentDiv .botPriceItem {
        width: 95%;
    }

    .botDevelopmentDiv .botPrice {
        width: 300px;
        height: 180px;
        -webkit-mask-image: url(../../../../../public/static/img/bot/priceFonMaskMobile.png);
        mask-image: url(../../../../../public/static/img/bot/priceFonMaskMobile.png);
        background: url(../../../../../public/static/img/bot/priceFonMobile.png);
        -webkit-mask-repeat: no-repeat;
        mask-size: 100%;
        mask-repeat: no-repeat;
        background-size: 100%;
        backdrop-filter: blur(4px);
        position: relative;
    }

    .botDevelopmentDiv .botPriceButtonDiv {
        width: 400px;
    }

    .botDevelopmentDiv .botPriceInfo h5 {
        font-size: 15px;
        margin-bottom: 15px;
        white-space: nowrap;
    }

    .botDevelopmentDiv .botPriceInfo h2 {
        font-size: 17px;
        margin-bottom: 13px;
    }

    .botDevelopmentDiv .botPriceInfo p {
        font-size: 14px;
        padding: 13px 17px;
        margin-bottom: 7px;
        background-repeat: no-repeat;
        margin-left: 30px;
    }

    .botDevelopmentDiv .botPriceInfo .mobileSpans {
        display: flex;
        font-size: 10px;
        margin-bottom: 5px;
    }

    .botDevelopmentDiv .botPriceInfo span {
        display: none;
    }

    .botDevelopmentDiv .botPriceButtonDiv span {
        padding: 9px 35px;
        margin-top: 15px;
        margin-bottom: 30px;
        font-size: 15px;
        border-radius: 7px;
    }
    .botDevelopmentDiv .botUnderKeyInfo ol{
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
    }
    .botDevelopmentDiv .botUnderKeyInfo ol li{
        width: 90%;
    }
}

@media only screen and (max-width: 450px) {
    .botDevelopmentDiv .botHeaderText {
        font-size: 20px;
        line-height: 24px;
        margin-top: 60px;
        margin-bottom: 30px;
    }
}
