.botHeaderText {
    font-family: 'DaysOne';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.1em;
    color: #F4F4F4;
    margin-top: 120px;
    margin-bottom: 70px;
    margin-left: 7.5%;
}

.botHeder .homeHeaderImage {
    justify-content: center;
    position: relative;
    margin-top: 10px;
    height: 40%;
}

.botHederFon {
    position: absolute;
    top: 0;
    width: 100%;
}

.botHederFon {
    z-index: -1;
}

.botHederFon img {
    width: 100%;
}

.botHeder ul li {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 23px;
    display: flex;
    align-items: center;
    margin: 6px;
    color: #FFFFFF;
}

.botHeder h2 {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 50px;
}

.botHeder p {
    margin-bottom: 25px;
    font-weight: 800;
}

.botHeder .homeHeaderButtonDiv a {
    width: 60%;
}

.botHeder .homeHeaderButtonDiv {
    width: 80%;
    gap: 30px;
}

.botHeder .homeHeaderButtonDiv a:nth-child(2) button {
    background-color: transparent;
    color: #F7D81A;
    border-color: #F7D81A;
}

.botHeder .homeHeaderButtonDiv a:nth-child(2) button:hover {
    background-color: #F7D81A;
    color: black;
    border-color: #F7D81A;
}

.botHeder ul {
    list-style: none;
    margin-bottom: 100px;
}

.botHeder ul li::before {
    content: "\2022";
    color: #F7D81A;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1.5em;
}

.waveBotHeaderText {

    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #141414;
}

.waveBotHeaderText h5 {
    z-index: 2;
    font-size: 30px;
    color: #FFFFFF;
    margin: 30px auto;
    background-color: #141414;
    padding: 30px;
    text-transform: uppercase;
}

.waveBotHeaderText::before {
    background-image: url(../../../public/static/img/bot/audio_wave1.png);
    left: 0;
}

.waveBotHeaderText::after {
    background-image: url(../../../public/static/img/bot/audio_wave2.png);
    right: 0;
}

.waveBotHeaderText::after,
.waveBotHeaderText::before {
    z-index: 1;
    content: '';
    position: absolute;
    background-repeat: repeat-x;
    background-position: center;
    width: 50%;
    background-size: 300px;
    height: 50px;

}

.botHeder .homeHeaderText h4 {
    color: white;
    line-height: 40px;
    font-size: 41px;
}

.botHeder .homeHeaderText h4 i {
    font-size: 51px;
}


@media only screen and (max-width: 1668px) {
    .botHeder .homeHeaderText {
        padding-left: 30px;
    }

    .botHeder h2 {
        font-size: 32px;
    }

    .botHeder h3 {
        font-size: 26px;
    }

    .botHeder ul {
        margin-bottom: 40px;
    }

    .botHeder {
        margin-bottom: 30px;
    }

    .botHeder .homeHeaderButtonDiv {
        flex-direction: row;
        height: auto;
    }

}

@media only screen and (max-width: 1420px) {
    .botHeder {
        justify-content: flex-end;
    }

    .botHeder .homeHeaderText {
        width: 50%;
    }

    .botHeder .homeHeaderImage {
        margin-top: 10px;
        position: relative;
    }

    .waveBotHeaderText h5 {
        padding: 20px;
        background-color: #141414a0;
    }
}


@media only screen and (max-width: 1100px) {
    .botHeaderText {
        font-size: 30px;
        line-height: 35px;
        margin-top: 100px;
        margin-bottom: 50px;
        margin-left: 3%;
    }

    .botHeder .homeHeaderText {
        padding-left: 0px;
        width: 95%;

    }

    .botHeder .homeHeaderImage img {
        width: 300px;
        height: auto;
        object-fit: contain;

        margin-top: 0;
    }

    .botHeder {
        flex-direction: row;
        position: relative;
        justify-content: center;
    }

    .botHeder .homeHeaderImage {
        position: absolute;
        width: auto;
        right: 50px;
    }

    .botHeder p {
        width: 50%;
    }

    .botHeder ul {
        width: 50%;
        margin-bottom: 60px;
    }

    .botHeder .homeHeaderButtonDiv {
        gap: 10px;
        margin-bottom: 0px;
        flex-direction: column;
    }
}

@media only screen and (max-width: 1000px) {

    .botHeder .homeHeaderText h4 i{
        font-weight: bold;
    }
    .botHeder .homeHeaderText h4 i,
    .botHeder .homeHeaderText h4 {
        font-size: 30px;
    }
}

@media only screen and (max-width: 800px) {
    .botHeder .homeHeaderImage img {
        width: 200px;
        margin-top: 150px;
    }

    .botHeder p {
        width: 100%;
    }

    .botHeder ul {
        width: calc(100% - 300px);
    }
}

@media only screen and (max-width: 700px) {
    .waveBotHeaderText h5 {
        font-size: 25px;
    }

    .botHeaderText {
        font-size: 25px;
        line-height: 30px;
        margin-top: 80px;
        margin-bottom: 40px;
        margin-left: 3%;
    }

    .botHeaderText br {
        display: none;
    }

    .botHeder .homeHeaderText {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .botHeder h2 {
        line-height: 28px;
        text-align: left;
        font-size: 25px;
        margin: auto;
        margin-bottom: 40px;
        width: 90%;
    }

    .botHeder p {
        width: 90%;
        margin: auto;
        margin-bottom: 15px;
        text-align: left;
    }

    .botHeder ul li {
        font-size: 16px;
        line-height: 22px;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    .botHeder ul {
        text-align: left;
        width: 90%;
        margin-bottom: 50px;
    }

    .botHeder ul li::before {
        width: 0px;
        transform: scale(1.5);
        padding-right: 20px;
        margin-left: 0px;
    }

    .botHeder {
        flex-direction: column-reverse;
        margin-top: 50px;
    }

    .botHeder .homeHeaderImage {
        position: relative;
        margin: 0;
        right: 0;
        padding-bottom: 50px;
    }

    .botHeder .homeHeaderImage img {
        margin-top: 0;
    }

    .botHeder .homeHeaderButtonDiv {
        width: 90%;
    }

    .botHeder .homeHeaderButtonDiv a {
        width: 100%;

    }

    .botHeder .homeHeaderButtonDiv button {
        width: 100%;
        font-size: 14px;
    }
}

@media only screen and (max-width: 450px) {
    .waveBotHeaderText h5 {
        font-size: 20px;
    }

    .botHeaderText {
        font-size: 20px;
        line-height: 24px;
        margin-top: 60px;
        margin-bottom: 30px;
    }

    .botHeder h2 {
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 30px;
    }

    .botHeder p {
        margin-bottom: 5px;
    }

    .botHeder ul {
        padding-left: 20px;
    }

    /* .homeHeaderButtonDiv button{
        font-size: 14px;
        min-width: 150px;
    } */

}