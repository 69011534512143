.referalDiv {
    width: 100%;
    margin-top: 25px;
}

.referalInfoDiv {
    display: flex;
    width: 90%;
    margin: auto;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
}

.referalInfo {
    border: 1px solid #f9b21a71;
    border-radius: 12px;
    height: 50px;
    min-width: 150px;
    transition: .2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}

.referalInfo p {
    font-family: DaysOne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    line-height: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0;
    color: #F7D81A;
}

.referalInfo span {
    font-family: DaysOne;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    margin-right: 8px;
}

.referalContactInfo {
    width: 90%;
    margin: auto;
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.referalContactLinkDiv {
    border: 1px solid #f9b21a71;
    border-radius: 12px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 22px;
    padding-bottom: 6px;
}

.referalContactLinkDiv legend {
    font-family: DaysOne;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    color: #fff;
    margin: 0;
}

.referalContactLinkDiv img {
    height: 25px;
    cursor: pointer;
    transition: 0.2s;
}

.referalContactLinkDiv img:hover {
    opacity: 0.8;
}

.referalContactLinkDiv p {
    font-family: DaysOne;
    transition: 0.2s;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    line-height: 25px;
    color: #fff;
    margin: 0;
    margin-right: 10px;
    text-align: center;
    cursor: pointer;
    z-index: 8; 
    user-select: text;

}

.referalContactLinkDiv p:hover {
    opacity: 0.8;
}

.referalContactLinkDiv h6 {
    position: absolute;
    font-family: Gilroy;
    transition: 0.2s;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    line-height: 25px;
    color: #989898;
    margin: 0;
    text-align: center;
    margin-top: 75px;
}

.referalContactLinkDiv span {
    font-family: DaysOne;
    transition: 0.2s;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    line-height: 25px;
    color: #fff;
    margin: 0;
    margin-right: 10px;
    text-align: center;
    position: absolute;
    opacity: 0;
}

.referalContactAuthor {
    border: 1px solid #f9b21a71;
    border-radius: 12px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 22px;
    padding-bottom: 6px;
    min-width: 400px;
}

.referalContactAuthor img {
    height: 20px;
    margin-right: 10px;
    transition: 0.2s;
}

.referalContactAuthor p {
    font-family: DaysOne;
    transition: 0.2s;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    line-height: 25px;
    color: #fff;
    margin: 0;
    margin-right: 10px;
    text-align: center;
}

.referalContactAuthor legend {
    font-family: DaysOne;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    color: #fff;
    margin: 0;
}

.referalContactAuthor h6 {
    position: absolute;
    font-family: Gilroy;
    transition: 0.2s;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .1em;
    line-height: 25px;
    color: white;
    margin: 0;
    text-align: center;
    margin-top: 75px;
}

.referalFAQDiv {
    width: 90%;
    margin: auto;
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 120px;
}

.referalFAQDiv p {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1em;
    color: #989898;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 2vh;
}
.referalFAQDiv .boldText {
    color: white;
}

.referralTableDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.referalFAQDiv .referralTableText {
    /* text-align: center; */
    width: 450px;
}

.referaPayMethodDiv {
    width: 60%;

}

.referaPayMethodImageDiv {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.referaPayMethodImageDiv img {
    height: 40px;
}

.referralTable {
    width: 300px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #f9b21a71;
    border-radius: 20px;
    padding: 20px;
}

.referralTableColumn {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.referralTableColumn p {
    font-family: 'DaysOne';
    margin: 0;
    margin-bottom: 15px;
    color: #F7D81A;
}

.referralTableColumn span {
    font-family: 'Gilroy';
    margin-bottom: 5px;
    margin-left: 15px;
    font-size: 17px;
    color: #c8c8c8;
}

.referalTableLine {
    width: 1px;
    background-color: #989898;
    height: 315px;
}
.referralPartnerActivationDiv {
    position: absolute;
    border: 1px solid #f9b21a71;
    background-color: #141414;
    border-radius: 25px;
    width: 500px;
    min-height: 200px;
    left: calc(50% - 270px);
    top: calc(50vh - 250px);
    z-index: 1111;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}
.referralPartnerActivationDiv p b{
    color: #F7D81A;
}
.referralPartnerActivationDiv p{
    color: white;
    font-family: 'DaysOne';
    text-align: center;
    
}
.referralPartnerActivationDiv div{
    width: 60%;
    display: flex;
    justify-content: space-around;
}
.referralPartnerActivationDiv button{
    border: 0;
    color: #000000;
    background: #f7d81a;
    cursor: pointer;
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    transition: .3s;
    border-radius: 7px;
    padding: 10px 15px;
    cursor: pointer;
    background: linear-gradient(-45deg, #d6ac55, rgba(245, 249, 26, 0.78), rgba(245, 249, 26, 0.3));
    box-shadow: 0px 0px 18px 1px rgba(247, 216, 26, 0.4);
}
.referralPartnerActivationDiv .referralPartnerActivationButtonCansel{
    color: #F7D81A;
    background: none;
    border: 1px solid #f9b21a71;
    box-shadow: none;
    
}
.referralPartnerActivationDiv button:hover{
    box-shadow: 0px 0px 20px 2px rgba(247, 216, 26, 0.4);
    filter: brightness(1.35);
}
.referralPartnerActivationDiv .referralPartnerActivationButtonCansel:hover{
    box-shadow: none;
}


.refferalManuFon {
    transition: .3s;
    top: 0;
    left: 0;
    z-index: -9;
    width: 100%;
    height: 100vh;
    background: rgba(20, 20, 20, 0.6);
    position: fixed;
    opacity: 0;
}
.refferalManuFonActive {
    z-index: 9;
    opacity: 1;
}
.referalError{
    color: rgb(255, 73, 73);
    text-align: center;
    margin-bottom: 20px;
}
@media only screen and (max-width: 1650px) {
    .referalInfoDiv{
        justify-content: space-between;
    }
    .referalContactInfo{
        justify-content: space-between;
        height: 170px;
    }
    
}
@media only screen and (max-width: 1450px) {
    .referalInfoDiv{
        justify-content: center;
    }
    .referalInfo{
        margin: 10px 30px;
    }
    .referalContactInfo{
        align-items: start;
        flex-direction: column;
        height: 170px;
    }
}
@media only screen and (max-width: 1300px) {
    .referalInfoDiv{
        justify-content: space-between;
    }
    .referalInfo{
        margin: 0;
    }
    .referalContactInfo{
        flex-direction: row;
    }
    
}
@media only screen and (max-width: 1120px) {
    .referalInfoDiv{
        width: 98%;
    }
    .referalContactInfo{
        width: 98%;    
    }
    .referalFAQDiv{
        width: 98%;            
    }
}
@media only screen and (max-width: 1020px) {
    .referalInfoDiv{
        justify-content: center;
    }
    .referalInfo{
        margin: 10px 40px;
    }
    .referalContactInfo{
        align-items: center;
        flex-direction: column;
        height: 200px;        
    }
    .referalFAQDiv{
        flex-direction: column;
        margin-top: 30px;
    }
    .referaPayMethodImageDiv{
        margin-top: 30px;
    }
    .referalFAQDiv .referaPayMethodDiv p{
        margin-bottom: 0;
    }
}
@media only screen and (max-width: 800px) {
    .referalDiv{
        margin-top: 0;
    }
    .referaPayMethodDiv p{
        width: 80%;
    }
    .referaPayMethodDiv{
        width: 80%;
    }
    
}
@media only screen and (max-width: 600px) {
    .referalContactLinkDiv p {
        user-select: all;
    }
    .referralTableDiv{width: 95%;}
    .referaPayMethodDiv{width: 95%;}
    .referalContactAuthor {
        width: 95%;
        padding: 0;
        padding-top: 5px;
    }
    .referalContactLinkDiv {
        width: 95%;
        padding: 0;
        padding-top: 5px;
        justify-content: flex-end;
    }
    .referalContactLinkDiv img{
        margin-right: 5%;
    }
    .referalContactLinkDiv h6 {
        width: 300px;
        left: calc(50% - 150px);        
    }
    .referalContactLinkDiv span{
        width: 200px;
        left: calc(50% - 100px);        
    }
    .referalContactLinkDiv p{
        position: absolute;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 75%;
        left: 10%;
    }
    .referralTable{
        margin-top: 10px;
    }
    .referalInfo {
        margin: 10px 10px;
        min-width: 180px;
    }
    .referalFAQDiv p{
        font-size: 15px;
    }
    .referralPartnerActivationDiv{
        width: 84%;
        padding: 3%;
        left: 5%;
    }
    .referralPartnerActivationDiv div{
        width: 80%;
    }
}
@media only screen and (max-width: 500px) {
    .referralPartnerActivationDiv{
        width: 90%;
        padding: 2.5%;
        left: 2.5%;
    }
    .referalInfo {
        margin: 10px 0px;
        min-width: 180px;
    }
    .referalInfoDiv{
        justify-content: space-around;
    }
    .referalContactLinkDiv p{
        left: 7%;
    }
    .referalContactAuthor{
        height: auto;
        min-width: auto;
        padding-bottom: 10px;
    }
    .referalContactAuthor p{
        width: 100%;
        text-align: center;
        margin-right: 0;
    }
    .referalContactAuthor img{
        /* position: absolute;
        left: 10%;
        margin-top: -25px; */
        display: none;
    }
    .referalFAQDiv .referralTableText{
        width: 98%;
    }
    .referralTable {
        width: 230px;
    }
    .referaPayMethodImageDiv{
        flex-direction: column;
    }
    .referaPayMethodImageDiv img{
        margin-bottom: 15px;
    }
    .referaPayMethodDiv p{
        width: 98%;
    }
}
@media only screen and (max-width: 400px) {
    .referralPartnerActivationDiv div{
        width: 100%;

    }
    .referalInfo{
        min-width: auto;
        width: 70%;
    }
}
