.formFonDiv{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

}
.formFon{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    background-color: #000000aa;
    cursor: pointer;
}
.botFormDiv{
    margin: auto;
}
.botFormDiv .homeForm{
    width: auto;
    background-color: #00000070;
    border-radius: 40px;
}
.botFormDiv .supportForm h2{
    font-size: 22px;
    line-height: 25px;
}
.botFormCloseDiv{
    display: none;
}
@media screen and (max-width:700px) {
    .botFormCloseDiv{
        display: block;
        width: 30px;
        height: 30px;
        cursor: pointer;
        background-image: url(../../../public/static/img/manuButtonActoveYellow.png);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        filter: invert(1);
        position: absolute;
        left: 20px;
        top: 20px;
    }
}
@media screen and (max-width: 800px) {
    .botFormDiv .supportForm h2{
        font-size: 16px;
        line-height: 20px;
        width: 90%;
    }
    
    
}