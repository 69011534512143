.botTrainingDiv .telegramItem {
    background: transparent;
    background-color: #ffffff05;
    background-image: url(../../../../../public/static/img/formFon.png);
    background-size: 40px;
    backdrop-filter: blur(2px);

}

.botTrainingDiv .telegramItem .selfMailingItemCover {
    background-color: #f7d81a;
    box-shadow: inset 2px 4px 4px rgb(0 0 0 / 25%), inset -2px -4px 4px rgb(110 110 110 / 50%);
}

.botTrainingDiv .telegramItem .selfMailingInfoHeader {
    margin-left: 100px;
}

.botTrainingDiv .telegramItem .selfMailingInfoButtonDiv div {
    background: #F7D81A;
    transition: 0.3s;
}

.botTrainingDiv .telegramItem .selfMailingInfoButtonDiv div p {
    color: #000;
}

.botTrainingDiv .telegramItem .selfMailingInfoButtonDiv div:hover {
    background: transparent;
}
.botTrainingDiv .telegramItem .selfMailingInfoButtonDiv div:hover img{
    filter: invert(1);
}

.botTrainingDiv .telegramItem .selfMailingInfoButtonDiv div:hover p {
    color: white;
}
.botTrainingDiv .selfMailingInfoTraningButton a {
    border: 1px solid #F7D81A;
}
.botTrainingDiv .selfMailingInfoTraningButton a:hover {
    background: transparent;
    color: white;
}

.botTrainingDiv .selfMailingInfoButtonDiv div img {
    width: 30%
}


.botTrainingFon {
    position: absolute;
    z-index: -1;
    top: 30px;
    width: 100%;
}
.botEducationCabinet{
    width: 100%;
    height: 100vh;    
    flex-direction: column;
    justify-content: center;
}
.botEducationCabinetTetx{
    color: #fff;
    font-size: 23px;
    width: 100%;
    margin: auto;    
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;    
    gap: 30px;
    margin-top: 25vh;
}
.botEducationCabinetHref{
    transition: .25s;
    background: #F7D81A;
    border: 0.5px solid #141414;    
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: DaysOne;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #141414;    
    font-size: 18px;
    padding: 10px 20px;
    margin: auto;
    
}
.botEducationCabinetHref:hover{
    color: #F7D81A;    
    background: transparent;
    border: 0.5px solid #F7D81A;    


}


@media screen and (max-width:1600px) {
    .botEducationCabinet .botEducationDiv {
        width: 95%;
    }

    .botEducationCabinet .selectEduFormatBlock h2 {
        font-size: 28px;
        gap: 15px;
    }

    .botEducationCabinet .selectEduFormatBlock h2 img {
        height: 55px;
    }

    .botEducationCabinet .selectEduFormatBlock h5 {
        margin-top: 50px;
    }

    .botEducationCabinet .selectEduFormatBlock h5 {
        font-size: 30px;
    }

    .botEducationCabinet .selectEduFormatBlock h4 {
        top: 70px;
        left: 100px;
    }

    .botEducationCabinet .selectEduFormatBlock {
        padding: 30px;
    }


    .studnetsReviewsInfo div {
        margin-top: 50px;
    }

    .botEducationCabinet .studnetsReviewsInfo svg {
        display: none;
    }

    .botEducationCabinet .studnetsReviewsInfo div img {
        width: 180px;
    }


    .educationPoint {
        scale: 0.8;
    }

    .botEducationCabinet .forSomeone h2 {
        gap: 25px;
        font-size: 28px;

    }

    .botEducationCabinet .botEducation p {
        max-width: 100%;
    }

    .botEducationCabinet .forSomeoneDiv {
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    .botEducationCabinet .forSomeone {
        max-width: 95%;
        min-width: 500px;
        height: auto !important;
    }

    .botEducationCabinet .forSomeoneDiv svg {
        display: none;
    }

    .botEducationCabinet .studnetsReviewsInfo div img {
        width: 150px;
    }

    .botEducationCabinet .studnetsReviewsInfo h5 {
        font-size: 30px;
    }

    .botEducationCabinet .studnetsReviewsInfo p {
        font-size: 16px;
    }

    .botEducationCabinet .studnetsReviewsSlider .splide__slide img {
        height: 400px;
    }

    .botEducationCabinet .selectEduFormatBlock button {
        padding: 12px 25px;
    }
}

@media screen and (max-width:700px) {
    .botTrainingDiv .telegramItem .selfMailingInfoHeader {
        margin: auto;
        width: 100%;
        text-align: center;
        font-size: 30px;
    }
    .botEducationCabinetTetx{
        font-size: 20px;
    }
    .botEducationCabinetHref{
        font-size: 14px;
    }
}